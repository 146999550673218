import { Empty, Spin } from "antd";
import { MTable, RootMRow, } from "./components";
import { NestedTableProps } from "./types";
import { renderCell, renderHeader } from "./utils";

function NestedTable({
	columns,
	loading,
	data,
	footer,
	rowKey = "id",
	body,
}: NestedTableProps) {

	return (
		<Spin spinning={loading}>
			<MTable>
				<RootMRow>
					{renderHeader(columns)}
				</RootMRow>
				{body ? body : data?.length ? (
					<>
						{renderCell(data, columns, rowKey)}
						{footer &&
							<RootMRow>
								{footer}
							</RootMRow>}
					</>
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
				)}
			</MTable>
		</Spin>
	);
}


export default NestedTable;
