import { TDateRangeFieldProps } from "components";

export const HEADER_DATA_KEYS = [
	"members",
	"actualConvertedEffort",
	"calendarEffort",
] as const;

export const DATE_PICKER_PROPS: TDateRangeFieldProps = {
	disabledDate: undefined,
	allowEmpty: [true, true],
};

export type THeaderDataKey = typeof HEADER_DATA_KEYS[number];
