import { effortNames } from "translations/keys/plan";
import { ETableCols } from "types";
import { PlanAllocationRecord } from "types/plan/project";
import { formatNum, renderNull } from "utils";
import FullNameAndUsername from "../components/FullNameAndUsername";
import { AvatarImg } from "../components/SmallComponents";
import { createPlanCol, planTranslate } from "../utils";

export const cols: ETableCols<PlanAllocationRecord> = [
	{
		dataIndex: effortNames.avatar,
		align: "center",
		width: "48px",
		render: (value) => value && <AvatarImg src={value} alt="avatar" />,
	},
	createPlanCol(effortNames.fullName, {
		align: "left",
		sorter: true,
		render: (value: string, record) => (
			<FullNameAndUsername
				fullName={value}
				userName={record[effortNames.username]}
			/>
		),
	}),
	createPlanCol(effortNames.position, {
		sorter: true,
		render: renderNull,
	}),
	createPlanCol(effortNames.rank, {
		sorter: true,
		render: renderNull,
	}),
	createPlanCol(effortNames.contractType),
	createPlanCol(effortNames.worked, {
		sorter: true,
		render: formatNum,
	}),
	createPlanCol(effortNames.startDate),
	createPlanCol(effortNames.endDate),
	createPlanCol(effortNames.convertedEffort, {
		render: formatNum,
	}),
	createPlanCol(effortNames.calendarEffort, undefined, ""),
];

const roleTran = (key: string) => planTranslate(`role.${key}`, "allocation");

export const ROLES = [
	{
		value: "TEAM_LEAD",
		label: roleTran("teamlead"),
	},
	{
		value: "KEY_MEMBER",
		label: roleTran("keyMember"),
	},
	{
		value: "MEMBER",
		label: roleTran("member"),
	},
] as const;
