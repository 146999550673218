import { Form } from "antd";
import axiosClient from "api";
import {
	ContractTypeSelect,
	FormItem,
	InputField,
	InputNumberField,
	MemberSelect,
	ModalForm,
	PositionSelect,
	RangeField,
	RankSelect,
} from "components";
import { PLAN_BILLABLE } from "constants/api";
import { FORMAT_DATE, FORMAT_TIME } from "constants/format";
import { LOGTIME_UNIT } from "constants/setting";
import { useAppSelector } from "hooks";
import moment from "moment";
import { useParams } from "react-router-dom";
import { billableFormNames } from "translations/keys/plan";

function EditForm() {
	const { record, mode } = useAppSelector((state) => state.modal);
	const { id } = useParams();
	const [form] = Form.useForm<any>();

	const handleSubmit = async (values: any) => {
		const startDate = values[billableFormNames.time]?.[0];
		const endDate = values[billableFormNames.time]?.[1];
		if (startDate) {
			values[billableFormNames.startDate] = startDate.format(FORMAT_DATE);
		} else {
			delete values[billableFormNames.startDate];
		}
		if (endDate) {
			values[billableFormNames.endDate] = endDate.format(FORMAT_DATE);
		} else {
			delete values[billableFormNames.endDate];
		}
		delete values[billableFormNames.time];
		if (mode === "add") {
			await axiosClient.post(PLAN_BILLABLE, {
				...values,
				[billableFormNames.projectKey]: id,
			});
		}

		if (mode === "edit") {
			await axiosClient.put(
				`${PLAN_BILLABLE}/${record[billableFormNames.id]}`,
				values
			);
		}
	};

	const initialValues = (record: any) => {
		if (record) {
			const startDate = record[billableFormNames.startDate];
			const endDate = record[billableFormNames.endDate];
			const values = {
				...record,
				[billableFormNames.startDate]: startDate
					? moment(startDate, FORMAT_TIME.DATE)
					: undefined,
				[billableFormNames.endDate]: endDate
					? moment(endDate, FORMAT_TIME.DATE)
					: undefined,
				[billableFormNames.position]: record?.[billableFormNames.positionId],
				[billableFormNames.rank]: record?.[billableFormNames.rankId],
				[billableFormNames.contractType]:
					record?.[billableFormNames.contractTypeId],
			};
			values[billableFormNames.time] = [
				values[billableFormNames.startDate],
				values[billableFormNames.endDate],
			];
			return values;
		}
		return {};
	};

	return (
		<ModalForm
			formName="planBillable"
			handleSubmit={handleSubmit}
			form={form}
			initialValues={initialValues}
			title={mode === "add" ? "Add billable member" : "Edit billable member"}
		>
			<MemberSelect
				name={billableFormNames.username}
				fieldProps={{
					required: true,
				}}
			/>
			<PositionSelect
				name={billableFormNames.position}
				fieldProps={{
					required: true,
				}}
			/>
			<RankSelect
				name={billableFormNames.rank}
				fieldProps={{
					required: true,
				}}
			/>
			<ContractTypeSelect
				name={billableFormNames.contractType}
				fieldProps={{
					required: true,
				}}
			/>
			<FormItem
				name={billableFormNames.worked}
				rules={[
					{
						type: "number",
						min: 1,
						max: 8,
					},
				]}
				required
			>
				<InputNumberField addonAfter={LOGTIME_UNIT} />
			</FormItem>
			<RangeField
				name={billableFormNames.time}
				required
				style={{ width: "100%" }}
			/>
			<InputField name={billableFormNames.note} inputType="textarea" />
		</ModalForm>
	);
}

export default EditForm;
