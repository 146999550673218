import { Button, Form, message, Space } from "antd";
import {
	Accordion,
	EditBtn,
	FormItem,
	InputNumberField,
	PercentInput,
	ProjectQuerySelect,
	SaveBtn,
	StandardForm,
} from "components";
import { TableLayout } from "components/ETable/components/TableComponents";
import { CONFIG_REWARD, CONFIG_SET_OF_METRICS } from "constants/api";
import { VND } from "constants/setting";
import { useFetchWithUrlParams, useUrlParams } from "hooks";
import CustomLayout from "layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { addCurrency, addPercent } from "utils";
import { TAB_KEYS, TABS_PROPS } from "../constants";
import { FlexColumn } from "./components";
import MetricTable from "./components/MetricTable";
import { GroupData, GroupResponse } from "./types";
import { checkChange, getDefaultMetricValues } from "./utils";
import axiosClient from "api";
import { PERCENT_FIELD_RULE } from "../../../constants/rules";
import ResetBtn from "./components/ResetBtn";
import { CaretLeftOutlined } from "@ant-design/icons";

function ProjectMetrics() {
	const [editing, setEditing] = useState(false);
	const [form] = Form.useForm();
	const { t: metricT } = useTranslation("metrics");
	const { t } = useTranslation();
	const { tab } = useUrlParams();
	const isSpecific = tab === TAB_KEYS[1];
	const { loading, data, refetch: refetchMetric } = useFetchWithUrlParams<{}, GroupResponse[]>({
		url: CONFIG_SET_OF_METRICS,
	});
	const { data: metricReward, refetch: refetchReward } = useFetchWithUrlParams<
		{},
		{
			norm: number;
			rate: number;
		}
	>({
		url: CONFIG_REWARD,
	});

	const groups = useMemo<GroupData[] | undefined>(() => {
		return data?.map(({ metric, ...group }) => {
			const newMetric = metric.map((({ unit, metricValues, ...metricProps }) => {
				const sortedMetricValues = metricValues.sort(
					({ ordinal: aIndex = 0 }, { ordinal: bIndex = 0 }) => aIndex - bIndex
				)
				return {
					...metricProps,
					unit: unit.value,
					metricValues: sortedMetricValues.length ? sortedMetricValues : getDefaultMetricValues()
				}
			}))

			return {
				...group,
				metric: newMetric
			}
		})
	}, [data])

	const initializeData = useCallback(() => {
		if (groups) {
			form.setFieldsValue({ groups, metricReward });
		}
	}, [form, groups, metricReward]);

	useEffect(() => {
		initializeData()
	}, [initializeData]);

	const resetData = useCallback(() => {
		form.resetFields();
		initializeData();
	}, [form, initializeData]);

	const cancelEdit = useCallback(() => {
		setEditing(false);
		refetchMetric();
		refetchReward();
	}, [refetchMetric, refetchReward]);

	useEffect(() => {
		setEditing(false);
	}, [isSpecific]);

	const handleSubmit = useCallback(
		async (values: any) => {
			try {
				let changed: string[] = [];
				if (groups) {
					changed = checkChange(groups, values.groups)
				}
				const result = {
					changed,
					...values,
				}
				await axiosClient.post(CONFIG_SET_OF_METRICS, result);

				message.success(
					t("msg.success", {
						action: t("function.edit"),
					})
				);
				refetchMetric();
				refetchReward();
				setEditing(false);
			} catch (error) {
				console.log("error", error);
				message.error((error as any)?.message);
			}
		},
		[groups, t, refetchMetric, refetchReward]
	);

	return (
		<CustomLayout
			tabProps={TABS_PROPS}
			currentPageLabel={metricT("projectMetrics")}
		>
			<StandardForm form={form} onFinish={handleSubmit} onFinishFailed={(errorInfo) => {
				message.error(metricT("msg.error"));
			}}>
				<TableLayout
					header={
						<FlexColumn gap="0.625rem">
							{isSpecific && <ProjectQuerySelect/>}
							{!editing ? (
								<EditBtn
									onClick={() => {
										setEditing(true);
									}}
								/>
							) : (
								<Space>
									<Button type="text" onClick={cancelEdit} icon={<CaretLeftOutlined/>}/>
									<SaveBtn/>
									<ResetBtn onClick={resetData}/>
								</Space>
							)}
						</FlexColumn>
					}
					fullScreen={{} as any}
				>
					<Accordion
						defaultActiveKey={[1, 2]}
						items={[
							{
								title: metricT("panelTitle.evaluation"),
								key: 1,
								info: `${metricT("info.evaluation")} ${
									isSpecific ? "" : metricT("info.iconStar")
								}`,
								children: (
									<MetricTable
										data={groups}
										loading={loading}
										isEditing={editing}
										name="metricsRequests"
										isSpecific={isSpecific}
									/>
								),
							},
							{
								title: metricT("panelTitle.reward"),
								key: 2,
								info: metricT("info.reward"),
								children: (
									<FlexColumn>
										<FormItem
											label={metricT("form.rewardRate")}
											forView={!editing}
											renderViewMode={() =>
												addPercent(metricReward?.rate)
											}
											name={["metricReward", "rate"]}
											rules={[
												{
													required: true,
													message: t("form:validateMsg.commonRequired"),
												},
												{
													...PERCENT_FIELD_RULE,
													message: t("form:validateMsg.range", {
														name: metricT("form.rewardRateShort"),
														...PERCENT_FIELD_RULE
													})
												}
											]}
										>
											<PercentInput/>
										</FormItem>
										{!isSpecific && (
											<FormItem
												label={metricT("form.rewardMoney")}
												forView={!editing}
												renderViewMode={() =>
													addCurrency(metricReward?.norm, false)
												}
												name={["metricReward", "norm"]}
												rules={[
													{
														required: true,
														message: t("form:validateMsg.commonRequired"),
													},
												]}
											>
												<InputNumberField onlyInteger addonAfter={VND}/>
											</FormItem>
										)}
									</FlexColumn>
								),
							},
						]}
					></Accordion>
				</TableLayout>
			</StandardForm>
		</CustomLayout>
	);
}

export default ProjectMetrics;
