import { CaretRightOutlined } from "@ant-design/icons";
import { CollapsePanelProps, CollapseProps } from "antd";
import { Heading } from "components/Typography";
import { ReactNode } from "react";
import { InfoTooltip } from "../InfoTooltip";
import { StyledAccordion, StyledPanel } from "./styles";

interface PanelProps extends Omit<CollapsePanelProps, "header"> {
	title: ReactNode;
	info?: ReactNode;
}

export interface ICollapseProps extends CollapseProps {
	items: PanelProps[];
	infoPosition?: "start" | "end";
	noPaddingX?: boolean;
}

function Accordion({ items, noPaddingX, ...props }: ICollapseProps) {
	return (
		<StyledAccordion
			ghost
			expandIcon={({ isActive }) => (
				<CaretRightOutlined rotate={isActive ? 90 : 0} />
			)}
			defaultActiveKey={items.map((item) => item.key)} /* Open all by default */
			noPaddingX={noPaddingX}
			{...props}
		>
			{items.map(({ title, info, ...value }) => (
				<StyledPanel
					data-testid={`${title}-panel`}
					header={
						<>
							<Heading level={4}>{title}</Heading>
							{info && <InfoTooltip title={info} />}
						</>
					}
					{...value}
				/>
			))}
		</StyledAccordion>
	);
}

export { Accordion };
