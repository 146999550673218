import React, { ReactNode, useMemo } from "react";
import { Form, FormItemProps } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { useTranslation } from "react-i18next";
import { formatFormName } from "utils";
import { useFormContext } from "components/Form";
import { ErrorBox } from "./EditableCell";
import { isFunction } from "lodash";

export interface IFormItemProps<Values = any> extends FormItemProps<Values> {
	/**
	 * @description display text instead of children
	 */
	name: NamePath;
	forView?: boolean;
	parentName?: NamePath;
	renderViewMode?: (value?: any) => ReactNode;
	errorTooltip?: boolean;
}

function FormItem<Values = any>({
	children,
	name,
	label,
	forView,
	required,
	parentName,
	renderViewMode,
	rules,
	noStyle,
	style,
	requiredMark,
	className,
	shouldUpdate,
	errorTooltip = true,
	...props
}: IFormItemProps<Values>) {
	const { formName } = useFormContext();
	const { t } = useTranslation("form", {
		keyPrefix: formName,
	});

	const { actualName, translationPath } = useMemo(() => {
		let actualName: NamePath = name;
		if (Array.isArray(name)) {
			const childrenName = [...name];
			if (Array.isArray(parentName)) {
				actualName = parentName.concat(childrenName);
			} else if (parentName) {
				childrenName.unshift(parentName);
				actualName = childrenName;
			}
		} else {
			if (Array.isArray(parentName)) {
				actualName = parentName.concat(name);
			} else if (parentName) {
				actualName = [parentName, name];
			}
		}
		return {
			actualName,
			translationPath: Array.isArray(actualName)
				? formatFormName(actualName.join("."))
				: actualName + "",
		};
	}, [name, parentName]);

	const itemLabel = useMemo(() => {
		return typeof label === "undefined" ? t(translationPath) : label;
	}, [label, translationPath, t]);

	const handledRules = useMemo(() => {
		const newRules = rules ? [...rules] : [];
		if (required) {
			newRules.push({ required: true });
		}
		return newRules;
	}, [required, rules]);

	return (
		<Form.Item
			label={itemLabel}
			shouldUpdate={
				typeof shouldUpdate === "undefined"
					? (prev, curr) => {
						let prevVal = prev;
						let currVal = curr;
						if (Array.isArray(actualName)) {
							actualName.forEach((name) => {
								prevVal = prevVal?.[name];
								currVal = currVal?.[name];
							});
						} else {
							prevVal = prevVal?.[actualName];
							currVal = currVal?.[actualName];
						}
						return prevVal !== currVal;
					}
					: shouldUpdate
			}
			style={style}
			noStyle={noStyle}
			required={required}
			requiredMark={requiredMark}
			className={className}
			{...props}
		>
			{(form) => {
				if (forView) {
					const value = form.getFieldValue(actualName);
					return renderViewMode ? renderViewMode(value) : value;
				}
				const error = form.getFieldError(actualName)?.[0];

				return (
					<ErrorBox hasError={noStyle && error?.length > 0}>
						<Form.Item
							name={name}
							rules={handledRules}
							required={required}
							noStyle
							{...props}
						>
							{isFunction(children) ? children(form) : children}
						</Form.Item>
					</ErrorBox>
				);
			}}
		</Form.Item>
	);
}

export default React.memo(FormItem) as typeof FormItem;
