import { Form } from "antd";
import { ApiSelect, TSomethingSelectProps } from "components";
import { GET_MEMBER_METRICS } from "constants/api";
import { RoleFactor, roleNames, TMemberMetricsResponse } from "../types";

export const RoleSelect = (
	props: TSomethingSelectProps<undefined, RoleFactor, TMemberMetricsResponse>
) => {
	const form = Form.useFormInstance();
	return (
		<ApiSelect<RoleFactor, undefined, TMemberMetricsResponse>
			url={GET_MEMBER_METRICS}
			preHandleData={(res) => res.roleFactorMetricsResponses}
			optionKeys={{
				label: roleNames.role,
				value: roleNames.id,
			}}
			onChange={(_, item) => {
				form.setFieldsValue({
					...item,
					key: item[roleNames.key],
				});
			}}
			{...props}
		/>
	);
};
