import styled from "@emotion/styled";
import { Tooltip, Typography } from "antd";
import { ComponentProps, CSSProperties, ReactNode } from "react";

const { Text, Title } = Typography;

export const Heading = styled(Title)`
	&& {
		text-transform: capitalize;
		margin: 0;
	}
`;

export const SubText = styled((props: any) => (
	<Text type="secondary" {...props} />
))`
	font-size: 0.875rem;
	font-weight: 400;
`;

type TTruncateTextProps = {
	row?: number;
	width?: CSSProperties["width"];
	maxWidth?: CSSProperties["maxWidth"];
};

export const TruncateText = styled.p<TTruncateTextProps>`
	display: -webkit-box;
	width: ${({ width = "" }) => width};
	max-width: ${({ maxWidth = "" }) => maxWidth};
	-webkit-line-clamp: ${({ row = 1 }) => row};
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
`;

interface ITooltipParagraph extends TTruncateTextProps {
	children?: ReactNode;
	value?: ReactNode;
}

export const TooltipParagraph = ({
	children,
	value,
	...props
}: ITooltipParagraph) => {
	const title = children ?? value;
	return (
		<Tooltip title={title}>
			<TruncateText {...props}>{title}</TruncateText>
		</Tooltip>
	);
};

export const H5 = (props: ComponentProps<typeof Heading>) => {
	return <Heading level={5} {...props} />;
};
