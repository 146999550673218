import { useFetch, useUrlParams } from "hooks";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";
import { Select } from "antd";
import {
	SelectField,
	SelectFieldProps,
	SelectMode,
} from "components/FormFields";
import { H5 } from "components/Typography";

import { DROPDOWN_PROJECT, DROPDOWN_STATUS_PLAN } from "constants/api";
import { NavigateOptions } from "react-router-dom";
import { Project } from "types/projects";
import { ApiSelect, IApiSelectProps } from "./ApiSelect";

interface ProjectSelectBase
	extends Omit<
		SelectFieldProps<undefined>,
		"name" | "children" | "optionKeys"
	> {
	username?: string;
	label?: string;
	/**
	 * @description if true, it will set the first option to url param
	 */
	defaultActiveFirstOption?: boolean;
}

interface ProjectSelectProps extends ProjectSelectBase {
	name?: string;
	replaceOldQueryParams?: boolean | object;
	navigateOptions?: NavigateOptions;
	url?: string;
}

const ProjectQueryContainer = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 0.75rem;

	.ant-select {
		min-width: 12rem;
	}
`;

function ProjectQuerySelectWithoutMemo({
	username,
	label,
	name = "projectKey",
	replaceOldQueryParams,
	navigateOptions,
	url,
	...props
}: ProjectSelectProps) {
	const { t } = useTranslation();
	const selectRef = useRef<React.ComponentRef<typeof Select>>(null);
	const { urlParams, setUrlParams } = useUrlParams();

	const { data: projects, loading } = useFetch<any, Project[]>({
		url: url ? url : DROPDOWN_PROJECT,
		params: {
			username,
		},
	});

	const options = useMemo(
		() =>
			projects?.map((value) => ({
				...value,
				label: value.name,
				value: value.key,
			})),
		[projects]
	);

	return (
		<ProjectQueryContainer>
			<H5
				onClick={() => {
					if (selectRef.current) {
						selectRef.current.focus();
					}
				}}
			>
				{label || t("project")}
			</H5>
			<SelectField
				mode={undefined}
				ref={selectRef}
				value={urlParams?.[name] as string}
				onChange={(value) => {
					const params = {
						newValue: {
							[name]: value,
						},
						replaceOldQueryParams,
					};
					if (typeof params.replaceOldQueryParams === "object") {
						params.newValue = {
							...params.replaceOldQueryParams,
							[name]: value,
						};
						params.replaceOldQueryParams = true;
					}
					setUrlParams(
						params.newValue,
						params.replaceOldQueryParams,
						navigateOptions
					);
				}}
				defaultActiveFirstOption
				allowClear={false}
				loading={loading}
				options={options}
				testId="project"
				{...props}
			/>
		</ProjectQueryContainer>
	);
}

interface IProjectSelectProps<M extends SelectMode = undefined>
	extends Omit<IApiSelectProps<Project, M>, "url" | "name"> {
	name?: string;
	projectUrl?: string;
}

export const ProjectSelect = <M extends SelectMode = undefined>({
	name = "projectKey",
	projectUrl = DROPDOWN_PROJECT,
	...props
}: IProjectSelectProps<M>) => {
	const { t } = useTranslation();

	return (
		<ApiSelect
			url={projectUrl}
			label={t("project")}
			name={name}
			mode="multiple"
			{...(props as any)}
		/>
	);
};
export const ProjectQuerySelect = React.memo(ProjectQuerySelectWithoutMemo);
