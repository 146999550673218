import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Form } from "antd";
import axiosClient from "api";
import { AddBtn, DeleteBtn, Heading } from "components";
import { PLAN_GENERAL_DURATION } from "constants/api";
import React, {
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { confirmDelete } from "utils/notifications";
import {
	combineDateNames,
	durationNames,
	DURATION_STATE,
	TDurationState,
} from "../constants";
import Duration from "./Duration";
const { Panel } = Collapse;

export type DurationKey = `${number}-${number}`;
export type OpenedDurations = Array<string>;

/**
 * convert key and name of duration to type {@link DurationKey} for saving both key and name, in order to control opening durations
 * @param key key of duration
 * @param name name of duration generated by `Form.List`
 * @returns "field-name" type {@link DurationKey}
 */
export const fieldToKey = (key: number, name: number) =>
	`${key}-${name}` as DurationKey;

/**
 * convert DurationKey back to `{ key, name }`
 * @param key has type {@link DurationKey}
 */
export const keyToField = (key: DurationKey) => {
	const [fieldKey, name] = key.split("-");
	return {
		key: +fieldKey,
		name: +name,
	};
};

type IDurationListProps = {
	name: string;
	isView?: boolean;
	openedDurations: OpenedDurations;
	setOpenedDurations: Dispatch<SetStateAction<OpenedDurations>>;
	durationKeysRef: MutableRefObject<DurationKey[]>;
	addDurationRef: MutableRefObject<Function | undefined>;
	loading: boolean;
};

function DurationList({
	name,
	isView,
	openedDurations,
	setOpenedDurations,
	durationKeysRef,
	addDurationRef,
	loading,
}: IDurationListProps) {
	const firstRef = useRef(0);
	const { t } = useTranslation("freeResource");
	const form = Form.useFormInstance();

	return (
		<Form.List name={name}>
			{(fields, { add, remove }) => {
				const handleAdd = () => {
					setOpenedDurations((prev) => prev.concat(firstRef.current + ""));
					add();
				};
				addDurationRef.current = handleAdd;
				durationKeysRef.current = [];
				const isLastDurationEnded =
					fields.length > 0
						? form.getFieldValue([
								name,
								fields[fields.length - 1].name,
								combineDateNames.actualDate,
						  ])?.[1]
						: true;

				const reverseFields = [];
				for (let i = fields.length - 1; i >= 0; i--) {
					const { key, ...restField } = fields[i];
					const durName = restField.name;
					durationKeysRef.current.push(fieldToKey(key, durName));

					if (key >= firstRef.current) {
						firstRef.current = key + 1;
					}
					const id = form.getFieldValue([name, durName, durationNames.id]);
					const actualDate = form.getFieldValue([
						name,
						durName,
						combineDateNames.actualDate,
					]);
					const thisDuration = `${t("duration")} ${i + 1}`;
					let durationState: TDurationState = DURATION_STATE.NEW;
					if (id) {
						if (actualDate?.[0]) {
							durationState = DURATION_STATE.CURRENT;
						}
						if (actualDate?.[1]) {
							durationState = DURATION_STATE.ENDED;
						}
					}
					const testId = `duration-${key}`;
					reverseFields.push(
						<React.Fragment key={key}>
							<Panel
								key={key}
								data-testid={testId}
								header={<Heading level={5}>{thisDuration}</Heading>}
								extra={
									!isView &&
									durationState === DURATION_STATE.NEW && (
										<DeleteBtn
											testId={testId}
											type="link"
											noText
											onClick={(e) => {
												e.stopPropagation();
												confirmDelete(
													async () => {
														if (id) {
															await axiosClient.delete(
																PLAN_GENERAL_DURATION + "/" + id
															);
														}
														remove(durName);
														setOpenedDurations((prev) =>
															prev.filter((prevKey) => +prevKey !== key)
														);
													},
													{
														content: thisDuration,
													}
												);
											}}
										/>
									)
								}
							>
								{/* Begin: Duration Form Item */}
								<Duration
									parentName={name}
									field={restField}
									forView={isView}
									durationState={durationState}
									loading={loading}
								/>
								{/* End: Duration Form Item */}
							</Panel>
							<hr />
						</React.Fragment>
					);
				}

				return (
					<div style={{ marginTop: 16 }}>
						{!isView && (
							<AddBtn
								testId="duration"
								onClick={handleAdd}
								subTitle={t("duration")}
								disabled={!isLastDurationEnded}
							/>
						)}
						<hr />
						<Collapse
							onChange={setOpenedDurations as any}
							activeKey={openedDurations}
							ghost
							expandIcon={({ isActive }) => (
								<CaretRightOutlined rotate={isActive ? 90 : 0} />
							)}
						>
							{reverseFields}
						</Collapse>
					</div>
				);
			}}
		</Form.List>
	);
}

export default React.memo(DurationList);
