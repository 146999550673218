import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import {
	CommonFilterForm,
	MemberSelect,
	ProjectSelect,
	SearchInput,
	StatusSelect,
	TableWithQuery,
} from "components";
import {
	DROPDOWN_PROJECT_PM,
	PLAN_PROJECT,
	PLAN_PROJECT_EXPORT,
} from "constants/api";
import { DEFAULT_THREE_RESOURCE_PARAMS } from "constants/apiParams";
import Layout from "layout";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { projectNames } from "translations/keys/plan";
import { PlanProjectRecord, PlanProjectRecordChild } from "types/plan/project";
import { concatString } from "utils";
import { DATE_PICKER_PROPS } from "../constants";
import { TToggleIconRender, createCols } from "./constants";

const params = DEFAULT_THREE_RESOURCE_PARAMS;

function PlanProject() {
	const { t } = useTranslation();
	const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
	const navigate = useNavigate();

	const toggle = (id: number) => {
		const isExpanded = expandedKeys.includes(id);
		if (isExpanded) setExpandedKeys((pre) => pre.filter((item) => item !== id));
		else setExpandedKeys((pre) => [...pre, id]);
	};

	const openDetail = useCallback(
		(record: PlanProjectRecord) =>
			navigate(
				`${
					(record as PlanProjectRecord)?.[projectNames.project] ||
					(record as any as PlanProjectRecordChild)?.[projectNames.projectKey]
				}`
			),
		[navigate]
	);

	const toggleIconRender: TToggleIconRender = (
		value: string,
		record: PlanProjectRecord
	) => {
		if (!record.data) return null;
		const id = record[projectNames.id];
		const isExpanded = expandedKeys.includes(id);
		return (
			<Button
				type="link"
				onClick={() => {
					toggle(id);
				}}
				onDoubleClick={(e) => e.stopPropagation()}
				data-testid={concatString("expand-row", id)}
				disabled={record.data.length < 1}
				icon={isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
			/>
		);
	};

	return (
		<Layout currentPageLabel={t("project")}>
			<div className="filter">
				<CommonFilterForm datePickerProps={DATE_PICKER_PROPS}>
					<ProjectSelect name={projectNames.project} />
					<MemberSelect
						name={projectNames.username}
						label={t("pm")}
						mode="multiple"
						url={DROPDOWN_PROJECT_PM}
					/>
					<StatusSelect name={projectNames.actionStatus} />
					<SearchInput placeholder="planProject" />
				</CommonFilterForm>
			</div>
			<TableWithQuery
				testId="All-project-plan"
				url={PLAN_PROJECT}
				defaultParams={params}
				exportParams={{
					url: PLAN_PROJECT_EXPORT,
					filename: `${t("function.all")} ${t("project")} ${t("plan")}`,
				}}
				columns={createCols(toggleIconRender)}
				rowKey={projectNames.id}
				expandable={{
					expandRowByClick: true,
					indentSize: 0,
					expandIcon: () => null,
					expandedRowKeys: expandedKeys,
					childrenColumnName: projectNames.children,
				}}
				actionColProps={{
					onDetail: openDetail,
				}}
			/>
		</Layout>
	);
}

export default PlanProject;
