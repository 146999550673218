import { Table, TablePaginationConfig, TableProps } from "antd";
import { IContentLayoutProps } from "components/LayoutComponents";
import { columnState } from "localStorage";
import { isFunction } from "lodash";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { ETableCol, ETableCols } from "types";
import { concatString } from "utils";
import { ActionCol, HandleFunctions } from "./components/ActionCol";
import { TableLayout } from "./components/TableComponents";

type IExtendContentLayout = Omit<
	IContentLayoutProps,
	| "children"
	| "className"
	| "cols"
	| "setCols"
	| "locale"
	| "fullScreen"
	| "header"
	| "exportFunc"
> & {
	utilProps?: Pick<IContentLayoutProps, "locale" | "extra" | "exportFunc">;
};

interface TActionColProps<RecordType>
	extends Omit<HandleFunctions<RecordType>, "record">,
		ETableCol<RecordType> {}

export interface TableBodyProps<RecordType>
	extends Omit<TableProps<RecordType>, "columns">,
		IExtendContentLayout {
	actionColProps?: TActionColProps<RecordType>;
	headerBtns?: ReactNode;
	columns?: ETableCols<RecordType>;
	pagination?:
		| (TablePaginationConfig & {
		"data-testid"?: string;
	})
		| false;
}

export const TableBody = <RecordType extends object>({
	className = "",
	columns = [],
	dataSource = [],
	rowKey = "id",
	headerBtns,
	rowSelection,
	utilProps = {},
	actionColProps,
	testId,
	onRow,
	...props
}: TableBodyProps<RecordType>) => {
	const { t } = useTranslation();
	const handleFullScreen = useFullScreenHandle();
	const [cols, setCols] = useState<ETableCols<RecordType>>(columns);

	useEffect(() => {
		const oldHiddenCols = columnState.getItem(testId);
		const newColumns: ETableCols<RecordType> = columns.map(
			({ onHeaderCell, onCell, testId: colId, ...col }) => {
				const columnId =
					typeof col.title === "string" ? col.title : (col.dataIndex as any);

				return {
					...col,
					hidden: oldHiddenCols?.includes(columnId) ?? col.hidden,
					onHeaderCell: (data, i) => {
						const header: React.HTMLAttributes<any> & {
							"data-testid"?: string;
						} = onHeaderCell?.(data, i) ?? {};
						if (col.title) {
							header["data-testid"] = concatString(testId, columnId, "header");
						}
						return header;
					},
					onCell: (data, i) => {
						const cell: React.HTMLAttributes<any> & {
							"data-testid"?: string;
						} = onCell?.(data, i) ?? {};
						cell["data-testid"] = concatString(
							testId,
							col.dataIndex as any,
							"row",
							i
						);
						return cell;
					},
				};
			}
		);
		if (actionColProps) {
			const actionTitle = t("function.action");
			const { onDetail, onDelete, onEdit, onCell, onStop, ...restProps } =
				actionColProps;
			newColumns.push({
				title: actionTitle,
				width: 80,
				align: "center",
				hidden: oldHiddenCols?.includes(actionTitle),
				onCell: (data, i) => {
					const cell = onCell?.(data, i) ?? {};
					cell.onDoubleClick = (e) => {
						e.stopPropagation();
					};
					return cell;
				},
				render: (_, record, index) => {
					return (
						<ActionCol
							testId={concatString(testId, "action-row", index)}
							getPopupContainer={() => document.querySelector(".fullscreen")!}
							record={record}
							onDetail={onDetail}
							onStop={onStop}
							onDelete={onDelete}
							onEdit={onEdit}
						/>
					);
				},
				...restProps,
			});
		}
		setCols(newColumns);
		//missing actionColProps
		// eslint-disable-next-line
	}, [columns, t]);

	const showCols = useMemo(() => cols.filter((col) => !col.hidden), [cols]);

	return (
		<TableLayout
			header={headerBtns}
			setCols={setCols}
			cols={cols}
			className={className}
			fullScreen={handleFullScreen}
			testId={testId}
			{...utilProps}
		>
			<Table
				data-testid={concatString(testId, "table")}
				columns={showCols}
				bordered={true}
				dataSource={dataSource}
				rowKey={rowKey}
				rowClassName={
					isFunction(actionColProps?.onDetail) ||
					isFunction(actionColProps?.onEdit)
						? "cursor-pointer"
						: ""
				}
				rowSelection={rowSelection}
				onRow={(data, i) => {
					const rowProps = onRow?.(data, i) ?? {};
					return {
						...rowProps,
						onDoubleClick: (e) => {
							rowProps?.onDoubleClick?.(e);
							if (isFunction(actionColProps?.onDetail)) {
								actionColProps!.onDetail(data);
							} else {
								actionColProps?.onEdit?.(data);
							}
						},
					};
				}}
				{...props}
			/>
		</TableLayout>
	);
};

export const ETable = React.memo(TableBody) as typeof TableBody;
