import styled from "@emotion/styled";
import { Form } from "antd";
import { ReactNode } from "react";
import { NamePath } from "antd/lib/form/interface";

interface EditableBox {
	isEditing?: boolean;
	hasError?: boolean;
}

export const ErrorBox = styled.div<EditableBox>`
	position: relative;
	display: flex;
	align-items: center;
	transition: all 0.3s;

	.ant-input,
	.ant-select-selector,
	.ant-input-affix-wrapper,
	.ant-input-group-addon,
	.ant-input-number-group-addon,
	.ant-input-number {
		border-color: ${({ hasError, theme }) =>
			hasError ? theme.color.danger : ""} !important;
	}

	.ant-input-affix-wrapper-focused,
	.ant-input-number-focused {
		box-shadow: ${({ hasError }) => hasError ?
			"0 0 0 2px rgba(255, 38, 5, 0.06)" : ""};
	}

	.ant-input-number-group-addon,
	.ant-input-group-addon {
		border-left: none;
	}
`;

export const ErrorCell = ({
	name,
	children,
}: {
	name: NamePath;
	children?: ReactNode;
}) => {
	return (
		<Form.Item noStyle shouldUpdate>
			{({ getFieldError }) => {
				const namePath = name;
				const error = getFieldError(namePath)?.[0];
				return (
					<ErrorBox hasError={error?.length > 0}>
						{children}
					</ErrorBox>
				);
			}}
		</Form.Item>
	);
};
