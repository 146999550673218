import {
	RangeField,
	TRangeFieldProps,
} from "components/CommonFields/RangeField";
import { viewDate } from "../utils";

const TimeRangeField = ({
	fieldProps = {},
	forView,
	...props
}: TRangeFieldProps & { forView?: boolean }) => {
	return (
		<RangeField
			fieldProps={{
				renderViewMode: viewDate,
				forView,
				...fieldProps,
			}}
			allowEmpty={[false, false]}
			{...props}
		/>
	);
};

export default TimeRangeField;
