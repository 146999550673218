import axiosClient from "api";
import { SYNC_URL } from "constants/api";

export const manualSync = {
	run: (projectKey: string[] = [""]) => {
		return axiosClient.get(SYNC_URL, {
			params: {
				projectKey,
			},
		});
	},
};
