import React, { useCallback } from "react";
import { Form } from "antd";
import { MCell, MNestedRow, MRow, RootMRow } from "components/NestedTable/components";
import { AddBtn, DeleteBtn, FormItem, InputField, InputNumberField } from "components";
import { NestedColumns } from "components/NestedTable/types";
import { getCol } from "components/NestedTable/utils";
import { StarCheckbox } from "../StarIcon";
import { UnitSelect } from "../Select";
import RangeField from "./RangeField";
import { useTranslation } from "react-i18next";
import WeightField from "./WeightField";
import AbsoluteField from "./AbsoluteField";

type EditMetricTableProps = {
	columns: NestedColumns
};

const ViewComponent = ({ value }: { value?: any }) => value

export const EditMetricTable = ({ columns }: EditMetricTableProps) => {
	const { t } = useTranslation("metrics");

	const getColStyle = useCallback((path: string[]) => {
		return getCol(columns, path)?.style || {};
	}, [columns])

	return (
		<Form.List name="groups">
			{(fields) =>
				fields.map(({ name: nameGroup, key }) => {
					return (
						<RootMRow key={key}>
							<MCell {...getColStyle(["name"])}>
								<Form.Item noStyle name={[nameGroup, "name"]}>
									<ViewComponent/>
								</Form.Item>
							</MCell>
							<MCell {...getColStyle(["weight"])}>
								<WeightField nameGroup={nameGroup}/>
							</MCell>
							<MNestedRow>
								<Form.List name={[nameGroup, "metric"]}>
									{(fields, { add, remove: removeMetric }) => (
										<>
											{fields.map(({ key, name: nameMetric }) => {
												return (
													<MRow key={key}>
														<MCell {...getColStyle(["metric", "name"])}>
															<InputField
																noStyle
																name={[nameMetric, "name"]}
																fieldProps={{
																	parentName: ["groups", nameGroup, "metric"],
																	shouldUpdate: true,
																}}
																rules={[{ required: true, whitespace: true }]}
																inputType={"input"}
																maxLength={15}
																addonAfter={<Form.Item noStyle name={[nameMetric, "metricRequired"]}>
																	<StarCheckbox/>
																</Form.Item>}/>
														</MCell>
														<MCell {...getColStyle(["metric", "unit"])}>
															<FormItem
																parentName={["groups", nameGroup, "metric"]}
																name={[nameMetric, "unit"]}
																noStyle
																rules={[{ required: true }]}>
																<UnitSelect/>
															</FormItem>
														</MCell>
														<MCell {...getColStyle(["metric", "description"])}>
															<InputField name={[nameMetric, "description"]} inputType="textarea" noStyle/>
														</MCell>
														<MCell {...getColStyle(["metric", "absolute"])}>
															<AbsoluteField nameMetric={nameMetric} parentName={["groups", nameGroup, "metric"]}/>
														</MCell>
														<MNestedRow>
															<Form.List name={[nameMetric, "metricValues"]}>
																{(fields, { remove, add }) => (
																	<>
																		{fields.map(({ key, name }) => (
																			<MRow key={key}>
																				<MCell {...getColStyle(["metric", "metricValues", "minScore"])}>
																					<RangeField nestedName={[nameGroup, nameMetric, name]} name="minScore"/>
																				</MCell>
																				<MCell {...getColStyle(["metric", "metricValues", "maxScore"])}>
																					<RangeField nestedName={[nameGroup, nameMetric, name]} name="maxScore"/>
																				</MCell>
																				<MCell {...getColStyle(["metric", "metricValues", "score"])}>
																					<FormItem
																						noStyle
																						shouldUpdate
																						name={[name, "score"]}
																						rules={[{ required: true }]}
																						parentName={["groups", nameGroup, "metric", nameMetric, "metricValues"]}
																					>
																						<InputNumberField/>
																					</FormItem>
																				</MCell>
																				<MCell {...getColStyle(["metric", "metricValues", "action"])}>
																					<DeleteBtn
																						noText
																						onClick={() => {
																							remove(name);
																							if (fields.length === 1) {
																								removeMetric(nameMetric)
																							}
																						}}
																					/>
																				</MCell>
																			</MRow>
																		))}
																		<MRow>
																			<MCell>
																				<AddBtn
																					transform="" type="text"
																					block
																					subTitle={t("evaluationTable.range").toLowerCase()}
																					onClick={() => add()}
																				/>
																			</MCell>
																		</MRow>
																	</>
																)}
															</Form.List>
														</MNestedRow>
													</MRow>
												);
											})}
											<MRow>
												<MCell>
													<AddBtn transform="" subTitle={t("metric")} block onClick={() => add({
														metricValues: [{
															min: 0,
															max: 0,
															score: 0
														}]
													})}/>
												</MCell>
											</MRow>
										</>
									)}
								</Form.List>
							</MNestedRow>
						</RootMRow>
					);
				})
			}
		</Form.List>
	);
};
