import styled from "@emotion/styled";
import { InputNumber, InputNumberProps } from "antd";
import { CONFIG_UNIT, SPECIAL_KEYS } from "constants/setting";
import { CSSProperties, KeyboardEventHandler, useCallback } from "react";
import { concatString } from "utils";

type StyledInputNumberProps = {
	width?: CSSProperties["width"];
};

export const StyledInput = styled(InputNumber)<StyledInputNumberProps>`
	width: ${({ width }) => width};
`;

interface IInputNumberFieldProps
	extends StyledInputNumberProps,
		Omit<InputNumberProps, "width"> {
	block?: boolean;
	/**
	 * prevent enter `.` when this prop is true
	 */
	onlyInteger?: boolean;
	testId?: string;
}

function InputNumberField({
	block = true,
	width,
	onKeyDown,
	onlyInteger,
	id,
	testId,
	...props
}: IInputNumberFieldProps) {
	const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>((e) => {
		const numberRegex = new RegExp(`[\\d${onlyInteger ? "" : "."}-]`);

		if (!(SPECIAL_KEYS.includes(e.key as any) || numberRegex.test(e.key))) {
			e.preventDefault();
		}
		onKeyDown?.(e);
	}, [onKeyDown, onlyInteger])

	return (
		<StyledInput
			width={block ? "100%" : width}
			onKeyDown={handleKeyDown}
			id={id}
			data-testid={concatString(id ?? testId, "input-number")}
			{...props}
		/>
	);
}

export const PercentInput = (props: IInputNumberFieldProps) => (
	<InputNumberField addonAfter={CONFIG_UNIT[1]} {...props}/>
);

export { InputNumberField };
