import styled from "@emotion/styled";
import { Button, Checkbox, Form, FormInstance, FormProps } from "antd";
import { memo, useEffect } from "react";
import { ECol } from "types";
import { concatString } from "utils";

const { List, Item } = Form;

export type TToggleCol = ECol & {
	[key: string]: any;
};

export interface EyeMenuProps<ColType extends TToggleCol = TToggleCol>
	extends Omit<FormProps, "onReset"> {
	form: FormInstance<{ cols: ColType[] }>;
	visible?: boolean;
	onOk?: (cols: ColType[]) => void;
	onReset?: (cols: ColType[]) => void;
	cols?: ColType[];
	okText?: string;
	resetText?: string;
}

const MenuBody = styled.div`
	padding: 0.75rem 0.75rem 0;
	max-height: 15rem;
	overflow-y: scroll;
`;

const MenuFooter = styled.div`
	margin-top: 0.5rem;
	border-top: 1px solid #e9e9e9;
	padding: 0.5rem 0.75rem;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: flex-end;
	cursor: default;
`;

const EyeMenu = <ColType extends TToggleCol = TToggleCol>({
	className,
	onOk = () => {},
	cols,
	visible,
	form,
	okText = "OK",
	resetText = "Reset",
	onReset,
}: EyeMenuProps<ColType>) => {
	useEffect(() => {
		visible &&
			form.setFieldsValue({
				cols: cols?.map(({ hidden, title, ...col }) => ({
					...col,
					title: typeof title === "object" ? title.props?.children : title,
					columnId: typeof title === "string" ? title : col.dataIndex,
					show: !hidden,
				})) as any,
			});
	}, [form, cols, visible]);

	return (
		<Form
			form={form}
			className={className}
			onFinish={(values) => {
				onOk(values.cols);
			}}
		>
			<MenuBody>
				<List name="cols">
					{(fields) => {
						return fields.map(({ key, name, ...restField }) => (
							<div key={key} className="eye-menu__row">
								<Item
									{...restField}
									noStyle
									shouldUpdate={(prev, curr) =>
										prev.cols[name]?.title !== curr.cols[name]?.title
									}
								>
									{({ getFieldValue }) => {
										const label = getFieldValue(["cols", name, "title"]);
										return (
											<>
												<Item
													{...restField}
													noStyle
													valuePropName="checked"
													name={[name, "show"]}
												>
													<Checkbox
														id={`col-show-${name}`}
														data-testid={concatString(label, "col-toggle")}
													/>
												</Item>
												<label
													htmlFor={`col-show-${name}`}
													className="eye-menu__label"
												>
													{label}
												</label>
											</>
										);
									}}
								</Item>
							</div>
						));
					}}
				</List>
			</MenuBody>
			<MenuFooter>
				{onReset && (
					<Button
						size="small"
						data-testid="btn-reset-col-state"
						onClick={() => {
							if (cols) {
								onReset(
									cols.map(
										({ hidden, show, ...col }) => ({ ...col } as ColType)
									)
								);
							}
						}}
					>
						{resetText}
					</Button>
				)}
				<Button
					htmlType="submit"
					data-testid="btn-submit-col-state"
					type="primary"
					size="small"
				>
					{okText}
				</Button>
			</MenuFooter>
		</Form>
	);
};

export default memo(EyeMenu) as typeof EyeMenu;
