import MemberMetrics from "pages/Configuration/MemberMetrics";
import ProjectJiraSync from "pages/Configuration/ProjectJiraSync";
import ProjectJiraSyncDetail from "pages/Configuration/ProjectJiraSync/ProjectJiraSyncDetail";
import ProjectMetrics from "pages/Configuration/ProjectMetrics";
import FreeResource from "pages/Plan/FreeResource";
import PlanProject from "pages/Plan/Project";
import PlanProjectLayout from "pages/Plan/components/PlanProjectLayout";
import ProjectKPI from "pages/ProjectKPI";
import ProjectKpiDetail from "pages/ProjectKPI/ProjectKpiDetail";
import MyWorkLog from "pages/WorkLog/MyWorkLog";
import ProjectReport from "pages/WorkLog/ProjectReport";
import ProjectWorkLog from "pages/WorkLog/ProjectWorkLog";
import { Navigate, RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
	{ path: "/", element: <Navigate to="/worklog" /> },
	{
		path: "/worklog",
		children: [
			{ index: true, element: <Navigate to="me" /> },
			{ path: "me", element: <MyWorkLog /> },
			{ path: "project", element: <ProjectWorkLog /> },
			{ path: "project-report", element: <ProjectReport /> },
		],
	},
	{
		path: "/plan",
		children: [
			{ index: true, element: <Navigate to="free" /> },
			{ path: "free", element: <FreeResource /> },
			{
				path: "project",
				children: [
					{ index: true, element: <PlanProject /> },
					{ path: ":id", element: <PlanProjectLayout /> },
				],
			},
		],
	},
	{
		path: "/project-kpi",
		children: [
			{ index: true, element: <ProjectKPI /> },
			{ path: ":id", element: <ProjectKpiDetail /> },
		],
	},
	{
		path: "/configuration",
		children: [
			{
				index: true,
				element: <Navigate to="project-jira-sync" />,
			},
			{
				path: "project-jira-sync",
				children: [
					{ index: true, element: <ProjectJiraSync /> },
					{ path: ":id", element: <ProjectJiraSyncDetail /> },
				],
			},
			{ path: "project-metrics", element: <ProjectMetrics /> },
			{ path: "member-metrics", element: <MemberMetrics /> },
		],
	},
];

export default routes;
