import React from 'react';
import { RollbackOutlined } from "@ant-design/icons";
import { EButton } from "components";
import { Form } from 'antd';

type ResetBtnProps = {
	onClick: () => void
};

const ResetBtn = (props: ResetBtnProps) => {
	return (
		<Form.Item noStyle shouldUpdate>
			{({ isFieldsTouched }) => (
				<EButton icon={<RollbackOutlined/>} title="reset" disabled={!isFieldsTouched()} {...props}/>
			)}
		</Form.Item>
	);
};

export default ResetBtn