import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ModalState = {
	record?: any;
	mode: "add" | "edit" | "sync";
	isOpened: boolean;
};

const initialState: ModalState = {
	mode: "add",
	isOpened: false,
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		openModal: (state) => {
			state.isOpened = true;
		},
		closeModal: (state) => {
			state.isOpened = false;
			state.record = undefined;
		},
		setModalRecord: (state, action) => {
			state.record = action.payload;
		},
		setModalMode: (state, action: PayloadAction<ModalState["mode"]>) => {
			state.mode = action.payload;
		},
	},
});

export const { openModal, closeModal, setModalRecord, setModalMode } =
	modalSlice.actions;

export default modalSlice.reducer;
