import { EllipsisOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Popover, PopoverProps } from "antd";
import { AddTestId } from "utils/typeUtils";
import "./popover.css";

const Dot = styled(EllipsisOutlined)`
	cursor: pointer;
	font-size: 1.5rem;
`;

export function DotPopover({ testId, ...props }: AddTestId<PopoverProps>) {
	return (
		<Popover
			{...props}
			overlayClassName="popover--action-col"
			placement="bottom"
		>
			<Dot data-testid={testId} />
		</Popover>
	);
}
