import {
	effortNames,
	billableFormNames,
	projectNames,
	freeResourceNames,
} from "translations/keys/plan";
import { planKey, translationKey } from "translations/utils";
import { addBudgetUnit, addLogtimeUnit } from "utils";

const plan = {
	effort: "effort",
	convertedEffort: "converted $t(effort)",
	contractType: "Contract type",
	member: translationKey("member"),
	members: "$t(member)(s)",
	general: "general",
	allocationEffort: "Allocation $t(effort)",
	billableEffort: "Billable $t(effort)",
	actualConvertedEffort: "Actual $t(plan:convertedEffort)",
	calendarEffort: "Calendar $t(effort)",
	billableHeader: {
		members: "Total $t(member)",
		actualConvertedEffort: "Total converted billable $t(effort)",
		calendarEffort: "Total billable calendar $t(effort)",
	},
	project: {
		[projectNames.projectName]: "Project name",
		[projectNames.project]: "Project key",
		[projectNames.pm]: translationKey("pm"),
		[projectNames.member]: "$t(member)",
		[projectNames.planStart]: "Plan start",
		[projectNames.planEnd]: "Plan end",
		[projectNames.actualStart]: "Actual start",
		[projectNames.actualEnd]: "Actual end",
		[projectNames.abe]: {
			short: addBudgetUnit("ABE"),
			full: "Approved budget $t(effort)",
		},
		[projectNames.pe]: {
			short: addBudgetUnit("PE"),
			full: `${translationKey("plan")} $t(effort)`,
		},
		[projectNames.ace]: {
			short: addBudgetUnit("ACE"),
			full: "$t(plan:actualConvertedEffort)",
		},
		[projectNames.ale]: {
			short: addBudgetUnit("ALE"),
			full: "Actual logged $t(effort)",
		},
		[projectNames.billable]: addBudgetUnit("Billable converted effort"),
		[projectNames.actionStatus]: "status"

	},
	allocation: {
		editMember: `${translationKey("function.edit")} ${translationKey(
			"member"
		)}`,
		role: {
			teamlead: "Teamlead",
			keyMember: "Key member",
			member: `${translationKey("member")} (Dev, BA, Test)`,
		},
		[effortNames.fullName]: "fullname",
		[effortNames.username]: "fullname",
		[effortNames.position]: translationKey("position"),
		[effortNames.rank]: translationKey("rank"),
		[effortNames.contractType]: planKey("contractType"),
		[effortNames.worked]: addLogtimeUnit(translationKey("worked")),
		[effortNames.startDate]: "Start date",
		[effortNames.endDate]: "End date",
		[effortNames.convertedEffort]: addLogtimeUnit(planKey("convertedEffort")),
	},
	billable: {
		t: "billable",
		[effortNames.fullName]: translationKey("member"),
		[billableFormNames.note]: translationKey("note"),
		[effortNames.convertedEffort]: addLogtimeUnit(
			`converted ${planKey("billable.t")} ${planKey("effort")}`
		),
		[effortNames.calendarEffort]: addLogtimeUnit(
			`${planKey("billable.t")} calendar ${planKey("effort")}`
		),
	},
	free: {
		[freeResourceNames.memberType]: "$t(member) type",
		[freeResourceNames.allocatedHour]: "Allocated hour",
		totalContractWorkingHour: "Total contract working hour",
		[freeResourceNames.note]: translationKey("note"),
	},
};

export default plan;
