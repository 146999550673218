import { PlusOutlined } from "@ant-design/icons";
import { message, Tag } from "antd";
import { fetchTable } from "appStore/commonSlice";
import { CommonFilterForm, EButton, ProjectSelect, TableWithQuery, } from "components";
import { PUT_STOP_SYNC, SYNC_HISTORY } from "constants/api";
import { useCreateCols, useGetPermission } from "hooks";
import CustomLayout from "layout";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "translations";
import axiosClient from "api";
import { PERMISSION } from "constants/permissionMap";
import ModalSync, { TModalSyncRef } from "./components/ModalSync";
import SyncOptionsSelect from "./components/SyncOptionsSelect";
import SyncStatusSelect from "./components/SyncStatusSelect";
import { SYNC_STATUS } from "./constants";
import { TKeySyncStatus, TSyncHistoryData } from "./types";

export const statusSyncRender = (value: string) => {
	const key = (value as string)?.toLocaleLowerCase() as TKeySyncStatus;
	return (
		<Tag color={SYNC_STATUS[key]}>
			{i18n.t(`projectJiraSync:syncStatus.${key}`) as string}
		</Tag>
	);
};

function ProjectJiraSync() {
	const { t } = useTranslation("projectJiraSync");

	const navigate = useNavigate();
	const { permissions } = useGetPermission();
	const editPermission = permissions?.includes(PERMISSION.EDIT);
	const modalRef = useRef<TModalSyncRef>();
	const dispatch = useDispatch();

	const columns = useCreateCols<TSyncHistoryData, "projectJiraSync:tableLabel">(
		"projectJiraSync:tableLabel",
		[
			{
				title: "No.",
				dataIndex: "index",
				key: "index",
				render: (_, __, index) => index + 1,
			},
			{
				dataIndex: "projectName",
			},
			{
				dataIndex: "projectKey",
			},
			{
				dataIndex: "time",
			},
			{
				dataIndex: "status",
				render: statusSyncRender,
			},
		]
	);

	const StopSync = async (value: { projectKey: { toString: () => any } }) => {
		const config = {
			projectKeys: value?.projectKey.toString(),
		};
		try {
			await axiosClient.put(PUT_STOP_SYNC, null, {
				params: config,
			});
			message.success(
				t("message.stopSuccess", { value: value?.projectKey.toString() })
			);
			dispatch(fetchTable());
		} catch (error) {
			message.error((error as any).message);
		}
	};
	return (
		<>
			<CustomLayout currentPageLabel={t("label")}>
				<div className="filter">
					<CommonFilterForm
						datePickerProps={{
							allowEmpty: [true, true],
						}}
						haveFilterTime={false}
					>
						<ProjectSelect name="projects"/>
						<SyncStatusSelect/>
						{/* <AllowedToSyncFilter /> */}
					</CommonFilterForm>
				</div>
				<TableWithQuery
					testId="project-jira-sync"
					url={SYNC_HISTORY}
					columns={columns}
					headerBtns={
						editPermission ? (
							<EButton
								type="primary"
								icon={<PlusOutlined/>}
								onClick={() => modalRef?.current?.open()}
							>
								{t("allowSync.addSync")}
							</EButton>
						) : undefined
					}
					rowKey="projectKey"
					actionColProps={{
						onStop: editPermission ? StopSync : undefined,
						onDetail: (record) => {
							navigate(`${record.projectKey}`);
						},
					}}
					utilProps={{
						extra: editPermission ? <SyncOptionsSelect/> : <></>,
					}}
				/>
			</CustomLayout>
			<ModalSync ref={modalRef as any}/>
		</>
	);
}

export default React.memo(ProjectJiraSync);
