import { Form } from "antd";
import {
	InputNumberField,
	FormItem,
	InputField,
	StatusRadio,
} from "components";
import { isNumber } from "lodash";
import { FormGroup } from "pages/Plan/Allocation/compontents/ModalForm";
import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { memberMetricNames } from "translations/keys/memberMetrics";

const { useFormInstance, useWatch } = Form;

function CommonFields() {
	const { t } = useTranslation("form", {
		keyPrefix: "validateMsg",
	});
	const form = useFormInstance();
	const min = useWatch([memberMetricNames.min], form);
	const max = useWatch([memberMetricNames.max], form);

	const createRule = useCallback(
		(condition: (value: number) => boolean) => ({
			validator: (_: any, value?: number | null) => {
				if (isNumber(value) && condition(value)) {
					return Promise.reject(t("invalidValue"));
				}
				return Promise.resolve("");
			},
		}),
		[t]
	);

	useEffect(() => {
		if ((min ?? 0) && (max ?? 0)) {
			form.validateFields([
				memberMetricNames.min,
				memberMetricNames.max,
				memberMetricNames.default,
			]);
		}
	}, [min, max, form]);

	return (
		<>
			<InputField name={memberMetricNames.description} inputType="textarea" />
			<FormGroup>
				<FormItem
					name={memberMetricNames.min}
					rules={[createRule((value) => max <= value)]}
					required
				>
					<InputNumberField />
				</FormItem>
				<FormItem
					rules={[createRule((value) => min >= value)]}
					name={memberMetricNames.max}
					required
				>
					<InputNumberField />
				</FormItem>
			</FormGroup>
			<FormItem
				name={memberMetricNames.default}
				rules={[createRule((value) => min >= value || max < value)]}
				required
			>
				<InputNumberField />
			</FormItem>
			<StatusRadio />
		</>
	);
}

export default memo(CommonFields);
