import CustomLayout from "layout";
import { useParams } from "react-router-dom";
import PlanAllocation from "../Allocation";
import PlanBillable from "../Billable";
import PlanGeneral from "../General";
import { planTranslate } from "../utils";

const Tabs = [
	{
		tab: planTranslate("general"),
		key: "general",
		// @ts-ignore
		children: <PlanGeneral />,
	},
	{
		tab: planTranslate("allocationEffort"),
		key: "allocation",
		// @ts-ignore
		children: <PlanAllocation />,
	},
	{
		tab: planTranslate("billableEffort"),
		key: "billable",
		// @ts-ignore
		children: <PlanBillable />,
	},
];

function PlanProjectLayout() {
	const { id } = useParams();
	return (
		<CustomLayout
			currentPageLabel={id}
			tabProps={{
				items: Tabs,
			}}
		/>
	);
}

export default PlanProjectLayout;
