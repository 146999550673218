import { ApiResponse } from "./../types/apiTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TableRes<
	DataType,
	Other extends Record<string, any> = {}
> = ApiResponse<DataType> & Other;

type CommonState = {
	tableResponse?: any;
	fetchTable: boolean;
	loading: boolean;
};

const initialState: CommonState = {
	tableResponse: undefined,
	fetchTable: false,
	loading: false,
};

const commonSlice = createSlice({
	name: "common",
	initialState,
	reducers: {
		setTableResponse: <DataType, Other extends Record<string, any> = {}>(
			state: CommonState,
			{ payload }: PayloadAction<TableRes<DataType, Other>>
		) => {
			state.tableResponse = payload;
		},
		fetchTable: (state) => {
			state.fetchTable = !state.fetchTable;
		},
		setLoading: (state, { payload }: { payload: boolean }) => {
			state.loading = payload;
		},
	},
});

export const { setTableResponse, fetchTable, setLoading } = commonSlice.actions;

export default commonSlice.reducer;
