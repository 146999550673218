import styled from "@emotion/styled";
import { CommonFilterForm, Paper, PositionSelect, RankSelect, SearchInput, TableWithQuery, } from "components";
import { PLAN_ALLOCATION, PLAN_ALLOCATION_EXPORT } from "constants/api";
import { DEFAULT_PLAN_ALLOCATION_PARAMS } from "constants/apiParams";
import { useAppSelector } from "hooks";
import { useEditFunc } from "hooks/useEditFunc";
import { useParams } from "react-router-dom";
import { effortNames } from "translations/keys/plan";
import { HeaderInfoObj } from "../components/SmallComponents";
import { DATE_PICKER_PROPS } from "../constants";
import EditForm from "./compontents/ModalForm";
import { cols } from "./constants";
import { useTranslation } from "react-i18next";
import { PERMISSION, PermissionProps } from "constants/permissionMap";

const PaddingHeader = styled(Paper)`
	padding: 0.625rem 1rem;
`;

function PlanAllocation({ permissions }: PermissionProps<{}>) {
	const { id } = useParams();
	const { t } = useTranslation();
	const { handleEdit } = useEditFunc();
	const { tableResponse: headerData } = useAppSelector((state) => state.common);

	return (
		<>
			<PaddingHeader className="filter">
				<EditForm/>
				<CommonFilterForm
					style={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
					datePickerProps={DATE_PICKER_PROPS}
				>
					<PositionSelect name="position" mode="multiple"/>
					<RankSelect name="rank" mode="multiple"/>
					<SearchInput placeholder="planDetail"/>
				</CommonFilterForm>
			</PaddingHeader>
			<TableWithQuery
				testId="plan-allocationEffort"
				defaultParams={DEFAULT_PLAN_ALLOCATION_PARAMS}
				requiredParams={{
					projectKey: id,
				}}
				headerBtns={
					<HeaderInfoObj data={headerData} testId="plan-allocationEffort"/>
				}
				preHandleData={(data: any) => data?.allocationEffortPagingDTO}
				exportParams={{
					url: `${PLAN_ALLOCATION_EXPORT}?projectKey=${id}`,
					filename: `${id} ${t("allocationEffort")}`,
				}}
				url={PLAN_ALLOCATION}
				rowKey={(record) =>
					`${record[effortNames.id]}-${record[effortNames.recordIndex]}`
				}
				columns={cols}
				actionColProps={
					permissions.includes(PERMISSION.EDIT)
						? {
							onEdit: handleEdit,
						}
						: undefined
				}
			/>
		</>
	);
}

export default PlanAllocation;
