import { INavigateTabProps } from "hooks";
import { translate } from "utils";

export const TAB_KEYS = ["setOfMetrics", "projectMetrics"] as const;

export type TTab_Key = typeof TAB_KEYS[number];

export const TABS_PROPS: INavigateTabProps = {
	items: TAB_KEYS.map((key) => ({
		key,
		tab: translate(`metrics:${key}`),
	})),
};
