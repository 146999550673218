import axiosClient from "api";
import { setTableResponse } from "appStore/commonSlice";
import { ETable, TableBodyProps } from "components";
import { DEFAULT_PAGINATION_PARAMS, SORT } from "constants/apiParams";
import { PAGE_SIZES } from "constants/format";
import {
	UseFetchWithUrlParams,
	useAppSelector,
	useFetchWithUrlParams,
	useUrlParams,
} from "hooks";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ApiParams, ApiResponse, Primitive } from "types";
import { concatString, exportWithConfirm, handleExportDate } from "utils";
export interface TableWithQueryProps<
	DataType extends object,
	Response = ApiResponse<DataType>,
	QueryParams extends ApiParams = ApiParams
> extends Omit<TableBodyProps<DataType>, "loading"> {
	url: string;
	defaultParams?: QueryParams;
	requiredParams?: object;
	apiConfig?: UseFetchWithUrlParams["config"];
	preHandleData?: (data: Response) => ApiResponse<DataType>;
	refetchDep?: any;
	exportParams?: {
		url: string;
		filename: string;
	};
}

function TableWithQueryNoMemo<
	DataType extends object,
	Response = ApiResponse<DataType>,
	QueryParams extends ApiParams = ApiParams
>({
	url,
	defaultParams,
	columns,
	requiredParams,
	apiConfig,
	refetchDep,
	preHandleData = (data) => data as unknown as ApiResponse<DataType>,
	actionColProps,
	testId,
	pagination,
	exportParams,
	utilProps,
	...props
}: TableWithQueryProps<DataType, Response, QueryParams>) {
	const { t } = useTranslation();
	const { urlParams, setUrlParams } = useUrlParams();
	const { fetchTable, loading: manualSetLoading } = useAppSelector(
		(state) => state.common
	);
	const dispatch = useDispatch();
	const updateDep = useMemo(
		() => [refetchDep, fetchTable],
		[refetchDep, fetchTable]
	);
	const { data, loading } = useFetchWithUrlParams<DataType, Response>({
		url,
		defaultParams,
		refetchDep: updateDep,
		requiredParams,
		config: apiConfig,
	});

	useEffect(() => {
		dispatch(setTableResponse(data as unknown as ApiResponse<DataType>));
	}, [data, dispatch]);

	const handledData = useMemo(() => {
		if (data) {
			return preHandleData(data);
		}
		// eslint-disable-next-line
	}, [manualSetLoading, loading, data]);

	const current =
		Number(handledData?.page) > 0
			? handledData?.page
			: DEFAULT_PAGINATION_PARAMS.pageNo;

	const pageSize = urlParams.pageSize
		? +urlParams.pageSize
		: DEFAULT_PAGINATION_PARAMS.pageSize;

	useEffect(() => {
		let pageNo = current;
		if (pageNo && handledData?.total && pageSize) {
			const totalPage = Math.ceil(handledData?.total / pageSize);
			if (pageNo > totalPage) {
				pageNo--;
				setUrlParams({
					pageNo,
				});
			}
		}
		// eslint-disable-next-line
	}, [handledData]);

	const pageSizeOptions = useMemo(() => {
		const options = [];
		if (handledData?.total) {
			for (let i = 0; i < PAGE_SIZES.length; i++) {
				const pageSize = PAGE_SIZES[i];
				options.push(pageSize);
				if (pageSize >= handledData?.total) {
					break;
				}
			}
		}
		return options;
	}, [handledData?.total]);

	const colsWithSort = useMemo(() => {
		if (columns) {
			return columns.map((col) => {
				if (col.sorter) {
					col.sortOrder =
						urlParams.column === col.dataIndex
							? urlParams.sort === SORT.ASC
								? "ascend"
								: "descend"
							: undefined;
				}
				return col;
			});
		}
		return columns;
	}, [columns, urlParams.column, urlParams.sort]);

	const utilPropsWithExport = useMemo(() => {
		const newUtilProps = utilProps ?? {};
		if (exportParams) {
			newUtilProps.exportFunc = () => {
				const exportQuery = { ...urlParams };
				delete exportQuery.pageNo;
				delete exportQuery.pageSize;
				exportWithConfirm(
					async () => {
						const { data: exportFile } = await axiosClient.get<Blob>(
							`${process.env.REACT_APP_BASE_URL}${exportParams.url}`,
							{
								params: exportQuery,
								responseType: "blob",
							}
						);
						return window.URL.createObjectURL(exportFile);
					},
					`${handleExportDate({
						excelName: exportParams.filename,
						...exportQuery,
					})}.xlsx`
				);
			};
		}
		return newUtilProps;
	}, [utilProps, exportParams, urlParams]);

	return (
		<ETable<DataType>
			testId={testId}
			loading={manualSetLoading || loading}
			dataSource={handledData?.resource}
			columns={colsWithSort}
			scroll={{
				x: "max-content",
			}}
			pagination={{
				current,
				total: handledData?.total,
				pageSize,
				showQuickJumper: true,
				showSizeChanger: true,
				pageSizeOptions,
				showTotal(total) {
					return (
						<p data-testid={concatString(testId, "total-record")}>
							{t("record", { count: total })}
						</p>
					);
				},
				itemRender(page: any, type: any, element: any) {
					return element
						? React.cloneElement(element as any, {
								"data-testid": concatString(
									testId,
									(type === "page" ? `page-${page}` : `${type}-page`) as any
								),
						  })
						: element;
				},
				"data-testid": concatString(testId, "table-pagination"),
				...pagination,
			}}
			onChange={(pagination, _, sorter) => {
				const newParams: Record<string, Primitive> = {
					pageNo: pagination.current,
					pageSize: pagination.pageSize,
				};
				if (!Array.isArray(sorter)) {
					if (sorter.field && sorter.order) {
						newParams.sort = sorter.order === "ascend" ? SORT.ASC : SORT.DESC;
						newParams.column = sorter.field?.toString();
					} else {
						newParams.sort = undefined;
						newParams.column = undefined;
					}
				}
				setUrlParams(newParams);
			}}
			actionColProps={actionColProps}
			utilProps={utilPropsWithExport}
			{...props}
		/>
	);
}
const TableWithQuery = React.memo(
	TableWithQueryNoMemo
) as typeof TableWithQueryNoMemo;

export { TableWithQuery };
