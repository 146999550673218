import {
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	SaveOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, ButtonProps, Form } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Translation } from "translations";
import { ExtendItemProps } from "types";
import { concatString } from "utils";

type TBtnText = keyof Translation["translation"]["function"];

interface CommonTextBtnProps extends Omit<ButtonProps, "title" | "children"> {
	title?: TBtnText | Omit<string, TBtnText>;
	subTitle?: ReactNode;
	children?: ReactNode;
	noText?: boolean;
	testId?: string | string[];
}

interface CommonIconBtnProps extends CommonTextBtnProps {
	noText: true;
	icon: React.ReactNode;
}

interface CommonIconWithTextBtnProps extends CommonTextBtnProps {
	noText?: false;
	title?: TBtnText | Omit<string, TBtnText>;
}

type CommonBtnProps = Omit<
	CommonIconBtnProps | CommonIconWithTextBtnProps,
	"title"
> & {
	transform?: "capitalize" | "uppercase" | "";
};

const CommonTextBtn = ({
	title,
	subTitle,
	children,
	noText,
	testId,
	...props
}: CommonIconBtnProps | CommonIconWithTextBtnProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "function",
	});

	return (
		<Button
			data-testid={concatString(testId, title as string, "btn")}
			{...props}
		>
			{!noText &&
				(children ?? (
					<>
						{t(title as string)} {subTitle}
					</>
				))}
		</Button>
	);
};

export const EButton = styled(CommonTextBtn, {
	shouldForwardProp: (propName) => propName !== "transform",
})<CommonBtnProps>`
	text-transform: ${({ transform = "uppercase" }) => transform};
`;

export const ApprovedBtn = (props: CommonBtnProps) => (
	<EButton type="primary" icon={<CheckOutlined/>} title="approve" {...props} />
);

export const RejectBtn = (props: CommonBtnProps) => (
	<EButton icon={<CloseOutlined/>} danger title="reject" {...props} />
);

interface IFormBtn extends CommonBtnProps, ExtendItemProps<"noStyle"> {}

export const SaveBtn = ({
	noStyle = true,
	fieldProps = {},
	...props
}: IFormBtn) => (
	<Form.Item noStyle={noStyle} shouldUpdate {...fieldProps}>
		{({ getFieldsError, isFieldsTouched }) => {
			const disabled =
				!isFieldsTouched() ||
				getFieldsError().some(({ errors }) => errors.length > 0);
			return (
				<EButton
					icon={<SaveOutlined/>}
					title="save"
					htmlType="submit"
					type="primary"
					disabled={disabled}
					{...props}
				/>
			);
		}}
	</Form.Item>
);

export const CancelBtn = (props: CommonBtnProps) => (
	<EButton icon={<CloseOutlined/>} title="cancel" {...props} />
);

export const EditBtn = (props: CommonBtnProps) => (
	<EButton type="primary" icon={<EditOutlined/>} title="edit" {...props} />
);

export const AddBtn = (props: CommonBtnProps) => (
	<EButton type="link" title="add" icon={<PlusOutlined/>} {...props} />
);

export const DeleteBtn = (props: CommonBtnProps) => (
	<EButton title="delete" icon={<DeleteOutlined/>} danger {...props} />
);

export const HeaderBtnGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 0.625rem;
	background-color: white;
`;
