import styled from "@emotion/styled";
import { ContentLayout } from "components/LayoutComponents";

export const TableLayout = styled(ContentLayout)`
	--antd-wave-shadow-color: var(--primary);

	.ant-table-content::-webkit-scrollbar {
		height: 0.5rem; /* height of horizontal scrollbar*/
		width: 0.5rem; /* width of vertical scrollbar */
	}

	.ant-table-content::-webkit-scrollbar-thumb {
		background-color: #c1c1c1;
		height: 0.5rem;
		border-top-left-radius: 10rem;
		border-top-right-radius: 10rem;
		border-bottom-right-radius: 10rem;
		border-bottom-left-radius: 10rem;
	}

	.ant-table {
		background-color: var(--background);
	}
	.ant-table-thead > tr > th {
		background-color: #e7f0fb;
		::first-letter {
			text-transform: capitalize;
		}

		.ant-table,
		.ant-pagination,
		.ant-pagination-prev,
		.ant-pagination-next,
		.ant-pagination-jump-prev,
		.ant-pagination-jump-next,
		.ant-table-thead > tr > th {
			color: var(--text-color);
		}
		.ant-pagination-item,
		.ant-pagination-next,
		.ant-pagination-prev {
			&:hover,
			&-active {
				.ant-pagination-item-link:not(:disabled) {
					border-color: var(--primary);
				}
				border-color: var(--primary);
				color: var(--primary);
			}
			a,
			button:not(:disabled) {
				color: inherit;
				transition: none;
			}
		}

		/* .ant-table-tbody > tr:nth-of-type(2n), */
		.ant-table-tbody > tr:hover {
			> td {
				background-color: ${({ theme }) => theme.hover.medium};
			}
		}
		.ant-table-tbody > tr:hover > td {
			background-color: ${({ theme }) => theme.hover.dark};
		}

		input:focus {
			border-color: var(--primary);
		}
	}
	.ant-table-tbody > tr > td {
		padding: 8px 16px;
	}
	.ant-checkbox-wrapper:hover,
	.ant-checkbox:hover {
		.ant-checkbox-inner {
			border-color: var(--primary);
		}
	}
	.ant-checkbox-checked {
		.ant-checkbox-inner {
			border-color: var(--primary);
			background-color: var(--primary);
			&::after {
				border-color: var(--background);
			}
		}
		&::after {
			border-color: var(--primary);
		}
	}
	.ant-checkbox-indeterminate .ant-checkbox-inner::after {
		background-color: var(--primary);
	}

	.ant-btn.ant-btn-primary:not(:disabled) {
		border-color: var(--primary);
		background-color: var(--primary);
	}

	box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
		rgba(17, 17, 26, 0.05) 0px 8px 32px;
	/* border-radius: 10px; */
`;
