enum PERMISSION {
	VIEW = "VIEW",
	EDIT = "EDIT",
	APPROVE = "APPROVE",
}

type TPermissionCode = {
	[key in PERMISSION]?: string;
};

const PERMISSIONS_MAP: Record<
	string,
	{
		permissions?: TPermissionCode;
		tabs?: Record<string, TPermissionCode>;
	}
> = {
	"/worklog/me": {
		permissions: {
			[PERMISSION.VIEW]: "F1C1",
		},
	},
	"/worklog/project": {
		permissions: {
			[PERMISSION.VIEW]: "F2C1",
			[PERMISSION.EDIT]: "F2C2",
		},
	},
	"/worklog/project-report": {
		tabs: {
			me: { [PERMISSION.VIEW]: "F3C1" },
			project: { [PERMISSION.VIEW]: "F3C2" },
			all: { [PERMISSION.VIEW]: "F3C3" },
		},
	},
	"/plan/free": {
		permissions: {
			[PERMISSION.VIEW]: "F4C1",
			[PERMISSION.EDIT]: "F4C2",
		},
	},
	"/plan/project": {
		permissions: {
			[PERMISSION.VIEW]: "F5C1",
		},
	},
	"/plan/project/:id": {
		tabs: {
			general: {
				[PERMISSION.VIEW]: "F5C2.1",
				[PERMISSION.EDIT]: "F5C2.2",
			},
			allocation: {
				[PERMISSION.VIEW]: "F5C3.1",
				[PERMISSION.EDIT]: "F5C3.2",
			},
			billable: {
				[PERMISSION.VIEW]: "F5C4.1",
				[PERMISSION.EDIT]: "F5C4.2",
			},
		},
	},
	"/configuration/project-jira-sync": {
		permissions: {
			[PERMISSION.VIEW]: "F6C1.1",
			[PERMISSION.EDIT]: "F6C1.2",
		},
	},
	"/configuration/project-jira-sync/:id": {
		permissions: {
			[PERMISSION.VIEW]: "F6C2",
		},
	},
	"/configuration/project-metrics": {
		tabs: {
			setOfMetrics: {
				[PERMISSION.VIEW]: "F7C1",
				[PERMISSION.EDIT]: "F7C2",
			},
			projectMetrics: {
				[PERMISSION.VIEW]: "F7C3",
				[PERMISSION.EDIT]: "F7C4",
			},
		},
	},
	"/configuration/member-metrics": {
		tabs: {
			setOfMetrics: {
				[PERMISSION.VIEW]: "F8C1",
				[PERMISSION.EDIT]: "F8C2",
			},
			projectMetrics: {
				[PERMISSION.VIEW]: "F8C3",
				[PERMISSION.EDIT]: "F8C4",
			},
		},
	},
	"/project-kpi": {
		permissions: {
			[PERMISSION.VIEW]: "F9C1",
		},
	},
	"/project-kpi/:id": {
		tabs: {
			detail: {
				[PERMISSION.VIEW]: "F10C1.1",
				[PERMISSION.APPROVE]: "F10C3",
			},
		},
	},
	"/project-kpi/:id/edit": {
		permissions: {
			[PERMISSION.VIEW]: "F10C2.1",
			[PERMISSION.EDIT]: "F10C2.2",
		},
	},
	"/project-kpi/member": {
		permissions: {
			[PERMISSION.VIEW]: "F11C1.1",
			[PERMISSION.EDIT]: "F11C2",
			[PERMISSION.APPROVE]: "F11C3",
		},
	},
};

export type PermissionProps<T> = T & {
	permissions: PERMISSION[];
}
export type TPermissions = Record<
	string,
	{
		permissions: PERMISSION[];
		tabs: Record<string, PERMISSION[]>;
	}
>;

export { PERMISSIONS_MAP, PERMISSION };
