import styled from "@emotion/styled";
import { Table } from "antd";
import { TooltipParagraph } from "components";

export const RequiredLabel = ({ text }: { text: string }) => {
	return <label className="ant-form-item-required">{text}</label>;
};

export const SummaryCell = styled(Table.Summary.Cell)`
	font-weight: 700;
	text-transform: uppercase;
`;

export const renderLongText = (value: any) => (
	<TooltipParagraph value={value} row={2} />
);
