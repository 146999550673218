import { openModal, setModalMode, setModalRecord } from "appStore/modalSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

function useEditFunc() {
	const dispatch = useDispatch();
	const handleEdit = useCallback(
		<DataType>(record: DataType) => {
			dispatch(openModal());
			dispatch(setModalRecord(record));
			dispatch(setModalMode("edit"));
		},
		[dispatch]
	);
	return { handleEdit };
}

function useAddFunc() {
	const dispatch = useDispatch();
	const handleAdd = useCallback(() => {
		dispatch(openModal());
		dispatch(setModalRecord(undefined));
		dispatch(setModalMode("add"));
	}, [dispatch]);
	return { handleAdd };
}

export { useEditFunc, useAddFunc };
