import { Tag, Tooltip } from "antd";
import { projectNames } from "translations/keys/plan";
import { ETableCol, ETableCols } from "types";
import { PlanProjectRecord } from "types/plan/project";
import { formatNum, renderNull } from "utils";
import FullNameAndUsername from "../components/FullNameAndUsername";
import { createPlanCol, planTranslate } from "../utils";
import { LIST_STATUS_PLAN } from "../General/constants";
import { find } from "lodash";

export type TToggleIconRender = ETableCol<PlanProjectRecord>["render"];

const createProjectCol: typeof createPlanCol = (...args) =>
	createPlanCol(args[0], args[1], "project");

const createPlanColWithRender: typeof createPlanCol = (name, other) =>
	createPlanCol(
		name,
		{
			render: renderNull,
			...other,
		},
		"project"
	);

const createPlanColWithTooltip: typeof createPlanColWithRender = (
	name,
	other
) => {
	const fullTitle = planTranslate(`${name}.full`, "project");
	return createPlanColWithRender(name, {
		...other,
		title: () => (
			<Tooltip title={fullTitle}>
				{planTranslate(`${name}.short`, "project")}
			</Tooltip>
		),
		render: formatNum,
	});
};

export const createCols = (
	toggleIconRender: TToggleIconRender
): ETableCols<PlanProjectRecord> => {
	return [
		{
			align: "center",
			width: 80,
			render: toggleIconRender,
		},
		createProjectCol(projectNames.projectName, {
			sorter: true,
		}),
		createProjectCol(projectNames.project, {
			sorter: true,
			hidden: true,
		}),
		createProjectCol(projectNames.pm, {
			align: "left",
			render: (value: string, record) => (
				<FullNameAndUsername
					fullName={value}
					userName={record[projectNames.pmUsername]}
				/>
			),
		}),
		createProjectCol(projectNames.member, { hidden: true }),
		createPlanColWithRender(projectNames.planStart),
		createPlanColWithRender(projectNames.planEnd),
		createPlanColWithRender(projectNames.actualStart),
		createPlanColWithRender(projectNames.actualEnd),
		createPlanColWithTooltip(projectNames.abe),
		createPlanColWithTooltip(projectNames.pe),
		createPlanColWithTooltip(projectNames.ace),
		createPlanColWithTooltip(projectNames.ale),
		createPlanColWithRender(projectNames.billable, {
			render: formatNum,
			hidden: true,
		}),
		createProjectCol(projectNames.actionStatus, {
			sorter: true,
			render: (value, record) => {
				const tag = find(LIST_STATUS_PLAN, {
					id: record?.actionStatus,
				});
				return <Tag color={tag?.value}>{tag?.label}</Tag>;
			},
		}),
	];
};
