import {
	PERMISSIONS_MAP,
	PERMISSION,
	TPermissions,
} from "constants/permissionMap";
import { useMemo } from "react";
import { RouteObject, matchPath, useLocation } from "react-router-dom";
import routes from "routes";
import { objMap } from "utils";
import { useAppSelector } from "./reduxHooks";

function findMatchedRoute(
	pathname: string,
	routes: RouteObject[],
	prePattern?: string
): string | undefined {
	for (const route of routes) {
		if (!route.path) {
			continue;
		}
		const exactPath = `${prePattern ? prePattern + "/" : ""}${route.path}`;
		const matched = matchPath(exactPath, pathname);

		if (matched) {
			return exactPath;
		}

		if (route.children) {
			const matchedChild = findMatchedRoute(
				pathname,
				route.children,
				exactPath
			);
			if (matchedChild) {
				return matchedChild;
			}
		}
	}

	return;
}

export default function useGetPermission() {
	const { permissionCodes } = useAppSelector((state) => state.auth);
	const { pathname } = useLocation();
	return useMemo(() => {
		const exactPath = findMatchedRoute(pathname, routes);
		let permissions: PERMISSION[] = [PERMISSION.VIEW];
		let tabs: TPermissions[string]["tabs"] = {};
		if (exactPath) {
			const thisRoute = PERMISSIONS_MAP[exactPath];
			if (thisRoute.permissions) {
				permissions = objMap(thisRoute.permissions, (code, key) => {
					return (
						code && permissionCodes.includes(code) ? key : undefined
					) as any;
				});
			}
			if (thisRoute.tabs) {
				objMap(thisRoute.tabs, (value, key) => {
					tabs[key] = objMap(value, (code, perm) => {
						return (
							code && permissionCodes.includes(code) ? perm : undefined
						) as any;
					});
				});
			}
		}
		return { permissions, tabs };
	}, [pathname, permissionCodes]);
}
