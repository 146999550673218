export const CONFIG_UNIT = ["point", "%", "other"] as const;
export const LOGTIME_UNIT = "hours";
export const BUDGET_UNIT = "MD";
export const VND = "VND";

export const SPECIAL_KEYS = [
	"Alt",
	"Tab",
	"Control",
	"Shift",
	"Backspace",
	"Enter",
	"ArrowLeft",
	"ArrowUp",
	"ArrowRight",
	"ArrowDown",
	"Meta",
	"Delete",
] as const;
