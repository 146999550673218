import translation from "./translation.json";
import workLog from "./workLog.json";
import freeResource from "./freeResource.json";
import projectJiraSync from "./projectJiraSync.json";
import metrics from "./metrics.json";
import form from "./form";
import plan from "./plan";

const vi = {
	translation,
	workLog,
	freeResource,
	form,
	plan,
	projectJiraSync,
	metrics,
};

export default vi;
