import styled from "@emotion/styled";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { concatString } from "utils";
import { HEADER_DATA_KEYS, THeaderDataKey } from "../constants";

export const AvatarImg = styled.img`
	width: 2.25rem;
	height: 2.25rem;
	border-radius: 50%;
`;

const HeaderInfoValue = styled.span`
	color: ${({ theme }) => theme.color.primary};
`;

type THeaderInfoProps = {
	label: string;
	value?: ReactNode;
	testId?: string;
	keyPrefix?: string;
};

export const HeaderInfo = ({
	label,
	value,
	testId,
	keyPrefix,
}: THeaderInfoProps) => {
	const { t } = useTranslation("plan", {
		keyPrefix,
	});
	return (
		<p>
			{t(label)}:{" "}
			<HeaderInfoValue data-testid={concatString(testId, label)}>
				{value}
			</HeaderInfoValue>
		</p>
	);
};

export const HeaderContainer = styled.div<{ marginLeft?: string }>`
	display: flex;
	align-items: center;
	gap: 1.725rem;
	margin-left: ${({ marginLeft = "" }) => marginLeft};
	p::first-letter {
		display: inline-block;
		text-transform: capitalize;
	}
`;

export const HeaderInfoList = ({
	data,
	marginLeft,
	testId,
}: {
	data: THeaderInfoProps[];
	marginLeft?: string;
	testId?: string;
}) => {
	return (
		<HeaderContainer marginLeft={marginLeft}>
			{data.map((info) => (
				<HeaderInfo key={info.label} testId={testId} {...info} />
			))}
		</HeaderContainer>
	);
};

type DataObj = {
	[key in THeaderDataKey]?: number;
};

export const HeaderInfoObj = ({
	data,
	marginLeft,
	testId,
	keyPrefix,
}: {
	data: DataObj;
	marginLeft?: string;
	testId?: string;
	keyPrefix?: string;
}) => {
	return (
		<HeaderContainer marginLeft={marginLeft}>
			{HEADER_DATA_KEYS.map((value) => (
				<HeaderInfo
					key={value}
					label={value}
					value={data?.[value]}
					testId={testId}
					keyPrefix={keyPrefix}
				/>
			))}
		</HeaderContainer>
	);
};
