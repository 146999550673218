import { SelectField, SelectMode } from "components/FormFields";
import {
	DROPDOWN_CONTRACT_TYPE,
	DROPDOWN_MEMBER,
	DROPDOWN_POSITION,
	DROPDOWN_RANK,
	DROPDOWN_STATUS_PLAN,
} from "constants/api";
import { useTranslation } from "react-i18next";
import { DropdownRes } from "types";
import { ApiSelect, IApiSelectProps, TApiSelectData } from "./ApiSelect";
import { useFetch } from "hooks";
import { useCallback, useMemo } from "react";
import { LIST_STATUS_PLAN, TypeListStatus } from "pages/Plan/General/constants";
import { find } from "lodash";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Form, Tag } from "antd";

export type TSomethingSelectProps<
	M extends SelectMode = undefined,
	DataType extends DropdownRes = TApiSelectData,
	Res = DataType
> = Omit<IApiSelectProps<DataType, M, Res>, "url" | "data">;

export const PositionSelect = <M extends SelectMode>({
	username,
	...props
}: TSomethingSelectProps<M, TApiSelectData & { rankId?: string }> & {
	username?: string;
}) => {
	const { t } = useTranslation();
	return (
		<ApiSelect
			url={DROPDOWN_POSITION}
			label={t("position")}
			params={{
				username,
			}}
			{...props}
		/>
	);
};

export const RankSelect = <M extends SelectMode>(
	props: TSomethingSelectProps<M>
) => {
	const { t } = useTranslation();
	return <ApiSelect url={DROPDOWN_RANK} label={t("rank")} {...props} />;
};

export const ContractTypeSelect = (props: TSomethingSelectProps) => (
	<ApiSelect url={DROPDOWN_CONTRACT_TYPE} {...props} />
);

type TMemberSelectData = {
	fullName: string;
	username: string;
};

export const MemberSelect = <M extends SelectMode>({
	url = DROPDOWN_MEMBER,
	...props
}: TSomethingSelectProps<M, TMemberSelectData> & {
	url?: string;
}) => {
	return (
		<ApiSelect
			url={url}
			optionKeys={{ label: "fullName", value: "username" }}
			{...props}
		/>
	);
};
export const StatusSelect = <M extends SelectMode>({
	name,
	...props
}: TSomethingSelectProps<M, TMemberSelectData> & {
	url?: string;
}) => {
	const { t } = useTranslation();
	const { data, loading } = useFetch<{}, TypeListStatus[]>({
		url: DROPDOWN_STATUS_PLAN,
	});

	const options = useMemo(() => {
		return data?.map((item, index) => {
			const option = {
				...item,
				value: item.value,
			} as any;
			return option;
		});
	}, [data]);
	const tagRender = useCallback(({ value, ...props }: CustomTagProps) => {
		const tag = find(LIST_STATUS_PLAN, { id: value });
		return (
			<Tag color={tag?.value} style={{ marginRight: 3 }} {...props}>
				{tag?.label}
			</Tag>
		);
	}, []);

	return (
		<Form.Item name={name || ""} label={t("status")}>
			<SelectField
				loading={loading}
				options={options}
				mode="multiple"
				placeholder={t("status")}
				tagRender={tagRender}
			/>
		</Form.Item>
	);
};
