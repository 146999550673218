export const names = {
	url: "url",
	key: "key",
	name: "name",
	avatar: "avatar",
	description: "description",
	syncJira: "syncJira",
	children: "dataList",
	status: "actionStatus",
} as const;

export const combineDateNames = {
	planDate: "planDate",
	actualDate: "actualDate",
	budgetDate: "budgetDate",
	adjustBudgetDate: "adjustBudgetDate",
} as const;

export const durationNames = {
	id: "durationId",
	planStart: "planStart",
	planEnd: "planEnd",
	actualStart: "actualStart",
	actualEnd: "actualEnd",
	adjustBudgets: "adjustBudgets",
	adjustBudgetStart: "adjustBudgetStart",
	adjustBudgetEnd: "adjustBudgetEnd",
	adjustBudget: "adjustBudget",
	budget: "budget",
	budgetStart: "budgetStart",
	budgetEnd: "budgetEnd",
	description: "description",
	businessRank: "businessRank",
	pm: "pm",
	pmUsername: "pmUsername",
} as const;

export const dateKeys = [
	durationNames.actualStart,
	durationNames.actualEnd,
	durationNames.planStart,
	durationNames.planEnd,
	durationNames.adjustBudgetStart,
	durationNames.adjustBudgetEnd,
	durationNames.budgetStart,
	durationNames.budgetEnd,
];

export const DURATION_STATE = {
	NEW: "NEW",
	CURRENT: "CURRENT",
	ENDED: "ENDED",
} as const;

export type TDurationState =
	(typeof DURATION_STATE)[keyof typeof DURATION_STATE];

export const LIST_STATUS_PLAN = [
	{ id: 1, label: "Running", value: "orange" },
	{ id: 2, label: "New", value: "blue" },
	{ id: 3, label: "Holding", value: "red" },
	{ id: 4, label: "Finished", value: "green" },
];
export type TypeListStatus = {
	id: string;
	value: string;
	color: string;
};
