import styled from "@emotion/styled";
import { ButtonProps, Form, FormInstance, message, Modal } from "antd";
import { fetchTable } from "appStore/commonSlice";
import { closeModal } from "appStore/modalSlice";
import { SaveBtn } from "components/CommonBtns";
import { useAppSelector } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ExtendModalProps } from "types";
import { IStandardFormProps, StandardForm } from "..";

const { Item } = Form;

export type ModalFormProps<Values = any> = Omit<
	IStandardFormProps<Values>,
	"className" | "title" | "form"
> &
	ExtendModalProps<"className" | "children" | "title"> & {
		submitBtn?: React.ReactElement<ButtonProps>;
		handleSubmit: (values: Values) => Promise<any>;
		marginTopBtn?: number | string;
		initialValues?: Function;
		form: FormInstance<Values>;
		messages?: string;
	};

interface ISubmitItem {
	marginTopBtn: number | string;
}

const StyledModal = styled(StandardForm)`
	.ant-form-item-label > label {
		display: inline-block;
	}
`;

const SubmitItem = styled(Item, {
	shouldForwardProp: (propName) => propName !== "marginTopBtn",
})<ISubmitItem>`
	margin-top: ${({ marginTopBtn }) =>
		typeof marginTopBtn === "number"
			? `${marginTopBtn / 16}rem`
			: marginTopBtn};
	margin-bottom: 0 !important;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.625rem;
`;

function ModalForm<Values = any>({
	title,
	className,
	children,
	submitBtn = <SaveBtn />,
	form,
	handleSubmit,
	initialValues = () => {},
	modalProps,
	marginTopBtn = 60,
	formName,
	messages,
	...props
}: ModalFormProps<Values>) {
	const { t } = useTranslation();
	const { isOpened, record, mode } = useAppSelector((state) => state.modal);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const close = useCallback(() => {
		form.resetFields();
		dispatch(closeModal());
	}, [form, dispatch]);

	// When open modal
	useEffect(() => {
		if (isOpened && mode === "edit") {
			const values = { ...record, ...initialValues(record) };
			form.setFields(
				Object.keys(values).map((key) => ({
					name: key,
					touched: false,
					value: values[key],
				}))
			);
		}
		//missing dependencies initialValues
		//eslint-disable-next-line
	}, [isOpened, mode, form]);

	const submitForm = async (values: Values) => {
		if (typeof handleSubmit === "function") {
			setLoading(true);
			try {
				await handleSubmit(values);
				messages
					? message.info(messages)
					: message.success(
							t("msg.success", {
								action: t("function." + mode),
							})
					  );
				dispatch(fetchTable());
				close();
			} catch (error) {
				close();
				message.error((error as any)?.message);
			}
			setLoading(false);
		}
	};

	return (
		<Modal
			title={title}
			className={className}
			onCancel={close}
			visible={isOpened}
			// getContainer={(node: HTMLElement) => node.parentNode}
			footer={false}
			{...modalProps}
		>
			<StyledModal
				form={form}
				onFinish={submitForm}
				layout="vertical"
				formName={formName}
				{...props}
			>
				{children}
				<SubmitItem shouldUpdate marginTopBtn={marginTopBtn}>
					{({ getFieldsError, isFieldsTouched }) => {
						const disabled =
							!isFieldsTouched() ||
							getFieldsError().some(({ errors }) => errors.length);
						return React.cloneElement(submitBtn, {
							disabled,
							htmlType: "submit",
							loading,
							"data-testid": "btn-submit-modal",
						} as any);
					}}
				</SubmitItem>
			</StyledModal>
		</Modal>
	);
}

export { ModalForm };
