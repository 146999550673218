import { SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Form, FormInstance, FormProps } from "antd";
import { DEFAULT_PAGINATION_PARAMS } from "constants/apiParams";
import { useUrlParams } from "hooks";
import React, {
	ForwardedRef,
	ReactElement,
	ReactNode,
	RefAttributes,
	useEffect,
} from "react";
import { FilterFormValue, PrimitiveObj } from "types";
import { checkPrimitive } from "utils/commonUtils";

export interface IFilterFormProps<Values extends object = FilterFormValue>
	extends FormProps<Values> {
	resetPagination?: boolean;
	children?: ReactNode | ReactNode[];
	initValue?: () => void;
	haveFilterTime?: boolean;
	preHandleParams?: (values: Values) => PrimitiveObj;
}

function FilterForm<Values extends object>(
	{
		resetPagination = true,
		children,
		preHandleParams,
		initValue,
		...props
	}: IFilterFormProps<Values>,
	ref?: ForwardedRef<FormInstance<Values>>
) {
	const [form] = Form.useForm<Values>();
	const { urlParams, setUrlParams } = useUrlParams();

	useEffect(() => {
		if (typeof initValue === "function") {
			initValue();
		} else {
			form.setFieldsValue(urlParams as any);
		}
		//missing urlParams
		//eslint-disable-next-line
	}, [initValue, form]);

	return (
		<Form
			validateTrigger={["onChange"]}
			form={form}
			ref={ref}
			layout="vertical"
			{...props}
			onFinish={(values) => {
				let handledValues: PrimitiveObj;
				if (typeof preHandleParams === "function") {
					handledValues = preHandleParams(values);
				} else {
					handledValues = {};
					for (const key in values) {
						if (Object.prototype.hasOwnProperty.call(values, key)) {
							const element = values[key];
							if (checkPrimitive(element)) {
								handledValues[key] = element;
							} else {
								handledValues[key] = JSON.stringify(element);
							}
						}
					}
				}
				setUrlParams(
					Object.assign(
						{},
						handledValues,
						resetPagination ? DEFAULT_PAGINATION_PARAMS : {}
					)
				);
			}}
		>
			{children}
			<Form.Item shouldUpdate noStyle>
				{({ getFieldsError }) => {
					return (
						<Button
							data-testid="btn-submit-filter"
							type="primary"
							htmlType="submit"
							icon={<SearchOutlined />}
							disabled={getFieldsError().some(
								({ errors }) => errors.length > 0
							)}
						/>
					);
				}}
			</Form.Item>
		</Form>
	);
}

const FilterFormWithRef = React.forwardRef(FilterForm) as <
	Values extends object = FilterFormValue
>(
	props: IFilterFormProps<Values> & RefAttributes<FormInstance<Values>>
) => ReactElement | null;

const StyledFilterForm = styled(FilterFormWithRef)`
	background-color: white;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 0.75rem;
	> * {
		margin-bottom: 0;
		&:not(button) {
			min-width: 13.75rem;
		}
	}
	label {
		text-transform: capitalize;
	}
` as typeof FilterFormWithRef;

export default React.memo(StyledFilterForm) as typeof FilterFormWithRef;
