import styled from "@emotion/styled";
import { CSSProperties } from "react";

export type TMCell = {
	width?: string | number;
	order?: number;
	minWidth?: string | number;
	textAlign?: CSSProperties["textAlign"];
	textTransform?: CSSProperties["textTransform"];

	/**
	 * if true, background would be the same as table's head
	 */
	sticky?: boolean;
};

export const MRow = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;

export const MCell = styled("div", {
	shouldForwardProp: (propName) =>
		!["width", "order", "minWidth", "textAlign", "sticky"].includes(propName),
})<TMCell>`
	${({ width }) => (width ? `width: ${width}` : "flex: 2")};
	text-align: ${({ textAlign }) => textAlign ?? ""};
	flex-shrink: 0;
	border-width: 0;
	text-transform: ${({ textTransform }) => textTransform ?? ""};
	min-width: ${({ minWidth = "" }) => minWidth};
	order: ${({ order }) => order ?? ""};
	${({ theme }) =>
		`border-bottom: 1px solid ${theme.color.border};
	border-right: 1px solid ${theme.color.border};`}
	background-color: ${({ sticky }) => sticky ? "#e7f0fb" : ""};
	display: block;
	padding: 0.625rem 1rem;
	word-break: break-word;
`;

export const MHead = styled(MCell)`
	text-align: center;
	font-weight: 500;
	position: relative;
	background-color: #e7f0fb;

	&::first-letter {
		text-transform: uppercase;
	}
`;

export const MTable = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow-x: auto;
`;

export const MFooter = styled(MCell)`
	font-weight: 700;
	text-transform: uppercase;
	color: ${({ theme }) => theme.color.primary};
`;

export const MNestedRow = styled.ul`
	order: 100;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	margin: 0;
	padding: 0;
`;

export const RootMRow = styled(MRow)`
	${({ theme }) =>
		`border-top: 1px solid ${theme.color.border};
	border-left: 1px solid ${theme.color.border};`}
`
