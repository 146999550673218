import { Form, FormProps } from "antd";
import { TOptions } from "i18next";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatFormName } from "utils";

export interface IStandardFormProps<Values = any> extends FormProps<Values> {
	children?: React.ReactNode;
	/**
	 * @description `formName` is used for translation
	 */
	formName?: string;
}

const FormContext = React.createContext<{ formName?: string }>({
	formName: undefined,
});

export const useFormContext = () => React.useContext(FormContext);

function StandardForm({
	children,
	formName = "",
	...props
}: IStandardFormProps) {
	const { t } = useTranslation("form");
	const validateMsgT = useCallback(
		(key: string, name: string, options?: TOptions) => {
			return t(`validateMsg.${key}`, {
				name: t(formatFormName(name, formName)),
				...options,
			});
		},
		[t, formName]
	);
	return (
		<FormContext.Provider
			value={{
				formName,
			}}
		>
			<Form
				labelAlign="left"
				validateTrigger={["onChange"]}
				validateMessages={{
					required: ((name: string) => validateMsgT("required", name)) as any,
					string: {
						range: ((name: string, min: number, max: number) =>
							validateMsgT("range", name, {
								min,
								max,
							})) as any,
					},
					number: {
						range: ((name: string, min: number, max: number) =>
							validateMsgT("range", name, {
								min,
								max,
							})) as any,
					},
				}}
				requiredMark
				{...props}
			>
				{children}
			</Form>
		</FormContext.Provider>
	);
}

export { StandardForm };
export { default as FilterForm } from "./FilterForm";
export * from "./FilterForm";
export * from "./ModalForm";
