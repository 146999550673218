import { ChartData, ChartType } from "chart.js";
import { StatisticBoxProps } from "components";
import { FORMAT_TIME, TKeyFormat } from "constants/format";
import { LOGTIME_UNIT } from "constants/setting";
import { APPROVED_STATUS } from "constants/workLog";
import moment from "moment";
import { workLogT } from "pages/WorkLog/constants";
import { TMyWorkLogChartData, TTicketData, TTicketDetailData } from "types";
import {
	addLogtimeUnit,
	formatNum,
	objMap,
	translate,
	upperFirstLetter,
} from "utils";
import { STATUS_COLOR, WORKLOG_STATUS } from "../constants";

const infoT: typeof workLogT = (key) => {
	const options =
		key === "totalTickets"
			? undefined
			: {
					unit: LOGTIME_UNIT + "s",
			  };
	return workLogT(`statistic.description.${key}`, options);
};

export const getSummary = (
	data?: Partial<TTicketData>
): StatisticBoxProps[] => {
	const { totalLogtime } = data || {};
	// const { totalLogtime, totalTicket } = data || {};
	return [
		{
			title: workLogT("statistic.logtime", {
				unit: LOGTIME_UNIT,
			}),
			data: formatNum(totalLogtime),
			info: infoT("approved"),
		},
		// {
		// 	title: addLogtimeUnit(translate("plan")),
		// 	data: formatNum(data?.totalPlanTime),
		// 	info: workLogT("workLog:statistic.description.plan", {
		// 		unit: LOGTIME_UNIT,
		// 	}),
		// },
		// {
		// 	title: workLogT("statistic.ticket"),
		// 	data: formatNum(totalTicket),
		// 	info: infoT("totalTickets"),
		// },
	];
};
const colProps = (status:string )=>{
	switch(status){
		case APPROVED_STATUS.reject:
			return '#F6424C';
		case APPROVED_STATUS.pending:
			return '#FAB937';
		case APPROVED_STATUS.approved:
			return '#266ABE';
	}
}

export const getProjectSummary = (data?: Partial<TTicketDetailData>) => {
	const { totalPlanTime, logTimeByStatus } = data || {};
	// const { totalPlanTime, totalTicket, logTimeByStatus } = data || {};
	const projectSummary: StatisticBoxProps[] = [
		// {
		// 	title: workLogT("statistic.ticket"),
		// 	data: formatNum(totalTicket),
		// 	info: infoT("totalTickets"),
		// },
		{
			title: addLogtimeUnit(translate("plan")),
			data: formatNum(totalPlanTime),
			info: infoT("plan"),
			color: `#64B4FF`,
		},
	];
	return projectSummary.concat(
		logTimeByStatus?.map<StatisticBoxProps>(({ status, loggedTime }) => {
			const approvedStatus = status.toLocaleLowerCase();
			return {
				title: addLogtimeUnit(
					workLogT("approvedStatusLabel." + approvedStatus)
				),
				data: formatNum(loggedTime),
				info: infoT(approvedStatus),
				color: colProps(status),		
			};
		}) || []
	);
};

export const findAndAdd = (
	arr: number[],
	index: number,
	value?: number | null
) => {
	const newValue = value || 0;
	if (arr[index] === undefined) {
		arr.push(newValue);
	} else {
		arr[index] += newValue;
	}
};

export const initMyDetailProjectDatasets = () => [
	{
		label: upperFirstLetter(translate("plan")),
		data: [],
		backgroundColor: STATUS_COLOR.PLAN,
		stack: "Stack0",
	},
	...objMap(WORKLOG_STATUS, (value, status) => ({
		label: upperFirstLetter(
			`${workLogT(
				"approvedStatusLabel." + status.toLocaleLowerCase()
			)} ${workLogT("logtime")}`
		),
		data: [],
		backgroundColor: value.color,
		stack: "Stack1",
	})),
];

/**
 * @description function is used for map through each logtime data and push in dataset
 * @param datasets from `ChartData` is used for render in chart
 * @param logTimeData data from api
 * @param dateIndex the index of the current date
 */
export const dataToProjectDatasetData = (
	datasets: ChartData<ChartType, number[], string>["datasets"],
	logTimeData: TMyWorkLogChartData[string],
	dateIndex: number
) => {
	findAndAdd(datasets[0].data, dateIndex, logTimeData.planedTime);
	// loop through logtimeData and add logtime to its dataset based on worklogStatus
	logTimeData.data.forEach(({ loggedTime, worklogStatus }) => {
		findAndAdd(
			datasets[WORKLOG_STATUS[worklogStatus].index].data,
			dateIndex,
			loggedTime
		);
	});
	// loop through datasets (plan, approved, pending, reject) and push 0 to data if data[dateIndex] === undefined
	// This is for the length of each dataset to be the same
	datasets.forEach((dataset) => {
		findAndAdd(dataset.data, dateIndex);
	});
};

/**
 * @description function combine labels and datasets for chart from data
 * @param data from api is an object with key is time and data is an array contains logtime value of that time
 * @param formatLabels used for format labels
 */
export const combineProjectDetailDataset = (
	data?: TMyWorkLogChartData,
	formatLabels: TKeyFormat = "DATE"
) => {
	const chartData: ChartData<"bar", number[], string> = {
		labels: [],
		datasets: initMyDetailProjectDatasets(),
	};
	if (data) {
		for (const key in data) {
			const { datasets, labels } = chartData;
			const indexDate =
				labels!.push(moment(key).format(FORMAT_TIME[formatLabels])) - 1;
			dataToProjectDatasetData(datasets, data[key], indexDate);
		}
	}
	return chartData;
};
