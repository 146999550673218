import styled from "@emotion/styled";
import { Table } from "antd";
import axiosClient from "api";
import { Accordion, ContentLayout, H5, TooltipParagraph } from "components";
import { SYNC_PROJECT_DETAIL, SYNC_PROJECT_DETAIL_EXPORT } from "constants/api";
import { useCreateCols } from "hooks";
import CustomLayout from "layout";
import { useEffect, useState } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { exportWithConfirm, renderNull } from "utils";
import { statusSyncRender } from "..";
import { TPlanSyncDetail, TTicketSyncDetail } from "./types";

type SyncDetailData = {
	projectName: string;
	projectKey: string;
	startDate: string;
	projectLink: string;
	teamLead: string;
	endDate: string;
	projectDesc: string;

	ticketResponse: {
		failedRecord: number;
		totalRecord: number;
		ticketDetailResponses: TTicketSyncDetail[];
	};

	planResponse: {
		failedRecord: number;
		totalRecord: number;
		planDetailResponses: TPlanSyncDetail[];
	};
};

const AccordionTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 50px;
`;

const AccordionTitle = ({
	section,
	total,
}: {
	section: string;
	total: number | undefined;
}) => {
	const { t } = useTranslation("projectJiraSyncDetail");
	return (
		<AccordionTitleContainer>
			<span>{section}</span>
			<span>{`${t("totalRecord")}: ${total}`}</span>
		</AccordionTitleContainer>
	);
};

const InfoLine = ({
	label,
	data,
}: {
	label: string;
	data: undefined | string;
}) => {
	return (
		<div>
			<span style={{ fontWeight: "bold" }}>{`${label}: `}</span>
			{data}
		</div>
	);
};

const handleExport = (id?: string | number) => {
	if (id) {
		exportWithConfirm(async () => {
			const { data: exportFile } = await axiosClient.get<Blob>(
				process.env.REACT_APP_BASE_URL + SYNC_PROJECT_DETAIL_EXPORT + id,
				{
					responseType: "blob",
				}
			);
			return window.URL.createObjectURL(exportFile);
		}, `${id}.xlsx`);
	}
};

function ProjectJiraSyncDetail() {
	const { t } = useTranslation("projectJiraSyncDetail");
	const { id } = useParams();
	const handleFullScreen = useFullScreenHandle();
	const [resData, setResData] = useState<null | SyncDetailData>(null);

	useEffect(() => {
		const fetchData = async () => {
			const { data } = await axiosClient.get<SyncDetailData>(
				SYNC_PROJECT_DETAIL + id
			);
			setResData(data);
		};

		fetchData();
	}, [id]);

	const ticketColumn = useCreateCols<
		TTicketSyncDetail,
		"projectJiraSyncDetail:ticketTableLabel"
	>("projectJiraSyncDetail:ticketTableLabel", [
		{
			dataIndex: "key",
		},
		{
			dataIndex: "name",
			render: (value) => {
				return <TooltipParagraph value={value} />;
			},
		},
		{
			dataIndex: "component",
		},
		{
			dataIndex: "type",
		},
		{
			dataIndex: "workLogDesc",
		},
		{
			dataIndex: "assignee",
		},
		{
			dataIndex: "reporter",
			width: 100,
		},
		{
			dataIndex: "loggedDate",
			width: 120,
		},
		{
			dataIndex: "memberLogWork",
			width: 100,
		},
	]);
	const planColumn = useCreateCols<
		TPlanSyncDetail,
		"projectJiraSyncDetail:planTableLabel"
	>("projectJiraSyncDetail:planTableLabel", [
		{
			dataIndex: "memberId",
			width: 300,
		},
		{
			dataIndex: "startDate",
		},
		{
			dataIndex: "worked",
			width: 200,
		},
	]);

	return (
		<CustomLayout currentPageLabel={resData?.projectName}>
			<ContentLayout
				fullScreen={handleFullScreen}
				exportFunc={() => handleExport(id as any)}
				header={<H5>{t("heading", { projectName: resData?.projectName })}</H5>}
			>
				<Accordion
					items={[
						{
							key: 1,
							title: t("projectSection"),
							children: (
								<>
									<InfoLine
										label={t("projectName")}
										data={resData?.projectName}
									/>
									<InfoLine
										label={t("projectKey")}
										data={resData?.projectKey}
									/>
									<InfoLine
										label={t("projectDes")}
										data={renderNull(resData?.projectDesc)}
									/>
									<InfoLine
										label={t("projectLink")}
										data={resData?.projectLink}
									/>
									<InfoLine
										label={t("projectTeamLead")}
										data={resData?.teamLead}
									/>
								</>
							),
						},
						{
							key: 2,
							title: (
								<AccordionTitle
									section={t("ticket")}
									total={resData?.ticketResponse?.totalRecord}
								/>
							),
							children: (
								<Table
									dataSource={resData?.ticketResponse?.ticketDetailResponses}
									columns={ticketColumn}
									pagination={false}
									rowKey="key"
									scroll={{ y: 450 }}
								/>
							),
						},
						{
							key: 3,
							title: (
								<AccordionTitle
									section={t("plan")}
									total={resData?.planResponse?.totalRecord}
								/>
							),
							children: (
								<Table
									dataSource={resData?.planResponse?.planDetailResponses}
									columns={planColumn}
									pagination={false}
									rowKey="memberId"
									scroll={{ y: 450 }}
								/>
							),
						},
					]}
				/>
			</ContentLayout>
		</CustomLayout>
	);
}

export default ProjectJiraSyncDetail;
