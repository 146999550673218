import { FormItem, RangePicker, RangePickerProps } from "components/FormFields";
import { FORMAT_TIME } from "constants/format";
import React from "react";
import { useTranslation } from "react-i18next";
import { ExtendFormItemProps } from "types";
import { AddTestId } from "utils/typeUtils";

export type TRangeFieldProps = AddTestId<
	ExtendFormItemProps<Omit<RangePickerProps, "name">>
>;

const RangeField = React.memo(
	({
		name,
		noStyle,
		label,
		fieldProps,
		rules,
		required,
		testId,
		...props
	}: TRangeFieldProps) => {
		const { t } = useTranslation("translation", {
			keyPrefix: "function",
		});
		return (
			<FormItem
				noStyle={noStyle}
				name={name}
				rules={rules}
				label={label}
				required={required}
				{...fieldProps}
			>
				<RangePicker
					placeholder={[t("from"), t("to")]}
					allowEmpty={[false, true]}
					format={FORMAT_TIME.DATE}
					data-testid={testId}
					{...props}
				/>
			</FormItem>
		);
	}
);

export { RangeField };
