import { Button, FormInstance, Modal, ModalProps } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

export type TModalDurationRef = {
	open: () => void;
	close: () => void;
};
export type TModalDurationProps = ModalProps & {
	addDuration: () => void;
	form: FormInstance;
};
function ModelDuration(
	{ form, addDuration, ...props }: TModalDurationProps,
	ref: React.Ref<TModalDurationRef>
) {
	const [isOpen, setIsOpen] = useState(false);
	const { t: tPlan } = useTranslation("freeResource");
	const close = () => {
		setIsOpen(false);
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				open() {
					setIsOpen(true);
				},
				close() {
					setIsOpen(false);
				},
			};
		},
		[]
	);
	return (
		<Modal
			visible={isOpen}
			onCancel={close}
			title={tPlan("warningFillActualEndDateEn")}
			footer={
				<div>
					<Button onClick={close} type="primary">
						{tPlan("no")}
					</Button>
					<Button
						type="primary"
						style={{ backgroundColor: "#1890ffad" }}
						onClick={() => addDuration()}
					>
						{tPlan("yesAndCreateDuaration")}
					</Button>
					<Button onClick={form.submit}>{tPlan("yes")}</Button>
				</div>
			}
			maskClosable
		>
			{tPlan("warningFillActualEndDateVn")}
		</Modal>
	);
}

export default forwardRef(ModelDuration);
