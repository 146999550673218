import { CHART_PROJECT } from "constants/api";
import { REQUIRED_PROJECT } from "constants/apiParams";
import { FORMAT_TIME, TKeyFormat } from "constants/format";
import { LOGTIME_UNIT } from "constants/setting";
import { APPROVED_STATUS } from "constants/workLog";
import { useFetchWithUrlParams, useUrlParams } from "hooks";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TProjectChartData } from "types";
import { addLogtimeUnit, formatNum, upperFirstLetter } from "utils";
import {
	ChartLayout,
	IChartLayoutProps,
	TDefaultChartData,
} from "../components/ChartLayout";
import MembersInfoDropdown from "../components/MembersInfoDropdown";
import { STATUS_COLOR } from "../constants";
import { findAndAdd } from "../utils";

function ProjectChartReport() {
	const { t } = useTranslation();
	const { urlParams } = useUrlParams();
	const { data, loading } = useFetchWithUrlParams<{}, TProjectChartData>({
		url: CHART_PROJECT,
		requiredParams: REQUIRED_PROJECT,
	});

	const handledData = useMemo(() => {
		const ticketList: IChartLayoutProps["ticketList"] = [
			{
				title: t("workLog:statistic.logtime", {
					unit: LOGTIME_UNIT,
				}),
				data: formatNum(data?.totalLogtime),
				info: t("workLog:statistic.description.approved", {
					unit: LOGTIME_UNIT,
				}),
				color: STATUS_COLOR.APPROVED,
			},
			{
				title: addLogtimeUnit(t("translation:plan")),
				data: formatNum(data?.totalPlan),
				info: t("workLog:statistic.description.plan", {
					unit: LOGTIME_UNIT,
				}),
				color: STATUS_COLOR.PLAN,
			},
		];
		const chartData: TDefaultChartData = {
			labels: [],
			datasets: [
				{
					type: "bar",
					label: upperFirstLetter(t("plan")),
					data: [],
					backgroundColor: STATUS_COLOR.PLAN,
					stack: "Stack0",
				},
				{
					type: "bar",
					label: upperFirstLetter(t("logtime")),
					data: [],
					backgroundColor: STATUS_COLOR.APPROVED,
					stack: "Stack1",
				},
			],
		};
		const projectLogtime = data?.data;
		if (projectLogtime) {
			const { datasets, labels } = chartData;
			for (const key in projectLogtime) {
				if (Object.prototype.hasOwnProperty.call(projectLogtime, key)) {
					const indexDate =
						labels!.push(
							moment(key).format(FORMAT_TIME[urlParams.groupBy as TKeyFormat])
						) - 1;
					projectLogtime[key].forEach(
						({ loggedTime, worklogStatus, planedTime }) => {
							findAndAdd(datasets[0].data, indexDate, planedTime);
							if (worklogStatus === APPROVED_STATUS.approved) {
								findAndAdd(datasets[1].data, indexDate, loggedTime);
							} else {
								findAndAdd(datasets[1].data, indexDate);
							}
						}
					);
				}
			}
		}
		return { ticketList, chartData };
	}, [t, data, urlParams.groupBy]);

	return (
		<ChartLayout
			{...handledData}
			loading={loading}
			title={<MembersInfoDropdown />}
		/>
	);
}

export default ProjectChartReport;
