import { Select } from "antd";
import { CONFIG_UNIT } from "constants/setting";
import { useMemo } from "react";
import { objMap, translate } from "utils";
import en from "translations/locales/en";
import styled from "@emotion/styled";

type IUnitSelectProps = {};

const AntSelect = styled(Select)`
	width: 100%;
`;

export function UnitSelect(props: IUnitSelectProps) {
	const options = useMemo(
		() => CONFIG_UNIT.map((value) => ({ value, label: value })),
		[]
	);
	return <AntSelect options={options} {...props} />;
}

const metricType = objMap(en.metrics.metricType, (_, key) => ({
	value: key,
	label: translate(key),
}));
export function TypeSelect(props: any) {
	return <AntSelect options={metricType} {...props} />;
}
