import { Form } from "antd";
import axiosClient from "api";
import { InputField, ModalForm } from "components";
import { FREE_RESOURCE } from "constants/api";
import { useAppSelector } from "hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { freeResourceNames } from "translations/keys/plan";

function EditForm() {
	const { t } = useTranslation();
	const { record } = useAppSelector((state) => state.modal);
	const [form] = Form.useForm<any>();

	const handleSubmit = useCallback(
		async (values: any) => {
			await axiosClient.put(
				FREE_RESOURCE + record[freeResourceNames.username],
				values
			);
		},
		[record]
	);

	const initValue = useCallback((record: any) => {
		const note = record?.[freeResourceNames.note] as string | undefined;
		const result: Record<string, string> = {};
		if (note) {
			result[freeResourceNames.note] = note.replace(/^\w+: /, "");
		}
		return result;
	}, []);

	return (
		<ModalForm
			handleSubmit={handleSubmit}
			initialValues={initValue}
			form={form}
			title={`${t("function.edit")} ${t("comment")}`}
		>
			<InputField
				inputType="textarea"
				name={freeResourceNames.note}
				label={"Comment"}
			/>
		</ModalForm>
	);
}

export default EditForm;
