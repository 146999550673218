import { Col, Form, Input, message, Row, Select, Tag } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import axiosClient from "api";
import { CancelBtn, EditBtn, FormItem, SaveBtn } from "components";
import { DROPDOWN_STATUS_PLAN, PLAN_GENERAL } from "constants/api";
import { useFetch } from "hooks";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TPlanGeneral } from "types/plan/project";
import { concatString, msgT } from "utils";
import { PERMISSION, PermissionProps } from "constants/permissionMap";
import DurationList, { DurationKey, keyToField, OpenedDurations, } from "./components/DurationList";
import ModelDuration, { TModalDurationRef } from "./components/ModelDuration";
import TimeRangeField from "./components/TimeRangeField";
import { combineDateNames, durationNames, LIST_STATUS_PLAN, names, TypeListStatus, } from "./constants";
import { FooterBtns, FormWrapper, InfoWrapper, ProjectAvatar } from "./styles";
import { dateStringToFormRange, preHandleGeneralValues, SubmitValues, } from "./utils";

import { find } from "lodash";

function PlanGeneral({ permissions }: PermissionProps<{}>) {
	const { id } = useParams();
	const { t } = useTranslation();
	const [isView, setIsView] = useState(true);
	const [loading, setLoading] = useState(false);
	const [openedDurations, setOpenedDurations] = useState<OpenedDurations>([]);
	const [form] = Form.useForm<TPlanGeneral>();
	const addDurationRef = useRef<Function>();
	const modelRef = useRef<TModalDurationRef>();
	/**
	 * for tracking the key and name of durations
	 */
	const durationKeysRef = useRef<DurationKey[]>([]);

	const { data, refetch } = useFetch<{}, TPlanGeneral>({
		url: PLAN_GENERAL,
		requiredParams: { projectKey: id },
	});
	const { data: statusPlan } = useFetch<{}, DefaultOptionType[]>({
		url: DROPDOWN_STATUS_PLAN,
		requiredParams: { projectKey: id },
	});

	const cancelEdit = useCallback(() => {
		setIsView(true);
		refetch();
	}, [refetch]);

	const sendDataToBackend = useCallback(
		async (newValues: SubmitValues) => {
			setLoading(true);
			try {
				await axiosClient.post(PLAN_GENERAL + "/" + id, newValues);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				return Promise.reject(error);
			}
		},
		[id]
	);
	const addDuration = useCallback(() => {
		if (addDurationRef.current) {
			addDurationRef.current();
			modelRef?.current?.close();
		}
	}, []);
	const onFinish = useCallback(
		async (values: TPlanGeneral) => {
			const newValues = preHandleGeneralValues(values);
			const successMessage = t("msg.success", {
				action: t("function.edit"),
			});

			try {
				await sendDataToBackend(newValues);
				setIsView(true);
				message.success(successMessage ?? msgT("success"));
				modelRef?.current?.close?.();
				refetch();
			} catch (error) {
				message.error((error as any).message);
			}
		},
		[sendDataToBackend, t, refetch]
	);

	const handleFinishFailed = useCallback(
		({ errorFields }: ValidateErrorEntity<TPlanGeneral>) => {
			const errorKeys: string[] = [];
			errorFields.forEach(({ name: namePath }) => {
				if (typeof namePath?.[1] !== "undefined") {
					for (let i = 0; i < durationKeysRef.current.length; i++) {
						const { key, name } = keyToField(durationKeysRef.current[i]);
						if (namePath[1] === name && !errorKeys.includes(key + "")) {
							errorKeys.push(key + "");
							break;
						}
					}
				}
			});
			setOpenedDurations(errorKeys);
		},
		[]
	);

	useEffect(() => {
		if (data) {
			// Pre handle data
			const preHandledData = {
				...data,
				[combineDateNames.planDate]: dateStringToFormRange(
					data,
					durationNames.planStart,
					durationNames.planEnd
				),
				[combineDateNames.actualDate]: dateStringToFormRange(
					data,
					durationNames.actualStart,
					durationNames.actualEnd
				),
			};
			preHandledData[names.children] = data[names.children].map((obj) => {
				return {
					...obj,
					[combineDateNames.planDate]: dateStringToFormRange(
						obj,
						durationNames.planStart,
						durationNames.planEnd
					),
					[combineDateNames.actualDate]: dateStringToFormRange(
						obj,
						durationNames.actualStart,
						durationNames.actualEnd
					),
					[combineDateNames.budgetDate]: dateStringToFormRange(
						obj,
						durationNames.budgetStart,
						durationNames.budgetEnd
					),
					[durationNames.adjustBudgets]: obj[durationNames.adjustBudgets]?.map(
						(adjustBudget) => {
							return {
								...adjustBudget,
								[combineDateNames.adjustBudgetDate]: dateStringToFormRange(
									adjustBudget,
									durationNames.adjustBudgetStart,
									durationNames.adjustBudgetEnd
								),
							};
						}
					),
				};
			}) as any;

			const lastDuration = data[names.children].length - 1;
			if (lastDuration >= 0) {
				setOpenedDurations([lastDuration + ""]);
			}
			form.setFieldsValue({
				...preHandledData,
			});
		}
	}, [data, form, refetch]);
	const { data: listStatus } = useFetch<{}, TypeListStatus[]>({
		url: DROPDOWN_STATUS_PLAN,
	});

	const renderTagBlock = (value: any) => {
		const tag = find(LIST_STATUS_PLAN, { id: value });
		return <Tag color={tag?.value}>{tag?.label}</Tag>;
	};
	return (
		<>
			<FormWrapper
				style={{ height: "100vh" }}
				form={form}
				formName="planGeneral"
				onFinish={onFinish}
				onFinishFailed={handleFinishFailed}
				labelCol={{ span: 5 }}
				wrapperCol={{ offset: 1 }}
				colon={false}
				disabled={loading}
			>
				{isView && (
					<EditBtn
						onClick={() => setIsView(false)}
						disabled={!permissions.includes(PERMISSION.EDIT)}
					/>
				)}
				<Row>
					<Col span={7} style={{ display: "flex", justifyContent: "center" }}>
						{data?.[names.avatar] && (
							<ProjectAvatar
								src={(data[names.avatar] as string).replaceAll('"', "")}
								alt=""
							/>
						)}
					</Col>
					<Col span={17}>
						<InfoWrapper isView={isView}>
							<FormItem name={names.name} forView={isView}>
								<Input disabled/>
							</FormItem>
							<FormItem name={names.key} forView={isView}>
								<Input disabled/>
							</FormItem>
							<FormItem name={names.url} forView={isView}>
								<Input disabled/>
							</FormItem>
							{/* <FormItem name={names.syncJira} valuePropName="checked">
							<Switch
								data-testid={concatString(names.syncJira, "switch")}
								disabled={isView}
							/>
						</FormItem> */}
							<Form.Item
								shouldUpdate={(prev, curr) =>
									prev?.[names.syncJira] !== curr?.[names.syncJira]
								}
								noStyle
							>
								{({ getFieldValue }) => {
									const syncFromJira =
										getFieldValue(names.syncJira) ?? data?.[names.syncJira];
									return (
										<>
											<TimeRangeField
												name={combineDateNames.planDate}
												forView={isView}
												disabled={syncFromJira}
											/>
											<TimeRangeField
												name={combineDateNames.actualDate}
												forView={isView}
												allowEmpty={[false, true]}
												disabled={syncFromJira}
											/>
											<FormItem name={names.description} forView={isView}>
												<Input.TextArea
													disabled={syncFromJira}
													data-testid={concatString(names.description, "input")}
												/>
											</FormItem>
										</>
									);
								}}
							</Form.Item>
						</InfoWrapper>
						<FormItem
							name={names.status}
							forView={isView}
							renderViewMode={renderTagBlock}
						>
							<Select options={listStatus}/>
						</FormItem>
						<DurationList
							name={names.children}
							isView={isView}
							openedDurations={openedDurations}
							setOpenedDurations={setOpenedDurations}
							durationKeysRef={durationKeysRef}
							addDurationRef={addDurationRef}
							loading={loading}
						/>
						{!isView && (
							<FooterBtns>
								<CancelBtn
									type="default"
									onClick={cancelEdit}
									disabled={loading}
								/>
								<SaveBtn
									htmlType="button"
									loading={loading}
									onClick={() => {
										const durations = form.getFieldValue("dataList");
										if (durations?.[durations.length - 1]?.actualDate?.[1]) {
											modelRef?.current?.open();
										} else {
											form.submit();
										}
									}}
								/>
							</FooterBtns>
						)}
					</Col>
				</Row>
			</FormWrapper>
			<ModelDuration
				ref={modelRef as any}
				addDuration={addDuration}
				form={form}
			/>
		</>
	);
}

export default PlanGeneral;
