import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, ModalProps, Table, TableColumnsType } from "antd";
import axiosClient from "api";
import { EButton } from "components";
import { ADD_STOP_SYNC, RESYNC } from "constants/api";
import React, { forwardRef, Key, useEffect, useImperativeHandle, useState, } from "react";
import { useTranslation } from "react-i18next";
import { TSyncAddData } from "../types";
import { useDispatch } from "react-redux";
import { fetchTable } from "appStore/commonSlice";

export type TModalSyncRef = {
	open: () => void;
	close: () => void;
};

const columns: TableColumnsType<TSyncAddData> = [
	{
		title: "key",
		align: "center",
		dataIndex: "key",
	},
	{
		title: "Last synced time",
		dataIndex: "lastSyncedTime",
		align: "center",
	},
];

function ModalSync(props: ModalProps, ref: React.Ref<TModalSyncRef>) {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const { t } = useTranslation("projectJiraSync");
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [resData, setResData] = useState<undefined | TSyncAddData[]>();
	const [loading, setLoading] = useState(false);
	const close = () => {
		setIsOpen(false);
		setSelectedRowKeys([]);
	};
	useEffect(() => {
		const fetchData = async () => {
			const { data } = await axiosClient.get<TSyncAddData[]>(ADD_STOP_SYNC);
			setResData(data);
		};
		fetchData();
	}, [isOpen]);
	useImperativeHandle(
		ref,
		() => {
			return {
				open() {
					setIsOpen(true);
				},
				close() {
					setIsOpen(false);
				},
			};
		},
		[]
	);
	const submitStopSync = async () => {
		const sync = selectedRowKeys?.join(",");
		const config = {
			projectKeys: sync.toString(),
		};
		try {
			setLoading(true);
			await axiosClient.put(RESYNC, null, { params: config, timeout: 400000 });
			message.success(t("message.addSuccess", { value: sync }));
			dispatch(fetchTable());
			setLoading(false);
			close();
		} catch (error) {
			message.error((error as any).message);
		}
	};
	return (
		<Modal
			visible={isOpen}
			onCancel={close}
			title={t("lalebModal")}
			footer={
				<div>
					<EButton
						type="primary"
						icon={<PlusOutlined/>}
						onClick={submitStopSync}
						loading={loading}
						disabled={!selectedRowKeys.length}
					>
						{t("allowSync.addSync")}
					</EButton>
				</div>
			}
			{...props}
		>
			<Table
				dataSource={resData}
				size="small"
				columns={columns}
				rowKey="key"
				rowSelection={{
					selectedRowKeys,
					onChange: setSelectedRowKeys,
					preserveSelectedRowKeys: true,
				}}
			/>
		</Modal>
	);
}

export default forwardRef(ModalSync);
