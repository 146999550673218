import { GroupData, MetricDetail, MetricResponse } from "./types";
import { isEqual } from "lodash";

export const METRIC_CATEGORIES = ["QUALITY", "EFFICIENCY", "BUSINESS"] as const;

export const getDefaultMetricValues = () => [{
	score: 0
}] as MetricDetail[]

const checkArrayEqual = <T>(arr1: T[], arr2: T[]) => {
	let isArrayEqual = arr1.length === arr2.length;
	if (!isArrayEqual) {
		return isArrayEqual;
	}
	for (let i = 0; i < arr1.length; i++) {
		if (!isEqual(arr1[i], arr2[i])) {
			isArrayEqual = false;
			break;
		}
	}
	return isArrayEqual;
}

export const checkChange = (oldValue: GroupData[], newValue: GroupData[]) => {
	const changes: string[] = [];

	newValue.forEach((newGroup, i) => {
		const oldGroup = oldValue[i];
		const changeGroup = (key: string) => `group:${oldGroup.id}:${key}`
		if (oldGroup.weight !== newGroup.weight) {
			changes.push(changeGroup("weight"));
		}
		const changedMetricMsg = changeGroup("metric")

		const deletedMetrics = oldGroup.metric.filter(({ id }) => !newGroup.metric.find(newMetric => newMetric.id === id));
		if (deletedMetrics.length) {
			changes.push(changedMetricMsg);
			changes.push.apply(changes, deletedMetrics.map(({ id }) => `metric:${id}:metricValues`));
		}

		for (const metric of newGroup.metric) {
			const oldMetric = oldGroup.metric.find(oldMetric => oldMetric.id === metric.id);
			if (!oldMetric) {
				if (!changes.includes(changedMetricMsg)) {
					changes.push(changeGroup("metric"));
				}
				continue;
			}

			for (const metricKey in metric) {
				const key = metricKey as keyof MetricResponse;
				if (!metric.hasOwnProperty(key)) {
					continue;
				}

				const isArrayEqual = Array.isArray(metric[key]) &&
					checkArrayEqual(metric[key] as any[], oldMetric[key] as any[])

				if (isArrayEqual || metric[key] === oldMetric[key]) {
					continue;
				}

				changes.push(`metric:${oldMetric.id}:${key}`);
			}
		}
	})
	return changes;
};
