import styled from "@emotion/styled";
import { Form } from "antd";
import { StandardForm } from "components";

export const FormWrapper = styled(StandardForm)`
	background: white;
	padding: 15px;
	flex: 1;
	overflow-y: scroll;
	.ant-form-item:not(.ant-form-item .ant-form-item) {
		margin-bottom: 14px;
		label {
			color: #000000ad;
			font-weight: 700;
		}
	}
	.ant-col-offset-1 {
		margin-left: 2.5%;
	}
`;

export const InfoWrapper = styled.div<{ isView?: boolean }>`
	.ant-form-item {
		margin-bottom: ${({ isView }) => (isView ? `0 !important` : "")};
	}
`;

export const DurationHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 5px;
	padding-bottom: 10px;
	cursor: pointer;
	.anticon.anticon-ellipsis {
		margin-right: 8px;
	}
`;

export const CombineFormItem = styled(Form.Item)`
	.ant-form-item-control-input-content {
		display: flex;
		gap: 1rem;
	}
`;

export const ProjectAvatar = styled.img`
	--size: 14rem;
	height: var(--size);
	width: var(--size);
	aspect-ratio: 1/1;
	border-radius: 50%;
`;

export const FooterBtns = styled.div`
	display: flex;
	gap: 0.625rem;
	float: right;
`;

export const BreakWordBox = styled.div`
	max-width: 100%;
	word-break: break-all;
`;
