import styled from "@emotion/styled";
import { PopoverProps } from "antd";
import { DotPopover } from "components/Antd";
import { DeleteBtn, EButton, EditBtn } from "components/CommonBtns";
import { isFunction } from "lodash";

const ActionMenu = styled.div`
	display: flex;
	flex-direction: column;
`;

export type HandleFunctions<RecordType> = {
	record: RecordType;
	onStop?: (record: RecordType) => void;
	onDetail?: (record: RecordType) => void;
	onEdit?: (record: RecordType) => void;
	onDelete?: (record: RecordType) => void;
};

export interface IActionColProps<RecordType>
	extends Omit<PopoverProps, "content">,
		HandleFunctions<RecordType> {
	testId?: string;
}

function ActionCol<RecordType>({ onEdit, onDelete, onDetail, onStop, record, ...props }: IActionColProps<RecordType>) {
	return (
		<DotPopover
			content={
				<ActionMenu>
					{isFunction(onStop) && (
						<EButton
							onClick={async () => onStop(record)}
							transform="capitalize"
							type="text"
							title="stopSync"
						/>
					)}
					{isFunction(onDetail) && (
						<EButton
							onClick={() => onDetail(record)}
							title="seeDetails"
							type="text"
							transform="capitalize"
						/>
					)}
					{isFunction(onEdit) && (
						<EditBtn
							onClick={() => onEdit(record)}
							transform="capitalize"
							type="text"
						/>
					)}
					{isFunction(onDelete) && (
						<DeleteBtn
							onClick={async () => onDelete(record)}
							transform="capitalize"
							type="text"
							danger={false}
						/>
					)}
				</ActionMenu>
			}
			{...props}
		/>
	);
}

export { ActionCol };
