import { FormInstance } from "antd";
import FilterForm, { IFilterFormProps } from "components/Form/FilterForm";
import { FORMAT_DATE } from "constants/format";
import moment from "moment";
import { useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FilterFormValue, FormValueToQuery } from "types";
import { urlParamsToObj } from "utils";
import { DateRangeField, TDateRangeFieldProps } from ".";

export interface CommonFilterFormProps<Values extends FilterFormValue>
	extends Omit<IFilterFormProps<Values>, "ref"> {
	paramsHandler?: IFilterFormProps<FormValueToQuery<Values>>["preHandleParams"];
	datePickerProps?: TDateRangeFieldProps;
}

export const changeTimeToQuery = <Values extends FilterFormValue>(
	{ time, ...values }: Values,
	handleValues?: IFilterFormProps<FormValueToQuery<Values>>["preHandleParams"]
) => {
	const newValues: FormValueToQuery<Values> = Object.assign({}, values) as any;
	newValues.fromDate = time?.[0] && time[0].format(FORMAT_DATE);
	newValues.toDate = time?.[1] && time[1].format(FORMAT_DATE);

	if (typeof handleValues === "function") {
		return handleValues(newValues);
	}
	return newValues;
};

export const searchParamsToFormValue = <Values extends FilterFormValue>(
	search: string
) => {
	const { fromDate, toDate, ...paramsFromQuery } = urlParamsToObj(
		search
	) as FormValueToQuery<Values>;
	const formValues: Values = {
		time: [],
		...paramsFromQuery,
	} as any as Values;
	formValues.time?.push(
		fromDate ? moment(fromDate, FORMAT_DATE) : undefined,
		toDate ? moment(toDate, FORMAT_DATE) : undefined
	);
	return formValues;
};

function CommonFilterForm<Values extends FilterFormValue>({
	children,
	paramsHandler,
	initValue,
	haveFilterTime = true,
	datePickerProps = {},
	...props
}: Omit<CommonFilterFormProps<Values>, "preHandleParams">) {
	const { search } = useLocation();
	const formRef = useRef<FormInstance<Values>>(null);

	const initValueFunc = useCallback(() => {
		if (formRef.current) {
			formRef.current.setFieldsValue(searchParamsToFormValue(search) as any);
		}
	}, [search]);

	return (
		<FilterForm
			initValue={initValueFunc}
			ref={formRef}
			preHandleParams={(values) => {
				return changeTimeToQuery(values, paramsHandler);
			}}
			{...props}
		>
			{haveFilterTime && <DateRangeField testId="filter-time" {...datePickerProps} />}
			{children}
		</FilterForm>
	);
}

export default CommonFilterForm;
