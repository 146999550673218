import { Form, Input } from "antd";
import axiosClient from "api";
import { FormItem, ModalForm } from "components";
import { EDIT_TEAM_EVALUATION } from "constants/api";
import { useAppSelector } from "hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { concatString } from "utils";
import { teamNames } from "../types";
import CommonFields from "./CommonFields";

export default function TeamMetricsForm() {
	const { t: teamT } = useTranslation("metrics", {
		keyPrefix: "team",
	});
	const { record } = useAppSelector((state) => state.modal);
	const [form] = Form.useForm<any>();

	const handleSubmit = useCallback(
		async (values: any) => {
			return await axiosClient.put(EDIT_TEAM_EVALUATION, {
				id: record.id,
				...values,
			});
		},
		[record]
	);

	return (
		<ModalForm
			formName="memberMetrics"
			handleSubmit={handleSubmit}
			form={form}
			title={teamT("edit")}
		>
			<FormItem label={teamT("name")} name={teamNames.name} required>
				<Input data-testid={concatString(teamNames.name, "input")} disabled />
			</FormItem>
			<FormItem label={teamT("weight")} name={teamNames.weight} required>
				<Input data-testid={concatString(teamNames.weight, "input")} disabled />
			</FormItem>
			<CommonFields />
		</ModalForm>
	);
}
