import { Form, SelectProps } from "antd";
import { SelectField } from "components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KPI_STATUS } from "../constants";

function StatusSelect() {
	const { t } = useTranslation();
	const options = useMemo<SelectProps["options"]>(
		() =>
			KPI_STATUS.map((key) => ({
				label: t(`statusTags.${key}`),
				value: key.toUpperCase(),
			})),
		[t]
	);

	return (
		<Form.Item name="status" label={t("status")}>
			<SelectField options={options} mode="multiple" />
		</Form.Item>
	);
}

export default StatusSelect;
