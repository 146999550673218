import styled from "@emotion/styled";
import { Button, ButtonProps, Tooltip } from "antd";
import { useState } from "react";
import { getPopupContainer } from "utils";

interface ITooltipBtnProps extends ButtonProps {
	title?: string;
}

const IconBtn = styled((props: ButtonProps) => (
	<Button type="text" size="large" {...props} />
))`
	&& {
		padding: 0;
		height: auto;
		width: auto;
		.anticon {
			font-size: 1.5rem;
		}
	}
`;

function TooltipBtn({ title, ...props }: ITooltipBtnProps) {
	return (
		<Tooltip getPopupContainer={getPopupContainer} title={title}>
			<IconBtn {...props} />
		</Tooltip>
	);
}

const AsyncTooltipBtn = ({
	onClick,
	loading: loadingProp,
	...props
}: ITooltipBtnProps) => {
	const [loading, setLoading] = useState(false);
	return (
		<TooltipBtn
			loading={loadingProp ?? loading}
			onClick={async (e) => {
				setLoading(true);
				await onClick?.(e);
				setLoading(false);
			}}
			{...props}
		/>
	);
};

export { TooltipBtn, AsyncTooltipBtn };
