import queryString, { ParseOptions, StringifyOptions } from "query-string";

export const searchParamsToObj = (
	params: URLSearchParams,
	parseOptions?: ParseOptions
) => {
	return queryString.parse(params.toString(), parseOptions);
};

export const urlParamsToObj = (
	params: string,
	parseOptions: ParseOptions = {
		parseNumbers: true,
		parseBooleans: true,
		arrayFormat: "comma",
	}
) => {
	return queryString.parse(params, parseOptions);
};

export const objToUrlParams = <ParamsType extends object>(
	params?: ParamsType,
	stringifyOptions: StringifyOptions | undefined = {
		skipNull: true,
		arrayFormat: "comma",
	}
) => {
	return queryString.stringify(params || {}, stringifyOptions);
};
