import styled from "@emotion/styled";
import { InfoTooltip, Paper } from "components";
import { HeaderBtnGroup } from "components/CommonBtns";
import { Primitive } from "types";

export type StatisticBoxProps = {
	title: string;
	info?: string;
	data?: Primitive;
	color?: string;
};

const StatisticBoxPaper = styled(Paper)<{ color?: string }>`
	background-color: ${({ color }) => (color ? color : "") + "1a"};
	display: flex;
	min-height: 4.875rem;
	min-width: 14.125rem;
	margin: 10px;
`;

const TitleBox = styled.div`
	display: flex;
	max-width: 8rem;
	align-items: center;
	position: relative;
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	gap: 0.625rem;

	p {
		text-transform: capitalize;
		text-align: center;
		margin: 0;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 100%;
		border-left: 0.5px solid rgba(0, 0, 0, 0.3);
	}
`;

const DataBox = styled.div<{ color?: string }>`
	flex: 1;
	align-self: center;
	font-size: 1.5rem;
	font-weight: 700;
	text-align: center;
	padding: 0.9875rem;
	color: ${({ color }) => (color ? color : "")};
`;

function StatisticBox({ title, data, color, info }: StatisticBoxProps) {
	return (
		<StatisticBoxPaper color={color}>
			<TitleBox>
				<p>{title}</p>
				<InfoTooltip title={info}/>
			</TitleBox>
			<DataBox color={color}>{data}</DataBox>
		</StatisticBoxPaper>
	);
}

function StatisticList({ dataSource }: { dataSource: StatisticBoxProps[] }) {
	return (
		<HeaderBtnGroup>
			{dataSource.map((props, index) => (
				<StatisticBox key={index} {...props} />
			))}
		</HeaderBtnGroup>
	);
}

export { StatisticBox, StatisticList };
