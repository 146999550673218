import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Modal, ModalFuncProps, Typography, message } from "antd";
import { theme } from "layout/theme";
import { isFunction } from "lodash";
import { translate } from "utils/translateUtils";
const { Text } = Typography;

const WarningIcon = styled(WarningOutlined)`
	color: ${theme.color.danger};
`;

export const msgT: typeof translate = (key, options) =>
	translate(`msg.${key}`, options);
const funcT: typeof translate = (key, options) =>
	translate(`function.${key}`, options);

type TShowConfirmParams = ModalFuncProps & {
	successMsg?: string;
	action?: string;
};

export const showConfirm = ({
	onOk,
	successMsg,
	action = "",
	...confirmProps
}: TShowConfirmParams) => {
	Modal.confirm({
		icon: <InfoCircleOutlined />,
		// okText: funcT("yes"),
		cancelText: funcT("no"),
		maskClosable: true,
		onOk: async () => {
			if (isFunction(onOk)) {
				try {
					await onOk();
					message.success(
						successMsg ??
							msgT("success", {
								action,
							})
					);
				} catch (error) {
					message.error((error as any).message);
				}
			}
		},
		...confirmProps,
	});
};

export const confirmDelete = (
	handleDelete: () => Promise<void>,
	confirmProps?: ModalFuncProps & {
		item?: string;
	}
) => {
	showConfirm({
		title: (
			<h4>
				{msgT("confirm")} <Text type="danger">{funcT("delete")}</Text>{" "}
				{msgT("thisItem", {
					item: confirmProps?.item ?? "",
				})}
				?
			</h4>
		),
		icon: <WarningIcon />,
		onOk: handleDelete,
		action: funcT("delete"),
		...confirmProps,
	});
};
