import styled from "@emotion/styled";
import { Form, message } from "antd";
import axiosClient from "api";
import {
	ApprovedBtn,
	CommonFilterForm,
	HeaderBtnGroup,
	Paper,
	ProjectQuerySelect,
	RejectBtn,
	SearchInput,
	TableWithQuery,
} from "components";
import { DROPDOWN_PROJECT_MANARGER, PROJECT_LOGWORK, PROJECT_LOGWORK_EXPORT, } from "constants/api";
import { DEFAULT_WORKLOG_PARAMS, REQUIRED_PROJECT } from "constants/apiParams";
import { APPROVED_STATUS } from "constants/workLog";
import { isEmpty } from "lodash";
import { Key, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataProjectWorkLog, ProjectUpdateWorkLog, WorkLogParams } from "types";
import { getPMDisabledTime } from "utils";
import { ApprovedStatusFilter } from "../components";
import ComponentSelect from "./ComponentSelect";
import useCreateCols from "./useCreateCols";
import { useGetPermission, useUrlParams } from "hooks";
import { PERMISSION } from "constants/permissionMap";
import moment from "moment";
import { FORMAT_DATE } from "constants/format";
import CustomLayout from "layout";

const PaddingHeader = styled(Paper)`
	padding: 0.625rem 1rem;
`;

function ProjectWorkLog() {
	const { t } = useTranslation("workLog");
	const { urlParams } = useUrlParams();
	const { permissions } = useGetPermission();
	const [form] = Form.useForm();
	const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
	const [sending, setSending] = useState(false);
	const [refetchDep, updateData] = useState(false);
	const columns = useCreateCols({
		selectedKeys,
	});
	const editPermission = permissions?.includes(PERMISSION.EDIT);

	const disabledEditTime = useMemo(getPMDisabledTime, []);

	const submitForm = useCallback(
		async (
			values: Record<string, string>,
			approvedStatus: ProjectUpdateWorkLog["approvedStatus"]
		) => {
			setSending(true);
			try {
				const newValues: ProjectUpdateWorkLog[] = [];
				for (const key in values) {
					if (Object.prototype.hasOwnProperty.call(values, key)) {
						const newUpdateLog = {
							id: key,
							approvedStatus,
							comment: values[key],
						};
						newValues.push(newUpdateLog as ProjectUpdateWorkLog);
					}
				}
				await axiosClient.post(PROJECT_LOGWORK, newValues);
				updateData((prev) => !prev);
				setSelectedKeys([]);
			} catch (error) {
				console.log("error :", error);
			}
			setSending(false);
		},
		[]
	);

	const handleSubmitFailed = useCallback(() => {
		message.error(t("validateMsg.requiredRejectComment"));
	}, [t]);

	return (
		<CustomLayout currentPageLabel={t("project")}>
			<PaddingHeader className="filter">
				<ProjectQuerySelect
					replaceOldQueryParams={DEFAULT_WORKLOG_PARAMS}
					url={DROPDOWN_PROJECT_MANARGER}
				/>
				<CommonFilterForm
					style={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ComponentSelect/>
					{/* <LogValidFilter /> */}
					<ApprovedStatusFilter/>
					<SearchInput placeholder="projectWorklog"/>
				</CommonFilterForm>
			</PaddingHeader>
			<Form
				form={form}
				onFinish={(values) => {
					if (isEmpty(values)) {
						handleSubmitFailed();
					} else {
						submitForm(values, APPROVED_STATUS.reject);
					}
				}}
				onFinishFailed={handleSubmitFailed}
				validateTrigger="onSubmit"
			>
				<TableWithQuery<DataProjectWorkLog, WorkLogParams>
					testId="project-worklog"
					refetchDep={refetchDep}
					url={PROJECT_LOGWORK}
					defaultParams={DEFAULT_WORKLOG_PARAMS}
					requiredParams={REQUIRED_PROJECT}
					exportParams={{
						url: PROJECT_LOGWORK_EXPORT,
						filename: `${urlParams.projectKey} ${t("project")}`,
					}}
					columns={columns}
					rowSelection={
						editPermission
							? {
								selectedRowKeys: selectedKeys,
								onChange(selectedRowKeys) {
									setSelectedKeys(selectedRowKeys);
								},
								getCheckboxProps({ date }) {
									return {
										disabled: moment(date, FORMAT_DATE) < disabledEditTime,
									};
								},
							}
							: undefined
					}
					headerBtns={
						editPermission ? (
							<HeaderBtnGroup>
								<ApprovedBtn
									loading={sending}
									disabled={selectedKeys.length === 0}
									onClick={() => {
										submitForm(form.getFieldsValue(), APPROVED_STATUS.approved);
									}}
								/>
								<RejectBtn
									disabled={selectedKeys.length === 0}
									loading={sending}
									htmlType="submit"
								/>
							</HeaderBtnGroup>
						) : undefined
					}
				/>
			</Form>
		</CustomLayout>
	);
}

export default ProjectWorkLog;
