import { EyeOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { useForm } from "antd/lib/form/Form";
import { columnState } from "localStorage";
import React, { SetStateAction, memo, useCallback, useState } from "react";
import { ECol, ETableCol } from "types";
import { concatString, getPopupContainer } from "utils";
import { TooltipBtn } from "../TooltipBtn";
import { EyeMenuProps, TToggleCol } from "./EyeMenu";
import StyledEyeMenu from "./StyledEyeMenu";

export type ECols = ECol[];
export interface ToggleColsProps<ColType extends ECol = ETableCol<any>> {
	cols: ColType[];
	setCols: React.Dispatch<SetStateAction<ColType[]>>;
	locale?: {
		title?: string;
		okText?: string;
		resetText?: string;
	};
	testId?: string;
}

function ToggleCols<ColType extends TToggleCol = TToggleCol>({
	cols,
	setCols,
	locale = {
		title: "Toggle columns's visibility",
		okText: "OK",
		resetText: "Reset",
	},
	testId,
}: ToggleColsProps<ColType>) {
	const [visible, setVisible] = useState(false);
	const [form] = useForm<{ cols: ColType[] }>();

	const handleOk = useCallback<NonNullable<EyeMenuProps<ColType>["onOk"]>>(
		(cols) => {
			let colState = "";
			let startIndex = 0;
			const newColState = cols.map(({ title, show, columnId, ...value }, i) => {
				if (!show) {
					if (i > startIndex) {
						colState += "__";
					}
					colState += columnId;
				} else {
					startIndex++;
				}

				return {
					...value,
					title,
					hidden: !show,
				};
			});
			setCols(newColState as any);
			columnState.setItem(colState, testId);
			setVisible(false);
		},
		[setCols, testId]
	);

	const handleReset = useCallback<
		NonNullable<EyeMenuProps<ColType>["onReset"]>
	>(
		(cols) => {
			setCols(cols);
			columnState.setItem("", testId);
			setVisible(false);
		},
		[setCols, testId]
	);

	return (
		<Dropdown
			visible={visible}
			onVisibleChange={(visible) => {
				setVisible(visible);
			}}
			getPopupContainer={getPopupContainer}
			placement="bottomRight"
			overlay={
				<StyledEyeMenu
					form={form}
					cols={cols}
					onOk={handleOk}
					onReset={handleReset}
					okText={locale.okText}
					resetText={locale.resetText}
					visible={visible}
				/>
			}
			trigger={["click"]}
		>
			<TooltipBtn
				title={locale.title}
				icon={
					<EyeOutlined
						data-testid={concatString(testId, "toggle-columns-btn")}
					/>
				}
			/>
		</Dropdown>
	);
}

export default memo(ToggleCols);
