import { Spin, message } from "antd";
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartData,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	registerables as registerablesJS,
} from "chart.js";
import {
	GraphLayout,
	Heading,
	StatisticBoxProps,
	StatisticList,
} from "components";
import { BAR_OPTIONS } from "constants/barChart";
import { useFetchWithUrlParams, useUrlParams } from "hooks";
import { merge } from "lodash";
import React, { useCallback, useRef } from "react";
import { Chart, ChartProps } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useTranslation } from "react-i18next";
import { exportWithConfirm, handleExportDate } from "utils";
import ChartHeader from "./ChartHeader";

export type TDefaultChartData = ChartData<"bar" | "line", number[], string>;

export interface IChartLayoutProps {
	backBtn?: React.ReactNode;
	title?: React.ReactNode;
	exportTitle?: string;
	ticketList: StatisticBoxProps[];
	chartData: TDefaultChartData;
	loading?: boolean;
	options?: ChartProps<"bar" | "line">["options"];
}

export interface IChartWithQueryProps<TicketData, WorkLogChartData>
	extends Omit<IChartLayoutProps, "ticketList" | "chartData"> {
	urlGetTicket: string;
	urlGetData: string;
	defaultParams?: object;
	requiredParams?: object;
	handleTicketData: (ticketData?: TicketData) => StatisticBoxProps[];
	handleChartData: (data?: WorkLogChartData) => TDefaultChartData;
}

ChartJS.register(...registerablesJS);
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

function ChartLayout({
	backBtn,
	title,
	exportTitle,
	ticketList,
	chartData,
	options,
	loading,
}: IChartLayoutProps) {
	const { t } = useTranslation();
	const chartRef =
		useRef<ChartJSOrUndefined<"bar" | "line", number[], string>>();
	const { urlParams } = useUrlParams();

	const exportChartToImg = useCallback(() => {
		if (Number(chartData.labels?.length) > 0) {
			if (chartRef.current) {
				const exportName = handleExportDate({
					excelName: `${
						exportTitle
							? exportTitle
							: typeof title === "string"
							? title
							: (urlParams.projectKey as string)
					}`,
					...urlParams,
				});
				exportWithConfirm(chartRef.current.toBase64Image(), exportName);
			}
		} else {
			message.error(t("msg.exportNoData"));
		}
	}, [urlParams, title, chartData, exportTitle, t]);
	return (
		<>
			<StatisticList dataSource={ticketList} />
			<GraphLayout
				exportFunc={exportChartToImg}
				header={
					<ChartHeader>
						{backBtn}
						<Heading level={4}>{title}</Heading>
					</ChartHeader>
				}
			>
				<Spin spinning={loading}>
					<Chart
						ref={chartRef}
						type="bar"
						options={merge({}, BAR_OPTIONS, options)}
						data={chartData}
					/>
				</Spin>
			</GraphLayout>
		</>
	);
}

function ChartWithQuery<TicketData, WorkLogChartData>({
	urlGetTicket,
	urlGetData,
	defaultParams,
	requiredParams,
	handleTicketData,
	handleChartData,
	...props
}: IChartWithQueryProps<TicketData, WorkLogChartData>) {
	const { data: ticketData, loading: ticketLoading } = useFetchWithUrlParams<
		{},
		TicketData
	>({
		url: urlGetTicket,
		defaultParams,
		requiredParams,
	});

	const { data, loading } = useFetchWithUrlParams<{}, WorkLogChartData>({
		url: urlGetData,
		defaultParams,
		requiredParams,
	});

	return (
		<ChartLayout
			ticketList={handleTicketData(ticketData)}
			chartData={handleChartData(data)}
			loading={ticketLoading || loading}
			{...props}
		/>
	);
}

export { ChartWithQuery, ChartLayout };
