import { Form } from "antd";
import { SelectField } from "components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { objMap } from "utils";
import { SYNC_STATUS } from "../constants";

function SyncStatusSelect() {
	const { t } = useTranslation("projectJiraSync");

	const options = useMemo(
		() =>
			objMap(SYNC_STATUS, (_value, key) => ({
				value: key,
				label: t(`syncStatus.${key}`),
			})),
		[t]
	);

	return (
		<Form.Item name="status" label={t("status")}>
			<SelectField options={options} mode="multiple" />
		</Form.Item>
	);
}

export default React.memo(SyncStatusSelect);
