import { Form } from "antd";
import axiosClient from "api";
import { InputField, ModalForm } from "components";
import { EDIT_ROLE_FACTOR } from "constants/api";
import { useAppSelector } from "hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RoleFactor, roleNames } from "../types";
import CommonFields from "./CommonFields";
import { RoleSelect } from "./SomethingSelect";

export default function RoleFactorFormForm({
	isProjectTab,
}: {
	isProjectTab?: boolean;
}) {
	const { t: roleT } = useTranslation("metrics", {
		keyPrefix: "role",
	});
	const { record, mode } = useAppSelector((state) => state.modal);
	const [form] = Form.useForm<RoleFactor>();

	const handleSubmit = useCallback(
		async (values: any) => {
			if (mode === "add") {
				return await axiosClient.post(EDIT_ROLE_FACTOR, values);
			} else {
				return await axiosClient.put(EDIT_ROLE_FACTOR, {
					...values,
					[roleNames.id]: record[roleNames.id],
				});
			}
		},
		[record, mode]
	);
	const initialValues = useCallback(
		(record: RoleFactor) => {
			if (mode === "add") {
				return {
					[roleNames.status]: true,
				};
			} else {
				return {
					role: record[roleNames.role],
					key: record[roleNames.key],
					[roleNames.default]: +record[roleNames.default],
				};
			}
		},
		[mode]
	);

	return (
		<ModalForm
			formName="memberMetrics"
			handleSubmit={handleSubmit}
			form={form}
			title={mode === "add" ? roleT("add") : roleT("edit")}
			initialValues={initialValues}
		>
			{mode === "add" && isProjectTab ? (
				<RoleSelect name="role" label={roleT("roleForm")} />
			) : (
				<InputField
					rules={[{ whitespace: true }]}
					maxLength={20}
					label={roleT("roleForm")}
					name="role"
					disabled={isProjectTab}
					required
				/>
			)}
			<InputField
				rules={[{ whitespace: true }]}
				maxLength={10}
				label={roleT("key")}
				name="key"
				disabled={isProjectTab}
				required
			/>
			<CommonFields />
		</ModalForm>
	);
}
