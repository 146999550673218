import { Radio } from "antd";
import { FormItem } from "components/FormFields";
import { IFormItemProps } from "components/FormFields/FormItem";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface StatusRadioProps extends Partial<IFormItemProps> {
	type?: "number" | "boolean";
}

export const StatusRadio = ({
	name = "status",
	label,
	type = "boolean",
	...props
}: StatusRadioProps) => {
	const { t } = useTranslation("translation");
	const status = useMemo(() => {
		if (type === "number") {
			return [
				{
					value: 0,
					label: t("statusTags.inactive"),
				},
				{
					value: 1,
					label: t("statusTags.active"),
				},
			];
		}
		return [
			{
				value: false,
				label: t("statusTags.inactive"),
			},
			{
				value: true,
				label: t("statusTags.active"),
			},
		];
	}, [t, type]);

	const radioLabel = label || t("status");

	return (
		<FormItem {...props} name={name} label={radioLabel}>
			<Radio.Group data-testid={`${radioLabel}-radio`}>
				{status.map(({ value, label }) => (
					<Radio
						key={label}
						value={value}
						data-testid={`${radioLabel}-radio-selection`}
					>
						{label}
					</Radio>
				))}
			</Radio.Group>
		</FormItem>
	);
};
