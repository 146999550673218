import { ONLY_TAB_REGEX, REMOVE_TAB_REGEX } from "constants/format";
import { safeParse } from "utils";

const getKey = () => {
	const tab = REMOVE_TAB_REGEX.exec(window.location.search)?.[0]?.slice(1);

	return {
		tab,
		key: tab ? `${window.location.pathname}?${tab}` : window.location.pathname,
	};
};

type TPageStateObj = Record<string, string>;

const PAGE_SEARCH_STATE = "PAGE_SEARCH_STATE";

const pageSearch = {
	getAll() {
		const stringState = sessionStorage.getItem(PAGE_SEARCH_STATE);
		return safeParse<TPageStateObj>(stringState, {});
	},
	getItem() {
		const { key, tab } = getKey();
		let searchUrl = this.getAll()[key];
		if (tab && searchUrl) {
			searchUrl = tab.concat("&" + searchUrl);
		}
		return searchUrl;
	},
	setItem(newSearchParams: string) {
		if (!ONLY_TAB_REGEX.test(newSearchParams)) {
			const { key } = getKey();
			const allPageSearches = this.getAll();
			allPageSearches[key] = newSearchParams.replace(REMOVE_TAB_REGEX, "");
			sessionStorage.setItem(
				PAGE_SEARCH_STATE,
				JSON.stringify(allPageSearches)
			);
		}
	},
};

const COLUMN_STATE_KEY = "COLUMN_STATE_KEY";

const columnState = {
	getKey(tableName: string = "") {
		return getKey().key + "_" + tableName;
	},
	getAll() {
		const columnStatesString = sessionStorage.getItem(COLUMN_STATE_KEY);
		return safeParse<TPageStateObj>(columnStatesString, {});
	},
	getItem(tableName?: string): string | undefined {
		const key = this.getKey(tableName);
		const allColumnStates = this.getAll();
		return allColumnStates[key];
	},
	setItem(colState: string, tableName: string = "") {
		const key = this.getKey(tableName);
		const allColumnStates = this.getAll();
		allColumnStates[key] = colState;
		sessionStorage.setItem(COLUMN_STATE_KEY, JSON.stringify(allColumnStates));
	},
};

export { pageSearch, columnState };
