import  { useEffect, useState } from 'react'

export default function useScroll() {

	  // storing this to get the scroll direction
		const [lastScrollTop, setLastScrollTop] = useState<number>(0);
    // the offset of the document.body
   const [bodyOffset, setBodyOffset] = useState<DOMRect>(
		 document.body.getBoundingClientRect()
		 );
    // the vertical direction
   const [scrollY, setScrollY] = useState<number>(bodyOffset.top);
    // the horizontal direction
   const [scrollX, setScrollX] = useState<number >(bodyOffset.left);
    // scroll direction would be either up or down
   const [scrollDirection, setScrollDirection] = useState<"down" | "up">();

   const listener = (e: any) => {
		 setBodyOffset(document.body.getBoundingClientRect());
		 setScrollY(-bodyOffset.top);
		 setScrollX(bodyOffset.left);
		 setScrollDirection(-bodyOffset.top<200 || (lastScrollTop > -bodyOffset.top) ? "down" : "up");
		 setLastScrollTop(-bodyOffset.top);
	}

   useEffect(() => {
		 window.addEventListener("scroll", listener);

     return () => {
       window.removeEventListener("scroll", listener);
     };
   });
	return {scrollY,scrollX,scrollDirection};

}
