import { useTranslation } from "react-i18next";
import { GroupData } from "../../types";
import NestedTable from "components/NestedTable";
import { useCallback, useMemo } from "react";
import { NestedColumnProps, NestedColumns } from "components/NestedTable/types";
import { addPercent, checkNotNull } from "utils";
import { MFooter } from "components/NestedTable/components";
import { EditMetricTable } from "../EditMetricTable";
import { StarIcon } from "../StarIcon";
import { Tooltip } from "antd";

type IMetricTableProps = {
	loading?: boolean;
	isEditing?: boolean;
	name: string;
	isSpecific?: boolean;
	data?: GroupData[];
};

function MetricTable({
	loading,
	data,
	isEditing,
}: IMetricTableProps) {
	const { t: translate } = useTranslation();
	const { t } = useTranslation("metrics");
	const tableT = useCallback((key: string) => {
		return t(`evaluationTable.${key}`)
	}, [t]);

	const columns = useMemo<NestedColumns>(() => {
		const metricValueCol: NestedColumnProps = {
			key: "metricValues",
			children: [
				{
					key: "range",
					title: tableT("range"),
					style: {
						textAlign: "center",
						width: "12.5rem",
					},
					render: (_, { minScore, maxScore, isAbsolute }, parentRow) => {
						if (checkNotNull(minScore) && minScore === maxScore) return `${parentRow?.name} = ${minScore}`;

						const prefix = isAbsolute ? "±" : "";
						const downLimit = checkNotNull(minScore) ? `${prefix}${minScore} <` : "";
						const topLimit = checkNotNull(maxScore) ? `\u2264 ${prefix}${maxScore}` : ""
						return `${downLimit} ${parentRow?.name} ${topLimit}`;
					},
				},
				{
					key: "score",
					title: tableT("score"),
					style: {
						width: "9.375rem",
						textAlign: "center",
					},
					required: isEditing,
				},
			],
		};

		const metricCol: NestedColumnProps = {
			key: "metric",
			children: [
				{
					key: "name",
					title: tableT("metric"),
					style: {
						width: "12.5rem",
					},
					render: (metric, row) => (
						<>
							{metric} {row?.metricRequired && <StarIcon active/>}
						</>
					),
					required: isEditing,
				},
				{
					key: "unit",
					title: tableT("unit"),
					style: {
						width: "7.5rem",
						textAlign: "center",
						textTransform: "capitalize",
					},
					required: isEditing,
				},
				{
					key: "description",
					title: tableT("description"),
					style: {
						minWidth: "12.5rem",
					},
				}
			],
		}

		if (isEditing) {
			metricValueCol.children.push({
				key: "action",
				style: {
					width: "4rem",
					textAlign: "center",
				},
			});
			metricValueCol.children.splice(
				0,
				1,
				{
					key: "minScore",
					title: (
						<Tooltip title={t("metrics:info.min")}>{tableT("min")}</Tooltip>
					),
					style: {
						width: "9.375rem",
						textAlign: "center",
					},
				},
				{
					key: "maxScore",
					title: (
						<Tooltip title={t("metrics:info.max")}>{tableT("max")}</Tooltip>
					),
					style: {
						width: "9.375rem",
						textAlign: "center",
					},
				}
			);

			metricCol.children.push({
				key: "absolute",
				title: tableT("absolute"),
				style: {
					width: "5.75rem",
					textAlign: "center",
				}
			})
		}

		metricCol.children.push(metricValueCol);

		return [
			{
				key: "name",
				title: tableT("group"),
				style: {
					width: "9.375rem",
					sticky: true,
					textAlign: "left",
				},
			},
			{
				key: "weight",
				title: tableT("weight"),
				style: {
					width: "7.5rem",
					textAlign: "center",
				},
				render: addPercent,
				required: isEditing,
			},
			metricCol
		];
	}, [isEditing, t, tableT]);

	return (
		<NestedTable
			columns={columns}
			data={data}
			loading={loading}
			body={isEditing ? <EditMetricTable columns={columns}/> : undefined}
			footer={
				!isEditing && (
					<>
						<MFooter width="9.375rem" sticky>
							{translate("metrics:sum")}
						</MFooter>
						<MFooter>{addPercent(100)}</MFooter>
					</>
				)
			}
		/>
	);
}

export default MetricTable;
