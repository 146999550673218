import styled from "@emotion/styled";
import { Form, Radio, Typography } from "antd";
import axiosClient from "api";
import { FormItem, InputField, ModalForm, RangePicker } from "components";
import { PLAN_ALLOCATION } from "constants/api";
import { FORMAT_TIME } from "constants/format";

import { InfoCircleOutlined } from "@ant-design/icons";
import { LOGTIME_UNIT } from "constants/setting";
import { useAppSelector } from "hooks";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { effortNames } from "translations/keys/plan";
import { concatString, endOfDay } from "utils";
import { ROLES } from "../constants";
import { AllocationFormValues } from "../types";

const { Text } = Typography;

export const FormGroup = styled.div`
	display: flex;
	gap: 1rem;
	> * {
		flex: 1;
	}
`;

function EditForm() {
	const { t } = useTranslation("plan", {
		keyPrefix: "allocation",
	});
	const { t: translate } = useTranslation("");
	const { record } = useAppSelector((state) => state.modal);
	const [form] = Form.useForm<AllocationFormValues>();

	const handleSubmit = useCallback(
		async (values: AllocationFormValues) => {
			delete values[effortNames.time];
			return await axiosClient.put(
				`${PLAN_ALLOCATION}/${record[effortNames.id]}`,
				{
					[effortNames.role]: values[effortNames.role],
				}
			);
		},
		[record]
	);

	const expiredEdit = useMemo(() => {
		if (record?.[effortNames.endDate]) {
			return moment(record[effortNames.endDate], FORMAT_TIME.DATE) < endOfDay();
		}
		return false;
	}, [record]);

	const initialValues = useCallback((record: any) => {
		const startDate = record[effortNames.startDate];
		const endDate = record[effortNames.endDate];
		const values = {
			...record,
			[effortNames.startDate]: startDate
				? moment(startDate, FORMAT_TIME.DATE)
				: undefined,
			[effortNames.endDate]: endDate
				? moment(endDate, FORMAT_TIME.DATE)
				: undefined,
			[effortNames.position]: record?.[effortNames.position],
			[effortNames.rank]: record?.[effortNames.rank],
			[effortNames.contractType]: record?.[effortNames.contractTypeId],
		};
		values[effortNames.time] = [
			values[effortNames.startDate],
			values[effortNames.endDate],
		];
		return values;
	}, []);

	return (
		<ModalForm
			formName="planAllocation"
			handleSubmit={handleSubmit}
			initialValues={initialValues}
			form={form}
			title={t("editMember")}
			disabled={expiredEdit}
			marginTopBtn={16}
		>
			<InputField name={effortNames.fullName} disabled />
			<FormItem name={effortNames.role}>
				<Radio.Group>
					{ROLES.map(({ value, label }) => (
						<Radio
							data-testid={concatString(effortNames.role, "radio-selection")}
							key={value}
							value={value}
						>
							{label}
						</Radio>
					))}
				</Radio.Group>
			</FormItem>
			<FormGroup>
				<InputField name={effortNames.position} disabled />
				<InputField name={effortNames.rank} disabled />
				<InputField
					data-testid={concatString(effortNames.worked, "input")}
					addonAfter={LOGTIME_UNIT}
					name={effortNames.worked}
					disabled
				/>
			</FormGroup>
			<FormItem name={effortNames.time}>
				<RangePicker
					format={FORMAT_TIME.DATE}
					style={{ width: "100%" }}
					disabled
				/>
			</FormItem>
			{expiredEdit ? (
				<Text type="warning">
					<InfoCircleOutlined color="orange" />{" "}
					{translate("msg.warningAllocationEN")}
				</Text>
			) : undefined}
		</ModalForm>
	);
}

export default EditForm;
