import React, { useCallback } from 'react';
import styled from "@emotion/styled";
import { StarFilled } from "@ant-design/icons";

type StarIconProps = {
	active?: boolean
};

export const StarIcon = styled(StarFilled)<StarIconProps>`
	color: ${({ theme, active }) => active ? theme.color.danger : theme.color.text};
`

type StarCheckboxProps = {
	value?: boolean;
	onChange?: (value: boolean) => void;
}

export const StarCheckbox = ({ value, onChange }: StarCheckboxProps) => {
	const toggle = useCallback(() => {
		onChange?.(!value)
	}, [value, onChange]);
	return <StarIcon active={value} onClick={toggle}/>
};