import { InputField } from "components";
import { Key, useMemo } from "react";
import { renderLongComment } from "../components";
import { createProjectCols } from "../constants";

type Params = {
	selectedKeys?: Key[];
};

export const EditableCell = ({
	value,
	isEditing,
	id,
}: {
	id: string | number;
	value?: string;
	isEditing?: boolean;
}) => {
	return (
		<InputField
			inputType="textarea"
			rows={1}
			autoSize={{
				minRows: 1,
				maxRows: 5,
			}}
			noStyle
			required
			name={id}
			fieldProps={{
				initialValue: value,
				forView: !isEditing,
				renderViewMode: (inputValue) => renderLongComment(inputValue ?? value),
				shouldUpdate: true,
				errorTooltip: false,
			}}
		/>
	);
};

function useCreateCols({ selectedKeys }: Params) {
	const projectCols = useMemo(createProjectCols, []);

	return useMemo(() => {
		projectCols[projectCols.length - 1].render = (value, { id }) => {
			return (
				<EditableCell
					id={id}
					isEditing={selectedKeys && selectedKeys.includes(id)}
					value={value}
				/>
			);
		};
		return [...projectCols];
	}, [selectedKeys, projectCols]);
}

export default useCreateCols;
