import { DownOutlined, SyncOutlined } from "@ant-design/icons";
import { Dropdown, Form, Menu, Modal } from "antd";
import { manualSync } from "api/manualSync";
import { fetchTable, setLoading } from "appStore/commonSlice";
import { openModal, setModalMode } from "appStore/modalSlice";
import { EButton, ModalForm, ProjectSelect } from "components";
import { DROPDOWN_PROJECT_SYNC } from "constants/api";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SYNC_OPTIONS_KEYS } from "../constants";

const { useForm } = Form;

function SyncOptionsSelect() {
	const dispatch = useDispatch();
	const [form] = useForm();
	const { t } = useTranslation("projectJiraSync");
	const Items = useMemo(
		() =>
			SYNC_OPTIONS_KEYS.map((key) => {
				return {
					key,
					label: t(`syncOptions.${key}`),
					title: t(`syncOptions.${key}`),
				};
			}),
		[t]
	);

	return (
		<>
			<Dropdown
				trigger={["click"]}
				overlay={
					<Menu
						onClick={async (e) => {
							if (e.key === "all") {
								dispatch(setLoading(true));
								try {
									setTimeout(() => {
										manualSync.run();
									}, 3000);
									Modal.info({ content: t("message.SyncWait") });
								} catch (error) {}
								dispatch(setLoading(false));
								dispatch(fetchTable());
							} else {
								dispatch(openModal());
								dispatch(setModalMode("sync"));
							}
						}}
						items={Items}
					/>
				}
			>
				<EButton type="primary" title="sync" subTitle={<DownOutlined />} />
			</Dropdown>
			<ModalForm
				form={form}
				messages={t("message.SyncWait")}
				handleSubmit={async (values) => {
					setTimeout(() => {
						manualSync.run(values?.projects);
					}, 100);
				}}
				title={t("formLabel")}
				submitBtn={
					<EButton type="primary" title="sync" icon={<SyncOutlined />} />
				}
			>
				<ProjectSelect
					label={t("selectProject")}
					name="projects"
					projectUrl={DROPDOWN_PROJECT_SYNC}
					mode={undefined}
					fieldProps={{
						required: true,
					}}
				/>
			</ModalForm>
		</>
	);
}

export default React.memo(SyncOptionsSelect);
