import styled from "@emotion/styled";
import { Collapse } from "antd";
import { ICollapseProps } from ".";

const { Panel } = Collapse;

export const StyledAccordion = styled(Collapse, {
	shouldForwardProp: (propName) => propName !== "infoPosition",
})<Omit<ICollapseProps, "items">>`
	.ant-collapse-header-text {
		display: flex;
		align-items: center;
		gap: 0.875rem;
		.anticon.anticon-info-circle {
			justify-self: ${({ infoPosition = "start" }) => infoPosition};
		}
	}
	${(props) =>
		props.noPaddingX &&
		`.ant-collapse-content > .ant-collapse-content-box {
		padding-right: 0;
		padding-left: 0;
	}`}
`;

export const StyledPanel = styled(Panel)`
	.ant-collapse-header.ant-collapse-header {
		align-items: center;
		background-color: ${({ theme }) => theme.color.primary + "88"};
	}
	.ant-collapse-header.ant-collapse-header {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.ant-collapse-expand-icon {
		display: flex;
		align-items: center;
		.anticon.anticon {
			font-size: 1rem;
		}
	}
`;
