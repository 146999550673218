import React, { useCallback, useMemo } from "react";
import { FormItem, InputNumberField } from "components";
import { Rule } from "rc-field-form/lib/interface";
import { Form } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { useTranslation } from "react-i18next";
import { checkNotNull } from "../../../../../utils";

type GetFunction<T> = T extends Function ? T : never

type RangeFieldProps = {
	nestedName: [number, number, number];
	name: "minScore" | "maxScore";
};

const RangeField = ({ nestedName, name }: RangeFieldProps) => {
	const { t } = useTranslation("metrics");
	const form = Form.useFormInstance();

	const { parentName, fieldName, absoluteName, minName, maxName, isMin } = useMemo(() => {
		const fieldName = [nestedName[2], name];
		const parentName = [
			"groups",
			nestedName[0],
			"metric",
			nestedName[1],
			"metricValues",
		];
		const absoluteName = [...parentName];
		absoluteName.splice(absoluteName.length - 1, 1, "isAbsolute");

		const isMin = name === "minScore";
		let minName: NamePath, maxName: NamePath;

		if (isMin) {
			maxName = parentName.concat([nestedName[2], "maxScore"])
			minName = parentName.concat([nestedName[2] - 1, "maxScore"])
		} else {
			maxName = parentName.concat([nestedName[2] + 1, "minScore"])
			minName = parentName.concat([nestedName[2], "minScore"])
		}

		return {
			fieldName,
			parentName,
			absoluteName,
			minName,
			maxName,
			isMin,
		};
	}, [nestedName, name]);

	const validateRule = useCallback<GetFunction<Rule>>(({ getFieldValue }) => {
		const isLast = nestedName[2] === getFieldValue(parentName).length - 1;
		const min = getFieldValue(minName) ?? -Infinity;
		const max = getFieldValue(maxName) ?? Infinity;
		return {
			type: "number",
			validator: (_, value) => {
				let realValue = value ?? (isMin ? -Infinity : Infinity);

				if (!checkNotNull(value)) {
					if (isMin ? max === Infinity : min === -Infinity) {
						return Promise.reject(t("msg.oneEmpty"));
					}
					if ((!isLast && realValue === max)
						|| (nestedName[2] !== 0 && realValue === min)) {
						return Promise.reject(t("msg.overlap"));
					}
				}

				if (realValue > max || realValue < min) {
					return Promise.reject(t("msg.overlap"));
				}
				return Promise.resolve();
			}
		};
	}, [minName, maxName, t, isMin])

	const handleChange = useCallback(() => {
		return form.validateFields([minName, maxName]);
	}, [minName, maxName, form])

	return (
		<FormItem
			noStyle
			shouldUpdate
			name={fieldName}
			parentName={parentName}
			rules={[validateRule]}
		>
			{() => {
				return <InputNumberField
					disabled={nestedName[2] === 0 && isMin && form.getFieldValue(absoluteName)}
					onChange={handleChange}/>
			}}
		</FormItem>
	);
};

export default RangeField;
