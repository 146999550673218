import { MY_CHART, MY_CHART_SUMMARY } from "constants/api";
import { ALL_PARAM, REQUIRED_PROJECT } from "constants/apiParams";
import { useUrlParams } from "hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TMyWorkLogChartData } from "types";
import { ChartWithQuery } from "../components/ChartLayout";
import { combineProjectDetailDataset, getProjectSummary } from "../utils";

const projectKeyParams = {
	...REQUIRED_PROJECT,
	projectKey: ALL_PARAM,
};

const requiredParams = {
	...projectKeyParams,
	fromDate: undefined,
};

function MyChartReport() {
	const { t } = useTranslation("workLog");
	const { urlParams } = useUrlParams();

	const handleChartData = useCallback(
		(data?: TMyWorkLogChartData) => {
			return combineProjectDetailDataset(data, urlParams.groupBy as any);
		},
		[urlParams.groupBy]
	);

	return (
		<ChartWithQuery
			urlGetData={MY_CHART}
			urlGetTicket={MY_CHART_SUMMARY}
			requiredParams={requiredParams}
			title={t("myChart")}
			handleChartData={handleChartData}
			handleTicketData={getProjectSummary}
		/>
	);
}

export default MyChartReport;
