import { ApiSelect } from "components";
import { DROPDOWN_COMPONENT } from "constants/api";
import { useUrlParams } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";

function ComponentSelect() {
	const { t } = useTranslation("workLog");
	const { urlParams } = useUrlParams();

	return (
		<ApiSelect
			label={t("component")}
			name="component"
			mode="multiple"
			url={`${DROPDOWN_COMPONENT}/${urlParams?.projectKey}`}
		/>
	);
}

export default React.memo(ComponentSelect);
