import {
	ExportOutlined,
	FullscreenExitOutlined,
	FullscreenOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import React, { MouseEventHandler, SetStateAction, memo } from "react";
import { FullScreenHandle } from "react-full-screen";
import { concatString } from "utils";
import ToggleCols, { ECols } from "./ToggleCols";
import { AsyncTooltipBtn, TooltipBtn } from "./TooltipBtn";

export interface UtilBarProps {
	cols?: ECols;
	setCols?: React.Dispatch<SetStateAction<ECols>>;
	exportFunc?: MouseEventHandler<HTMLSpanElement>;
	fullScreen: FullScreenHandle;
	className?: string;
	locale?: {
		fullscreen?: string;
		toggleCols?: string;
		export?: string;
	};
	testId?: string;
}

const UtilBarBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;
	> * {
		cursor: pointer;
		transition: all 0.3s;
		> svg {
			width: 1.5rem;
			height: 1.5rem;
		}
		&:hover {
			color: ${({ theme }) => theme.color.primary.toString()};
		}
	}
`;

export const UtilBar = memo(function UtilBar({
	exportFunc,
	fullScreen,
	className = "",
	cols,
	setCols = () => {},
	locale = {
		fullscreen: "Fullscreen",
		export: "Export file",
	},
	testId = "",
}: UtilBarProps) {
	return (
		<UtilBarBox className={className}>
			{typeof exportFunc === "function" && (
				<AsyncTooltipBtn
					title={locale.export}
					icon={
						<ExportOutlined data-testid={concatString(testId, "export-btn")} />
					}
					onClick={exportFunc}
				/>
			)}
			{cols && setCols && (
				<ToggleCols
					testId={testId}
					setCols={setCols}
					cols={cols}
					locale={{ title: locale.toggleCols }}
				/>
			)}
			<TooltipBtn
				title={locale.fullscreen}
				icon={
					fullScreen.active ? (
						<FullscreenExitOutlined
							onClick={fullScreen.exit}
							data-testid={concatString(testId, "fullscreen-btn")}
						/>
					) : (
						<FullscreenOutlined
							onClick={fullScreen.enter}
							data-testid={concatString(testId, "fullscreen-btn")}
						/>
					)
				}
			/>
		</UtilBarBox>
	);
});
