import { TOptions } from "i18next";
import i18n from "translations";

type TransFunc = (
	key: string,
	options?: string | TOptions | undefined
) => string;

export const translate: TransFunc = (
	key: string,
	options?: string | TOptions | undefined
) => i18n.t<string>(key, options);
