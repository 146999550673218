import styled from "@emotion/styled";

const ChartHeader = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 2px solid ${({ theme }) => theme.color.primary};
	button {
		border: 0;
	}
	p,
	span {
		display: inline-block;
		margin-left: 0.5rem;
		font-weight: 400;
	}
`;

export default ChartHeader;
