import { TTagStatusKey } from "components";

export type TKpiStatus = Exclude<TTagStatusKey, "inactive" | "active">;

export const KPI_STATUS: ReadonlyArray<TKpiStatus> = [
	"approved",
	"pending",
	"reject",
	"overdue",
] as const;
