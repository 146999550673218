export const projectNames = {
	id: "projectId",
	username: "username",
	project: "project",
	projectKey: "projectKey",
	pm: "pm",
	pmUsername: "pmUsername",
	member: "member",
	planStart: "planStart",
	planEnd: "planEnd",
	actualStart: "actualStart",
	actualEnd: "actualEnd",
	abe: "ABE",
	pe: "PE",
	ace: "ACE",
	ale: "ALE",
	billable: "billAbleConvertedEffort",
	children: "data",
	projectName:'projectName',
	actionStatus: "status"
} as const;

export const effortNames = {
	id: "id",
	recordIndex: "recordIndex",
	fullName: "fullName",
	username: "username",
	avatar: "avatarUrl",
	position: "position",
	rank: "rank",
	time: "time",
	role: "roleMember",
	positionId: "positionId",
	rankId: "rankId",
	contractType: "contractType",
	contractTypeId: "contractTypeId",
	worked: "worked",
	startDate: "startDate",
	endDate: "endDate",
	convertedEffort: "convertedEffort",
	calendarEffort: "calendarEffort",
} as const;

export const billableFormNames = {
	id: "id",
	username: "member",
	position: "position",
	positionId: "positionId",
	rankId: "rankId",
	rank: "rank",
	time: "time",
	startDate: "startDate",
	endDate: "endDate",
	note: "note",
	role: "role",
	worked: "worked",
	contractType: "contractType",
	contractTypeId: "contractTypeId",
	projectKey: "projectKey",
} as const;

export const freeResourceNames = {
	id: "id",
	fullName: effortNames.fullName,
	username: effortNames.username,
	avatar: effortNames.avatar,
	position: effortNames.position,
	rank: effortNames.rank,
	memberType: "memberType",
	contractType: effortNames.contractType,
	allocatedHour: "allocatedHour",
	days: "reportByDayResponseList",
	daysName: {
		day: "planDay",
		value: "planValue",
	},
	note: "note",
} as const;
