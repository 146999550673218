import React, { useCallback } from 'react';
import { FormItem, PercentInput } from "components";
import { GroupData } from "../../types";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

type WeightFieldProps = {
	nameGroup: number;
};

const WeightField = ({ nameGroup }: WeightFieldProps) => {
	const { t } = useTranslation("metrics");
	const form = Form.useFormInstance();
	const handleRevalidate = useCallback(() => {
		const otherGroup = [...Array(form.getFieldValue("groups")?.length ?? 0).keys()];
		otherGroup.splice(nameGroup, 1);
		return form.validateFields(otherGroup.map(group => ["groups", group, "weight"]));
	}, [form, nameGroup])

	return (
		<FormItem
			noStyle
			shouldUpdate
			name={[nameGroup, "weight"]}
			parentName="groups"
			rules={[{ required: true, type: "number", min: 1, max: 99 }, ({ getFieldValue }) => {
				const groups = getFieldValue("groups") as GroupData[];
				const total = groups.reduce((prev, { weight }) => prev + Number(weight ?? 0), 0)
				return {
					validator: (_, value) => {
						if (total !== 100) {
							return Promise.reject(t("msg.totalWeight"))
						}
						return Promise.resolve("");
					}
				}
			}]}
		>
			<PercentInput onChange={handleRevalidate}/>
		</FormItem>
	);
};

export default WeightField