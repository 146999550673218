import { addCustomMessageListener, addInitListener, sendCustomMessage, } from "@luigi-project/client";
import { authService } from "api";
import { setPermissionCodes } from "appStore/authSlice";
import jwtDecode from "jwt-decode";
import { Token } from "localStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { ParsedToken } from "types/auth";

let clientListener: number;
let authListener: string;

function App() {
	const [token, setToken] = useState(Token.get());
	const dispatch = useDispatch();
	useEffect(() => {
		if (!clientListener) {
			clientListener = addInitListener((context) => {
				sendCustomMessage({ id: "joinClient" }); //Gửi thông báo Main là đã kết nối
			});
			if (!authListener) {
				authListener = addCustomMessageListener("authToken", async (data) => {
					const newToken = (data as any).token;
					const { preferred_username } = jwtDecode<ParsedToken>(newToken);

					const userId: any = await authService
						.get("/admin/realms/hivetech/users", {
							headers: {
								Authorization: `Bearer ${newToken}`,
							},
							params: {
								briefRepresentation: true,
								exact: true,
								enabled: true,
								username: preferred_username,
							},
						})
						.then((res) => res.data);

					const groups = await authService
						.get(`/admin/realms/hivetech/users/${userId?.[0]?.id}/groups`, {
							headers: {
								Authorization: `Bearer ${newToken}`,
							},
							params: {
								briefRepresentation: false,
							},
						})
						.then((res) => res.data);
					if (Array.isArray(groups)) {
						const role = groups.find(
							(resMenu) => resMenu.name.includes("SF_"));
						const permissionCode = Object.keys(role.attributes);
						dispatch(setPermissionCodes(permissionCode));
						Token.set(newToken);
						setToken(newToken);
					}
				});
			}
		}
	}, [dispatch]);

	const router = useRoutes(routes);

	if (token) {
		return router;
	}
	return null;
}

export default App;
