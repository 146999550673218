import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import CustomLayout from "layout";
import { useTranslation } from "react-i18next";
import ProjectChartFilter from "./components/ProjectChartFilter";
import MyChartReport from "./tabs/MyChartReport";
import ProjectChartReport from "./tabs/ProjectChartReport";
import AllMember from "./tabs/AllMember";
import { translate } from "utils";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const TAB_MENU = [
	{
		tab: translate("workLog:me"),
		key: "me",
		children: <MyChartReport />,
	},
	{
		tab: translate("project"),
		key: "project",
		children: <ProjectChartReport />,
	},
	{
		tab: `${translate("function.all")} ${translate("member")}`,
		key: "all",
		children: <AllMember />,
	},
];

function ProjectReport() {
	const { t } = useTranslation("workLog");

	return (
		<CustomLayout
			currentPageLabel={t("report")}
			tabProps={{
				items: TAB_MENU,
			}}
		>
			<ProjectChartFilter />
		</CustomLayout>
	);
}

export default ProjectReport;
