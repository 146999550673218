import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Dropdown, Spin } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import { H5, Heading } from "components";
import { CHART_MEMBERS_DETAIL } from "constants/api";
import { useFetchWithUrlParams, useUrlParams } from "hooks";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TMemberInfo } from "types";
import { getPopupContainer, objMap } from "utils";

const MemberInfoMenu = styled.div`
	background-color: ${({ theme }) => theme.backgroundColor};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 21rem;
	min-height: 20rem;
	display: grid;
	padding: 1.75rem;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: masonry;
	p {
		margin-left: 0;
	}
`;

const Label = styled((props: TitleProps) => <Heading level={5} {...props} />)`
	::after {
		content: ": ";
		display: inline-block;
	}
`;

function MembersInfoDropdown() {
	const { t } = useTranslation("workLog", {
		keyPrefix: "projectInfo",
	});
	const { urlParams } = useUrlParams();
	const { data, loading } = useFetchWithUrlParams<{}, TMemberInfo>({
		url: CHART_MEMBERS_DETAIL,
		requiredParams: {
			projectKey: undefined,
		},
	});
	const MemberInfoContent = useMemo(() => {
		return objMap(data, (value, key) => {
			if (typeof value === "string" || typeof value === "number") {
				return (
					<React.Fragment key={key as string}>
						<Label>{t(key as any)}</Label>
						<H5>{value}</H5>
					</React.Fragment>
				);
			} else if (key === "positionSummary") {
				return (
					<React.Fragment key={key as string}>
						<Label>{t("position")}</Label>
						<H5>
							{(value as TMemberInfo["positionSummary"]).reduce(
								(prev, curr, index) => {
									return (
										prev +
										`${curr.role} (${curr.memberCount})${
											index !== value.length - 1 ? ", " : ""
										}`
									);
								},
								""
							)}
						</H5>
					</React.Fragment>
				);
			}
		}).concat(
			<React.Fragment key="positionList">
				<H5
					style={{
						gridColumn: "1 / 3",
					}}
				>
					{t("memberList")}
				</H5>
				{data?.positionList.map(({ member, positionName }) => (
					<React.Fragment key={member}>
						<p>{member}</p>
						<p>{positionName}</p>
					</React.Fragment>
				))}
			</React.Fragment>
		);
	}, [data, t]);

	return (
		<>
			{urlParams.projectKey}
			<Dropdown
				trigger={["click"]}
				getPopupContainer={getPopupContainer}
				overlay={
					<Spin spinning={loading}>
						<MemberInfoMenu>{MemberInfoContent}</MemberInfoMenu>
					</Spin>
				}
			>
				<InfoCircleOutlined />
			</Dropdown>
		</>
	);
}

export default React.memo(MembersInfoDropdown);
