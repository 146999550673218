export const TASK_TYPE_COLOR = {
	Task: undefined,
	Bug: "#FF4D4F",
	Epic: "#531DAB",
	Story: "#52C41A",
	"Sub-task": undefined,
	"Story Defect": "#FF4D4F",
} as const;

export const LOG_VALID = {
	invalid: 0,
	valid: 1,
} as const;

export const APPROVED_STATUS = {
	approved: "APPROVED",
	pending: "PENDING",
	reject: "REJECT",
} as const;
