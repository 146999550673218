import { BUDGET_UNIT, CONFIG_UNIT, LOGTIME_UNIT, VND } from "constants/setting";
import moment, { Moment } from "moment";
import { checkNotNull } from "./commonUtils";

export const formatFormName = (name: string, formName?: string) =>
	`${formName ? formName + "." : ""}${name.replace(/\.\d+/g, "")}`;

export const renderNull = (value: any) => value || "--";

export const addUnit =
	(unit: string) =>
	(s?: any, parenthesis: boolean = true) =>
		checkNotNull(s) ? s + (parenthesis ? ` (${unit})` : ` ${unit}`) : "--";
export const addLogtimeUnit = addUnit(LOGTIME_UNIT);
export const addBudgetUnit = addUnit(BUDGET_UNIT);
export const addCurrency = addUnit(VND);
export const addPercent = (s?: string | number) =>
	checkNotNull(s) ? `${s}${CONFIG_UNIT[1]}` : "--";

export const upperFirstLetter = (s: string) =>
	s[0].toLocaleUpperCase() + s.slice(1);

export const formatNum = (num: number | string = 0) => {
	const thisNum = num ?? "--";
	const value = Number(thisNum);
	return Number.isNaN(value)
		? thisNum
		: Math.round((value + Number.EPSILON) * 100) / 100;
};

export const endOfDay = (date?: moment.MomentInput) =>
	moment(date).endOf("day");

export const disableFutureDate = (date: Moment) => {
	const today = endOfDay(Date.now());
	return date > today;
};