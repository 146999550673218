import { ChartOptions } from "chart.js";

export const BAR_OPTIONS: ChartOptions<"bar" | "line"> = {
	plugins: {
		legend: {
			align: "end",
			labels: {
				padding: 20,
				textAlign: "center",
				pointStyle: "circle",
				usePointStyle: true,
			},
		},
	},
	responsive: true,
	interaction: {
		mode: "index",
		intersect: false,
	},
	scales: {
		x: {
			title: {
				display: true,
				text: "date",
			},
			stacked: true,
			grid: {
				lineWidth: 0,
			},
		},
		y: {
			stacked: true,
			suggestedMax: 10,
			title: {
				display: true,
				text: "hours",
				align: "end",
			},
		},
	},
};
