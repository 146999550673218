import { message } from "antd";
import axiosClient from "api";
import axios, { AxiosRequestConfig, Canceler } from "axios";
import { useCallback, useEffect, useState } from "react";
import { ApiResponse } from "types";
import { objToUrlParams } from "utils";
import { useUrlParams } from "./useUrlParams";

export interface UseFetchParams {
	url: string;
	params?: Record<string, any>;
	requiredParams?: Record<string, any>;
	config?: AxiosRequestConfig;
	refetchDep?: any; //re-fetch data when this parameter changes
}

function useFetch<DataType = any, Response = ApiResponse<DataType>>({
	url,
	params,
	requiredParams = {},
	refetchDep,
	config,
}: UseFetchParams) {
	const [data, setData] = useState<Response | undefined>();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	const [isUpdated, setIsUpdated] = useState(false);

	const refetch = useCallback(() => {
		setIsUpdated((prev) => !prev);
	}, []);

	useEffect(() => {
		let cancel: Canceler;
		const fetchData = async () => {
			let callCondition: boolean = true;
			const newParams = Object.assign({}, params);
			for (const key in requiredParams) {
				if (Object.prototype.hasOwnProperty.call(requiredParams, key)) {
					const element = requiredParams[key];
					if (typeof newParams[key] === "undefined") {
						if (typeof element !== "undefined") {
							newParams[key] = element;
						} else {
							callCondition = false;
							break;
						}
					}
				}
			}

			if (callCondition) {
				setError("");
				setLoading(true);
				try {
					const { data } = await axiosClient.get<Response>(url, {
						params: newParams,
						cancelToken: new axios.CancelToken((c) => {
							cancel = c;
						}),
						...config,
					});
					setData(data);
					setLoading(false);
				} catch (error) {
					const errorMsg = (error as any)?.message;
					if (!axios.isCancel(error)) {
						console.log("error :", error);
						setError(errorMsg);
						setLoading(false);
						message.error(errorMsg);
					}
				}
			}
		};
		fetchData();
		return () => {
			cancel?.();
		};
		// eslint-disable-next-line
	}, [url, objToUrlParams(params), isUpdated, refetchDep]);

	return {
		loading,
		error,
		data,
		refetch,
	};
}

export interface UseFetchWithUrlParams extends UseFetchParams {
	defaultParams?: object;
}

function useFetchWithUrlParams<
	DataType = any,
	Response = ApiResponse<DataType>
>({
	url,
	defaultParams,
	requiredParams,
	refetchDep,
	config,
}: UseFetchWithUrlParams) {
	const { urlParams } = useUrlParams(defaultParams);

	return useFetch<DataType, Response>({
		url,
		params: urlParams,
		requiredParams,
		refetchDep,
		config,
	});
}

export { useFetch, useFetchWithUrlParams };
