import { TooltipParagraph } from "components";
import { billableFormNames, effortNames } from "translations/keys/plan";
import { ETableCols } from "types";
import { PlanBillableRecord } from "types/plan/project";
import { formatNum } from "utils";
import FullNameAndUsername from "../components/FullNameAndUsername";
import { AvatarImg } from "../components/SmallComponents";
import { createPlanCol } from "../utils";

export const billableColumns: ETableCols<PlanBillableRecord> = [
	{
		dataIndex: effortNames.avatar,
		align: "center",
		width: "48px",
		render: (value) => value && <AvatarImg src={value} alt="avatar" />,
	},
	createPlanCol(
		effortNames.fullName,
		{
			align: "left",
			sorter: true,
			render: (value: string, record) => (
				<FullNameAndUsername
					fullName={value}
					userName={record[billableFormNames.username]}
				/>
			),
		},
		"billable"
	),
	createPlanCol(effortNames.position, {
		sorter: true,
	}),
	createPlanCol(effortNames.rank, {
		sorter: true,
	}),
	createPlanCol(effortNames.contractType),
	createPlanCol(effortNames.worked, {
		sorter: true,
		width: 100,
		render: formatNum,
	}),
	createPlanCol(effortNames.startDate, {
		align: "left",
	}),
	createPlanCol(effortNames.endDate, {
		align: "left",
	}),
	createPlanCol(
		effortNames.convertedEffort,
		{
			width: 150,
			render: formatNum,
		},
		"billable"
	),
	createPlanCol(
		effortNames.calendarEffort,
		{
			width: 150,
			render: formatNum,
		},
		"billable"
	),
	createPlanCol(
		billableFormNames.note,
		{
			align: "left",
			width: 200,
			render: (value: string) => <TooltipParagraph value={value} row={1} />,
		},
		"billable"
	),
];
