import { NestedColumnProps, NestedColumns } from "../types";
import { MCell, MHead, MNestedRow, MRow, RootMRow } from "../components";

export const renderHeader = (columns: NestedColumns, deep = 0) => {
	const RowComponent = deep ? MRow : RootMRow;

	return <RowComponent>
		{
			columns.map(col => {
				if ("children" in col) {
					return <MNestedRow key={col.key}>{renderHeader(col.children, ++deep)}</MNestedRow>;
				}
				return <MHead key={col.key} {...col.style}
											className={col.required ? "ant-form-item-required" : ""}>{col.title}</MHead>
			})
		}
	</RowComponent>
}

export const renderCell = (data: any[], columns: NestedColumns, rowKey = "id", parentRow?: Record<string, any>, deep = 0) => {
	const RowComponent = deep ? MRow : RootMRow;

	return data.map((row, index) => {
		return <RowComponent key={row[rowKey] ?? index}>
			{columns.map(col => {
				const dataCell = row[col.key];
				if ("children" in col) {
					return (
						<MNestedRow key={col.key}>
							{renderCell(dataCell, col.children, col.rowKey, row, deep + 1)}
						</MNestedRow>
					);
				}
				return <MCell key={col.key} {...col.style}>
					{col.render ? col.render(dataCell, row, parentRow) : dataCell}
				</MCell>
			})}
		</RowComponent>;
	});
};

function checkNestedCol(col?: NestedColumns[number]): col is NestedColumnProps {
	return Boolean(col && "children" in col);
}

export const getCol = (columns: NestedColumns, path: string[]) => {
	let i = 0
	let col = columns.find(column => column.key === path[i]);
	i++;
	if (!checkNestedCol(col)) {
		if (i < path.length) {
			return;
		}
		return col;
	}
	while (i < path.length) {
		col = col.children.find(column => column.key === path[i]);
		i++;
		if (!(col && "children" in col)) {
			break;
		}
	}
	if (i < path.length) {
		return;
	}
	if (checkNestedCol(col)) {
		return;
	}
	return col;
};
