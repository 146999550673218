import {
	CommonFilterForm,
	ProjectSelect,
	SearchInput,
	TableWithQuery,
} from "components";
import CustomLayout from "layout";
import StatusSelect from "./components/StatusSelect";

function ProjectKPI() {
	return (
		<CustomLayout>
			<div className="filter">
				<CommonFilterForm>
					<ProjectSelect />
					<StatusSelect />
					<SearchInput placeholder="projectKpi" />
				</CommonFilterForm>
			</div>
			<TableWithQuery url="" />
		</CustomLayout>
	);
}

export default ProjectKPI;
