import { PM_DISABLED_TIME } from "constants/format";
import moment from "moment";

export const getPMDisabledTime = () => {
	const today = moment(Date.now());
	const disabledTime = moment(today);
	disabledTime
		.set("date", PM_DISABLED_TIME.DATE)
		.set("hour", PM_DISABLED_TIME.HOUR)
		.set("minute", PM_DISABLED_TIME.MINUTE)
		.set("second", PM_DISABLED_TIME.SECOND);
	if (today <= disabledTime) {
		disabledTime.subtract(1, "month");
	}
	return disabledTime.startOf("month");
};
