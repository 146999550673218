import styled from "@emotion/styled";
import { Breadcrumb } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { concatString } from "utils";

interface breadcrumbItem {
	disabled?: boolean;
	label: string;
	path: string;
}
export interface UrlBreadcrumbProps {
	breadcrumbItems?: breadcrumbItem[];
	currentPageLabel?: string;
}

const Item = styled(Breadcrumb.Item)`
	text-transform: capitalize;

	a {
		color: inherit;
		text-transform: capitalize;
		&:hover {
			color: #1890ff;
		}
	}
	& + .ant-breadcrumb-separator,
	& {
		font-size: 1.25rem;
		font-weight: 500;
		display: inline-block;
		color: rgba(0, 0, 0, 0.85);
	}
`;

function UrlBreadcrumb({
	breadcrumbItems,
	currentPageLabel,
}: UrlBreadcrumbProps) {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const paths = pathname.split("/").slice(1);
	const breadcrumbs =
		breadcrumbItems ||
		paths.map((path, index) => {
			if (index === paths.length - 1 && currentPageLabel) {
				return { disabled: true, label: currentPageLabel };
			}
			return {
				disabled: index === 0 || index === paths.length - 1,
				label: t(path as any),
				path: pathname.slice(0, pathname.indexOf(path) + path.length),
			};
		});
	return (
		<Breadcrumb>
			{breadcrumbs.map((item) => (
				<Item
					data-testid={concatString(item.path || item.label, "path")}
					key={item.path || item.label}
				>
					{item.disabled ? (
						item.label
					) : (
						<Link to={item.path}>{item.label}</Link>
					)}
				</Item>
			))}
		</Breadcrumb>
	);
}

export default React.memo(UrlBreadcrumb);
