import { FORMAT_TIME } from "constants/format";
import moment from "moment";

export const handleExportDate = ({
	excelName,
	fromDate,
	toDate,
}: {
	excelName?: string;
	fromDate?: string;
	toDate?: string;
}) => {
	const dateString = fromDate
		? `${fromDate}_${toDate ? toDate : moment().format(FORMAT_TIME.DATE)}`
		: "";
	return `${excelName ? excelName : ""}${dateString ? " " + dateString : ""}`;
};
