import { ContentLayout, IContentLayoutProps } from "components";
import React from "react";
import { useFullScreenHandle } from "react-full-screen";

type IGraphLayoutProps = Omit<
	IContentLayoutProps,
	"fullScreen" | "cols" | "setCols" | "locale"
>;

function GraphLayout({ children, ...props }: IGraphLayoutProps) {
	const handleFullScreen = useFullScreenHandle();

	return (
		<ContentLayout fullScreen={handleFullScreen} {...props}>
			{children}
		</ContentLayout>
	);
}

export { GraphLayout };
