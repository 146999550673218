/**
 * @description `FORMAT_DATE` is used for api
 */
export const FORMAT_DATE = "DD-MM-YYYY"; // for api
/**
 * @description `FORMAT_TIME` is used for format display time in website
 */
export const FORMAT_HOUR = "HH:mm";
export const FORMAT_TIME = {
	DATE: "DD/MM/YYYY",
	WEEK: "ww/yyyy",
	MONTH: "MM/YYYY",
} as const;
export type TKeyFormat = keyof typeof FORMAT_TIME;

export const PM_DISABLED_TIME = {
	DATE: 7,
	HOUR: 23,
	MINUTE: 0,
	SECOND: 0,
} as const;

export const PAGE_SIZES = [10, 20, 50];
export const TAB_NAME = "tab";
/**
 * @description this regex is for remove param {@link TAB_NAME} from the url search string
 */
export const REMOVE_TAB_REGEX = new RegExp(`[?&]?${TAB_NAME}=\\w+`);
/**
 * @description this regex is for matching url that only has param {@link TAB_NAME}
 */
export const ONLY_TAB_REGEX = new RegExp(`^[?&]?${TAB_NAME}=\\w+$`);
