import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import { Translation } from "translations";
import { TApprovedStatus } from "types";

export type TTagStatusKey = keyof Translation["translation"]["statusTags"];

const approvedStatus = {
	approved: "success",
	pending: "warning",
	reject: "error",
	overdue: undefined,
};

export type TApprovedStatusTagProps = {
	value: TApprovedStatus;
};

export const ApprovedStatusTag = ({ value }: TApprovedStatusTagProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "statusTags",
	});
	const key = value.toLocaleLowerCase() as keyof typeof approvedStatus;
	return <Tag color={approvedStatus[key]}>{t(key)}</Tag>;
};

export type TStatusTagProps = {
	value: number | boolean;
};
export function StatusTag({ value }: TStatusTagProps) {
	const { t } = useTranslation("translation", {
		keyPrefix: "statusTags",
	});
	return (
		<Tag color={value ? "success" : "error"}>
			{t(value ? "active" : "inactive")}
		</Tag>
	);
}
