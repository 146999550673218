import { Form, FormListFieldData, Input } from "antd";
import {
	AddBtn,
	CancelBtn,
	FormItem,
	InputNumberField,
	MemberSelect,
} from "components";
import { TRangeFieldProps } from "components/CommonFields/RangeField";
import { BUDGET_UNIT } from "constants/setting";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	addBudgetUnit,
	concatString,
	disableFutureDate,
	endOfDay,
	translate,
} from "utils";
import {
	DURATION_STATE,
	TDurationState,
	combineDateNames,
	durationNames,
} from "../constants";
import { BreakWordBox, CombineFormItem } from "../styles";
import TimeRangeField from "./TimeRangeField";
import moment from "moment";
import { t } from "i18next";
const { List, useFormInstance } = Form;

type IDurationProps = {
	forView?: boolean;
	parentName: string;
	field: Omit<FormListFieldData, "key">;
	durationState?: TDurationState;
	loading: boolean;
};

const NextDurationRange = ({
	name,
	disabledDate,
	fieldProps,
	...props
}: TRangeFieldProps & {
	name: [number, string];
}) => {
	const form = useFormInstance();
	const actualEnd = useMemo(
		() =>
			form.getFieldValue([
				fieldProps?.parentName as string,
				name[0] - 1,
				combineDateNames.actualDate,
			])?.[1],
		[name, fieldProps?.parentName, form]
	);

	return (
		<TimeRangeField
			name={name}
			disabledDate={(date) => {
				const result = disabledDate ? disabledDate(date) : false;
				return result || (actualEnd ? date < endOfDay(actualEnd) : false);
			}}
			fieldProps={fieldProps}
			rules={[
				{
					warningOnly: true,
					validator: (_, value) => {
						const startYear = value?.[0];
						const checkYear = moment("2023");
						if (startYear && startYear <= checkYear) {
							return Promise.reject(
								`${t("msg.checkYear")} (${t("msg.checkYearVn")})`
							);
						}
						return Promise.resolve();
					},
				},
			]}
			{...props}
		/>
	);
};

// const BudgetTimeRange = ({
// 	parentTimeName,
// 	...props
// }: TRangeFieldProps & {
// 	parentTimeName: NamePath;
// }) => {
// 	const { t } = useTranslation();
// 	const form = useFormInstance();
// 	const actualParentName = Array.isArray(parentTimeName)
// 		? parentTimeName
// 		: [parentTimeName];
// 	const planDate = useWatch(
// 		actualParentName.concat(combineDateNames.planDate),
// 		form
// 	);
// 	const actualStart = useWatch(
// 		actualParentName.concat(combineDateNames.actualDate),
// 		form
// 	)?.[0];

// 	const checkDate = (start: Moment, end: Moment) => {
// 		return (
// 			(actualStart ? start < actualStart : start < planDate?.[0]) ||
// 			(planDate?.[1] ? end > endOfDay(planDate?.[1]) : false)
// 		);
// 	};

// 	return (
// 		<TimeRangeField
// 			rules={[
// 				{
// 					validator: (_, value) => {
// 						if (!props.disabled && value && checkDate(value[0], value[1])) {
// 							return Promise.reject(t("form:validateMsg.invalidDate"));
// 						}
// 						return Promise.resolve();
// 					},
// 				},
// 			]}
// 			disabledDate={(date) => {
// 				return checkDate(date, date);
// 			}}
// 			{...props}
// 		/>
// 	);
// };

function Duration({
	forView,
	parentName,
	field,
	durationState,
	loading,
}: IDurationProps) {
	const { t } = useTranslation("form", {
		keyPrefix: "planGeneral",
	});
	const { t: tran } = useTranslation();
	const form = useFormInstance();
	const { name, ...restField } = field;
	const ended = durationState === DURATION_STATE.ENDED || loading;
	const current = ended || durationState === DURATION_STATE.CURRENT || loading;

	const commonProps = useMemo(
		() => ({
			parentName,
			forView,
			className: "mb-0",
			...restField,
		}),
		[restField, parentName, forView]
	);

	const commonRequired = useMemo(
		() => ({
			...commonProps,
			required: true,
		}),
		[commonProps]
	);

	const adjustBudgetLabel = t(
		`${parentName}.${durationNames.adjustBudgets}.${durationNames.adjustBudget}`
	);

	return (
		<div>
			<MemberSelect
				key={name}
				name={[name, durationNames.pmUsername]}
				fieldProps={{
					...commonRequired,
					renderViewMode: () =>
						form.getFieldValue([parentName, name, durationNames.pm]),
				}}
				disabled={ended}
			/>
			<NextDurationRange
				name={[name, combineDateNames.planDate]}
				fieldProps={commonRequired}
				disabled={[current, ended]}
			/>
			<NextDurationRange
				name={[name, combineDateNames.actualDate]}
				fieldProps={commonProps}
				disabled={[current, ended]}
				allowEmpty={[false, true]}
				disabledDate={disableFutureDate}
			/>
			<FormItem
				{...commonRequired}
				name={[name, durationNames.businessRank]}
				rules={[
					{
						type: "number",
						min: 1,
						message: tran("msg.msgVldBusinessRank"),
					},
				]}
			>
				<InputNumberField disabled={ended} />
			</FormItem>
			<CombineFormItem
				label={t(`${parentName}.${durationNames.budget}`)}
				required
			>
				<FormItem
					name={[name, durationNames.budget]}
					{...commonRequired}
					rules={[
						{
							validator(_rule, value) {
								if (value === 0) {
									return Promise.reject(translate("msg.exportInfo"));
								}
								return Promise.resolve();
							},
						},
					]}
					label=""
					renderViewMode={addBudgetUnit}
				>
					<InputNumberField addonAfter={BUDGET_UNIT} disabled={current} />
				</FormItem>
				{/* <BudgetTimeRange
					parentTimeName={[parentName, name]}
					name={[name, combineDateNames.budgetDate]}
					fieldProps={commonRequired}
					label=""
					disabled={current}
				/> */}
			</CombineFormItem>
			<List name={[name, durationNames.adjustBudgets]}>
				{(fields, { add, remove }) => {
					if (durationState === DURATION_STATE.NEW) return null;
					return (
						<>
							{fields.map(({ key, name: adjustName, ...restField }, index) => {
								const isFirst = index === 0;
								const actualAdjustName = [
									parentName,
									name,
									durationNames.adjustBudgets,
								];

								const disabledAdjust = Boolean(
									ended ||
										form.getFieldValue(
											actualAdjustName.concat(adjustName, durationNames.id)
										)
								);

								return (
									<CombineFormItem
										key={key}
										label={isFirst ? adjustBudgetLabel : "  "}
										required={isFirst}
									>
										<FormItem
											{...commonRequired}
											{...restField}
											parentName={actualAdjustName}
											name={[adjustName, durationNames.adjustBudget]}
											label=""
											renderViewMode={addBudgetUnit}
										>
											<InputNumberField
												addonAfter={BUDGET_UNIT}
												disabled={disabledAdjust}
											/>
										</FormItem>
										{/* <BudgetTimeRange
											parentTimeName={[parentName, name]}
											name={[adjustName, combineDateNames.adjustBudgetDate]}
											fieldProps={{
												...commonRequired,
												...restField,
												parentName: actualAdjustName,
											}}
											label=""
											disabled={disabledAdjust}
										/> */}
										{!forView && !disabledAdjust && (
											<CancelBtn
												testId={actualAdjustName as any}
												onClick={() => {
													remove(adjustName);
												}}
											>
												{""}
											</CancelBtn>
										)}
									</CombineFormItem>
								);
							})}
							{!forView && !ended && (
								<CombineFormItem
									label=" "
									style={{
										marginTop: -14,
									}}
								>
									<AddBtn
										testId={[
											parentName,
											name as any,
											durationNames.adjustBudgets,
										]}
										style={{
											paddingLeft: 0,
										}}
										onClick={() => add()}
									>
										{adjustBudgetLabel}
									</AddBtn>
								</CombineFormItem>
							)}
						</>
					);
				}}
			</List>
			<FormItem
				{...commonProps}
				name={[name, durationNames.description]}
				renderViewMode={(value: any) => <BreakWordBox>{value}</BreakWordBox>}
			>
				<Input.TextArea
					data-testid={concatString([name, durationNames.description], "input")}
					disabled={ended}
					autoSize
					maxLength={1000}
				/>
			</FormItem>
		</div>
	);
}

export default React.memo(Duration);
