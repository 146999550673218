import styled from "@emotion/styled";

export const FlexColumn = styled.div<{
	gap?: string | number;
}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: ${({ gap }) => gap};
`;
