import { DatePicker } from "antd";
import React, { useEffect } from "react";
import { concatString } from "utils";
const { RangePicker: AntRangePicker } = DatePicker;

export type RangePickerProps = React.ComponentProps<typeof AntRangePicker>;

function RangePicker(props: RangePickerProps) {
	const testId = concatString(
		(props as any)["data-testid"] ?? props.id,
		"range-picker"
	);
	useEffect(() => {
		const pickerInputs = document.querySelectorAll<HTMLInputElement>(
			`[data-testid=${testId}] .ant-picker-input input`
		);
		if (pickerInputs) {
			pickerInputs.forEach((pickerInput) => {
				pickerInput.setAttribute(
					"data-testid",
					concatString(testId, pickerInput.placeholder)
				);
			});
		}
	}, [testId]);

	return (
		<AntRangePicker
			allowClear
			{...props}
			data-testid={testId}
			inputReadOnly={false}
		/>
	);
}

export default React.memo(RangePicker);
