import React from 'react';
import { Checkbox, Form } from "antd";

type AbsoluteFieldProps = {
	nameMetric: number;
	parentName: (string | number)[];
};

const AbsoluteField = ({ nameMetric, parentName }: AbsoluteFieldProps) => {
	return (
		<Form.Item noStyle shouldUpdate>
			{({ setFieldValue, getFieldValue }) => (
				<Form.Item noStyle name={[nameMetric, "isAbsolute"]} valuePropName="checked">
					<Checkbox onChange={e => {
						if (e.target.checked) {
							const firstMetricRangeName = parentName.concat([nameMetric, "metricValues", 0])
							setFieldValue(firstMetricRangeName, {
								...getFieldValue(firstMetricRangeName),
								minScore: 0,
								maxScore: 0,
							})
						}
					}}/>
				</Form.Item>
			)}

		</Form.Item>
	);
};

export default AbsoluteField