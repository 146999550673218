import translation from "./translation.json";
import workLog from "./workLog.json";
import freeResource from "./freeResource.json";
import projectJiraSync from "./projectJiraSync.json";
import metrics from "./metrics.json";
import form from "./form";
import plan from "./plan";

import projectJiraSyncDetail from "./projectJiraSyncDetail.json";

const en = {
	translation,
	workLog,
	freeResource,
	form,
	plan,
	projectJiraSync,
	metrics,
	projectJiraSyncDetail,
};

export default en;
