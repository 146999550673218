import styled from "@emotion/styled";
import { Layout } from "antd";
import UrlBreadcrumb, { UrlBreadcrumbProps } from "components/UrlBreadcrumb";
import { INavigateTabProps, useCreateNavigateTabs } from "hooks";
import useScroll from "hooks/useScroll";
import { ReactNode } from "react";

const { Header, Content } = Layout;

interface CustomLayoutProps extends UrlBreadcrumbProps {
	tabProps?: INavigateTabProps;
	header?: string | ReactNode;
	children?: ReactNode | ReactNode[];
}

export interface IWhiteHeaderProps {
	hasTabs?: boolean;
	scrollDirection?: string;
}

const WhiteHeader = styled(Header, {
	shouldForwardProp: (propName) => propName !== "hasTabs",
	target: "scrollDirection",
})<IWhiteHeaderProps>`
	background-color: white;
	padding: ${({ hasTabs }) => `1.5rem 1.25rem ${hasTabs ? "0" : ""}`};
	> :last-child {
		margin-top: ${({ hasTabs }) => `${hasTabs ? "0.625rem" : ""}`};
	}
	height: auto;
	line-height: inherit;
	z-index: 100;
	width: calc(100vw - 8px);
	visibility: visible;
	${({ scrollDirection }) =>
		scrollDirection === "up"
			? `
			visibility: hidden;
			transition: all 0.5s;
			transform: translateY(-90%);
	`
			: `
			visibility: visible;
			transition: all 0.5s;
			position: fixed;
	
	`}
`;

const Body = styled(Content, {
	shouldForwardProp: (propName) => propName !== "hasTabs",
	target: "scrollDirection",
})<IWhiteHeaderProps>`
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	padding: 1rem;
	margin-top: ${({ hasTabs }) => `${hasTabs ? "110px" : "80px"}`};
	${({ scrollDirection }) =>
		scrollDirection === "up"
			? `
			margin-top: 0px;
		.filter {
			position: fixed;
			transition: all 0.5s;
			z-index:100;
			top: 0;
			width: calc(100vw - 40px);
			padding: 20px 15px;
			background-color:white;
		}
		`
			: `
		.filter {
			transition: all 0.5s;
		}
`}
`;

function CustomLayout({
	header,
	children,
	tabProps,
	...props
}: CustomLayoutProps) {
	const { Children, Tabs } = useCreateNavigateTabs(tabProps || {});
	const { scrollDirection } = useScroll();
	return (
		<Layout
			style={{
				height: "100vh",
			}}
		>
			<WhiteHeader
				hasTabs={Boolean(tabProps)}
				scrollDirection={scrollDirection}
			>
				{header || <UrlBreadcrumb {...props} />}
				{tabProps && Tabs}
			</WhiteHeader>
			<Body scrollDirection={scrollDirection} hasTabs={Boolean(tabProps)}>
				{children}
				{Children}
			</Body>
		</Layout>
	);
}

export default CustomLayout;
export * from "./others";
