import { SubText } from "components";
import React from "react";

interface Props {
	fullName: string;
	userName: string;
}

function FullNameAndUsername({ fullName, userName }: Props) {
	return (
		<>
			<p>{fullName}</p>
			<SubText>{userName}</SubText>
		</>
	);
}

export default FullNameAndUsername;
