import styled from "@emotion/styled";
import { Card } from "antd";
import React from "react";

const CustomCard = styled(Card)`
	--gap: 0.9375rem;
	.ant-card-head {
		padding: 0 var(--gap);
	}
	.ant-cart-head-title,
	.ant-card-extra {
		padding: var(--gap) 0;
	}
	.ant-card-body {
		padding: var(--gap);
	}
	box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
`;
const AntCard = React.memo(CustomCard);

export { AntCard };
