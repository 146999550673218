import styled from "@emotion/styled";
import { UtilBar, UtilBarProps } from "./UtilBar";
import { ReactNode } from "react";
import { FullScreen } from "react-full-screen";

export const StyledFullScreen = styled(FullScreen)(({ theme }) => ({
	backgroundColor: theme.backgroundColor,
	flex: 1,
	display: "flex",
	color: theme.color.text,
}));

export const ContentContainer = styled.div`
	--text-color: ${({ theme }) => theme.color.text};
	--primary: ${({ theme }) => theme.color.primary};
	--background: ${({ theme }) => theme.backgroundColor};
	flex: 1;
	width: 100%;
	padding: 1rem;
	color: var(--text-color);
	background-color: var(--background);
`;

export const Header = styled.div`
	display: flex;
	padding: 0.5rem 0;
	& > :last-child {
		margin-left: auto;
	}
`;

export interface IContentLayoutProps extends UtilBarProps {
	header?: ReactNode | ReactNode[];
	children?: ReactNode | ReactNode[];
	extra?: ReactNode | ReactNode[];
}

export const ContentLayout = ({
	header,
	children,
	className,
	extra,
	...props
}: IContentLayoutProps) => (
	<StyledFullScreen handle={props.fullScreen}>
		<ContentContainer className={className}>
			<Header>
				{header}
				{extra ?? <UtilBar className="util-bar" {...props} />}
			</Header>
			{children}
		</ContentContainer>
	</StyledFullScreen>
);

export const Paper = styled.div`
	background-color: ${({ theme }) => theme.backgroundColor};
`;
