import { Form, SelectProps } from "antd";
import { SelectField, TooltipParagraph } from "components";
import { APPROVED_STATUS, LOG_VALID } from "constants/workLog";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { objMap } from "utils";
const { Item } = Form;
export const LogValidFilter = React.memo(() => {
	const { t } = useTranslation("workLog");

	const options = useMemo(
		() =>
			objMap(LOG_VALID, (value, key) => ({
				key,
				value,
				label: t(`logStatus.${key}`),
			})),
		[t]
	);
	return (
		<Item name="valid" label={t("logValid")}>
			<SelectField options={options} />
		</Item>
	);
});

export const StatusSelect = (props: Omit<SelectProps, "options">) => {
	const { t } = useTranslation("workLog");

	const options = useMemo(() => {
		return objMap(APPROVED_STATUS, (value, key) => ({
			key: value,
			value,
			label: t(`approvedStatusLabel.${key}`),
		}));
	}, [t]);
	return <SelectField testId="approved-status" options={options} {...props} />;
};

export const ApprovedStatusFilter = React.memo(() => {
	const { t } = useTranslation("workLog");

	return (
		<Item name="status" label={t("approvedStatus")}>
			<StatusSelect mode="multiple" />
		</Item>
	);
});

export const renderLongComment = (value?: string) => (
	<TooltipParagraph row={2} maxWidth="32rem">
		{value}
	</TooltipParagraph>
);
