import { Modal, message } from "antd";
import { translate } from "utils/translateUtils";

export const exportWithConfirm = (
	getFile: string | (() => string) | (() => Promise<string>),
	name: string = "export"
) => {
	Modal.confirm({
		closable: true,
		maskClosable: true,
		title: translate("msg.exportInfo"),
		cancelButtonProps: { hidden: true },
		getContainer: ".fullscreen",
		onOk: async () => {
			try {
				let file;
				if (typeof getFile === "function") {
					file = await getFile();
				} else {
					file = getFile;
				}
				const newWindow = window.open();
				const a = newWindow?.document.createElement("a");
				if (a) {
					a.href = file;
					a.setAttribute("download", name);
					a.click();
					a.remove();
					newWindow!.close();
					message.success(
						translate("msg.success", { action: translate("function.export") })
					);
				}
			} catch (error) {
				console.log("error :", error);
				message.error(
					translate("msg.exportFailed", { reason: (error as any).message })
				);
			}
		},
	});
};
