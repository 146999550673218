import moment from "moment";
import { FORMAT_DATE, PAGE_SIZES } from "./format";

const today = moment();
const DEFAULT_PAST_WEEK = 2;
export const SORT = {
	DESC: "DESC",
	ASC: "ASC",
} as const;

export const DEFAULT_PROJECT_KEY = "";
export const ALL_PARAM = "ALL";

export const DEFAULT_PAGINATION_PARAMS = {
	pageNo: 1,
	pageSize: PAGE_SIZES[0],
};

export const REQUIRED_PROJECT = {
	projectKey: undefined,
};

export const DEFAULT_FILTER_FORM_VALUES = {
	time: [moment(today).add(-DEFAULT_PAST_WEEK, "week"), today],
} as const;

export const DEFAULT_API_PARAMS = {
	fromDate: DEFAULT_FILTER_FORM_VALUES.time[0].format(FORMAT_DATE),
	toDate: DEFAULT_FILTER_FORM_VALUES.time[1].format(FORMAT_DATE),
	...DEFAULT_PAGINATION_PARAMS,
};

export const REQUIRED_WORKLOG_PARAMS = {
	projectKey: DEFAULT_PROJECT_KEY,
};

export const DEFAULT_WORKLOG_PARAMS = {
	...DEFAULT_API_PARAMS,
	sort: SORT.DESC,
	column: "loggedDate",
};

export const DEFAULT_FREE_RESOURCE_PARAMS = {
	...DEFAULT_PAGINATION_PARAMS,
	fromDate: today.format(FORMAT_DATE),
	toDate: moment(today).add(1, "month").format(FORMAT_DATE),
};
export const DEFAULT_THREE_RESOURCE_PARAMS = {
	...DEFAULT_PAGINATION_PARAMS,
	status: 1,
	fromDate: moment().startOf('quarter').format(FORMAT_DATE),
	toDate: moment().endOf('quarter').format(FORMAT_DATE),
}
export const DEFAULT_PLAN_ALLOCATION_PARAMS = {
	...DEFAULT_PAGINATION_PARAMS,
	fromDate: today.format(FORMAT_DATE),
	toDate: moment(today).add(2, "week").format(FORMAT_DATE),
};
