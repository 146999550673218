import {
	combineDateNames,
	durationNames,
	names,
} from "pages/Plan/General/constants";
import { translationKey } from "translations/utils";
import { effortNames, billableFormNames } from "translations/keys/plan";
import { memberMetricNames } from "translations/keys/memberMetrics";

const form = {
	validateMsg: {
		commonRequired: "This field is required",
		required: "{{name}} is required",
		range: "{{name}} is between {{min}} and {{max}}",
		invalidDate: "Invalid date",
		invalidValue: "Invalid value",
	},
	planGeneral: {
		[names.name]: "Name",
		[names.url]: "Url",
		[names.avatar]: "Avatar",
		[names.key]: "Key",
		[names.syncJira]: "Sync from Jira",
		[names.status]: "Status",
		[combineDateNames.planDate]: "Plan Date",
		[combineDateNames.actualDate]: "Actual Date",
		[durationNames.description]: translationKey("description"),
		[names.children]: {
			[durationNames.pmUsername]: translationKey("pm"),
			[combineDateNames.planDate]: "Plan Date",
			[combineDateNames.actualDate]: "Actual Date",
			[durationNames.businessRank]: "Business rank",
			[durationNames.budget]: translationKey("budget"),
			[combineDateNames.budgetDate]: `${translationKey("budget")} date`,
			[durationNames.adjustBudgets]: {
				[durationNames.adjustBudget]: `Adjust ${translationKey("budget")}`,
				[combineDateNames.adjustBudgetDate]: `Adjust ${translationKey(
					"budget"
				)} date`,
			},
			[durationNames.description]: translationKey("description"),
		},
		msg: {
			invalidNumber: "Invalid number"
		},
	},
	planAllocation: {
		[effortNames.fullName]: translationKey("member"),
		[effortNames.role]: "Role in project",
		[effortNames.time]: translationKey("function.time"),
		[effortNames.position]: translationKey("position"),
		[effortNames.rank]: translationKey("rank"),
		[effortNames.worked]: translationKey("worked"),
	},
	planBillable: {
		[billableFormNames.username]: translationKey("member"),
		[billableFormNames.time]: translationKey("function.time"),
		[billableFormNames.position]: translationKey("position"),
		[billableFormNames.worked]: translationKey("worked"),
		[billableFormNames.rank]: translationKey("rank"),
		[billableFormNames.contractType]: "Contract Type",
		[billableFormNames.note]: "Note",
	},
	memberMetrics: {
		[memberMetricNames.description]: "Description",
		[memberMetricNames.min]: "Min",
		[memberMetricNames.max]: "Max",
		[memberMetricNames.default]: "Default Value",
		[memberMetricNames.status]: "Status",
	},
};

export default form;
