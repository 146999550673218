import { memberMetricNames } from "translations/keys/memberMetrics";

export const teamNames = {
	...memberMetricNames,
	id: "id",
	name: "metric",
	weight: "weight",
	range: "valueRange",
} as const;

export const roleNames = {
	...memberMetricNames,
	id: "id",
	role: "roleInProject",
	key: "roleFactorKey",
	range: "factorRange",
} as const;

export type TeamMetrics = {
	[teamNames.id]: number;
	[teamNames.name]: string;
	[teamNames.weight]: string;
	[teamNames.description]: string;
	[teamNames.range]: string;
	[teamNames.min]: number;
	[teamNames.max]: number;
	[teamNames.default]: number;
	[teamNames.status]: number;
};

export type RoleFactor = {
	[roleNames.id]: number;
	[roleNames.role]: string;
	[roleNames.key]: string;
	[roleNames.description]: string;
	[roleNames.range]: string;
	[roleNames.min]: number;
	[roleNames.max]: number;
	[roleNames.default]: number;
	[roleNames.status]: number;
};

export type TMemberMetricsResponse = {
	teamEvaluationMetricsResponses: TeamMetrics[];
	roleFactorMetricsResponses: RoleFactor[];
};
