import { CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { TooltipParagraph } from "components";
import { FORMAT_DATE } from "constants/format";
import { STANDARD_HOUR_PER_DAY } from "constants/others";
import { theme } from "layout/theme";
import moment, { Moment } from "moment";
import { freeResourceNames } from "translations/keys/plan";
import { ETableCols, FreeResourceDay, FreeResourceRecord } from "types";
import FullNameAndUsername from "../components/FullNameAndUsername";
import { AvatarImg } from "../components/SmallComponents";
import { createPlanCol, planTranslate } from "../utils";

const { primary: primaryColor } = theme.color;

const createFixCol: typeof createPlanCol = (name, other = {}, tab) =>
	createPlanCol(
		name,
		{
			...other,
			fixed: "left",
		},
		tab
	);

const ALLOCATED_HOUR_FULL = `${planTranslate(
	freeResourceNames.allocatedHour,
	"free"
)} / ${planTranslate("totalContractWorkingHour", "free")}`;

const cols: ETableCols<FreeResourceRecord> = [
	{
		dataIndex: freeResourceNames.avatar,
		align: "center",
		width: "48px",
		fixed: "left",
		render: (value) => value && <AvatarImg src={value} alt="avatar" />,
	},
	createFixCol(freeResourceNames.username, {
		align: "left",
		sorter: true,
		render: (value: string, record) => (
			<FullNameAndUsername
				fullName={record[freeResourceNames.fullName]}
				userName={value}
			/>
		),
	}),
	createFixCol(freeResourceNames.position, {
		sorter: true,
	}),
	createFixCol(freeResourceNames.rank, {
		sorter: true,
	}),
	createFixCol(freeResourceNames.memberType, undefined, "free"),
	createFixCol(freeResourceNames.contractType),
	createFixCol(
		freeResourceNames.allocatedHour,
		{
			title: (translatedTitle: string) => (
				<Tooltip title={ALLOCATED_HOUR_FULL}>{translatedTitle}</Tooltip>
			),
			className: "allocated--free-resource",
		},
		"free"
	),
];

const findDate = (days: FreeResourceDay[], date: Moment) =>
	days.find(
		(day) => day[freeResourceNames.daysName.day] === date.format(FORMAT_DATE)
	)?.[freeResourceNames.daysName.value];

export const createColsWithDays = (
	fromDate?: string,
	toDate?: string
): typeof cols => {
	const newCols = [...cols];
	if (fromDate && toDate) {
		const startDate = moment(fromDate, FORMAT_DATE);
		const endDate = moment(toDate, FORMAT_DATE);
		for (; startDate <= endDate; startDate.add(1, "day")) {
			const copyDate = moment(startDate);
			const dayTitle = copyDate.format("DD/MM");
			const isWeekend = [6, 7].includes(copyDate.isoWeekday());
			const isPast = copyDate.endOf("day").isBefore(moment());

			const pastColor = "#808080";

			const getColorTitle = () => {
				if (isWeekend) return primaryColor;
				if (isPast) return pastColor;
				return "";
			};

			newCols.push({
				title: dayTitle,
				onHeaderCell: () => ({
					style: {
						color: getColorTitle(),
					},
				}),
				dataIndex: freeResourceNames.days,
				align: "center",
				className:
					"day--free-resource" +
					(isWeekend ? " day-weekend--free-resource" : ""),
				render: (days: FreeResourceDay[]) => {
					const value = findDate(days, copyDate);
					if (value === STANDARD_HOUR_PER_DAY)
						return <CheckOutlined style={{ color: primaryColor }} />;
					return (
						<span style={{ color: isPast ? pastColor : "" }}>{value}</span>
					);
				},
			});
		}
	}

	newCols.push(
		createPlanCol(
			freeResourceNames.note,
			{
				fixed: "right",
				align: "left",
				width: 200,
				render: (value: string) => <TooltipParagraph value={value} row={1} />,
			},
			"free"
		)
	);

	return newCols;
};
