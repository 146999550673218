import { map } from "lodash";
import { Primitive } from "types";

export const checkPrimitive = (value: any): value is Primitive => {
	return ["string", "number", "boolean", "undefined"].includes(typeof value);
};

//check if value is not null or undefined
export function checkNotNull<T>(value?: T): value is NonNullable<T> {
	return !(value === null || value === undefined);
}

export function safeParse<ReturnType>(
	jsonValue: string | null | undefined
): ReturnType | undefined;
export function safeParse<ReturnType>(
	jsonValue: string | null | undefined,
	defaultValue: ReturnType
): ReturnType;
export function safeParse<ReturnType>(
	jsonValue: string | null | undefined,
	defaultValue?: ReturnType
): ReturnType | undefined {
	if (jsonValue) {
		try {
			return (JSON.parse(jsonValue) as ReturnType) ?? defaultValue;
		} catch (error) {
			console.error("error when trying to parse ", jsonValue, error);
		}
	}
	return defaultValue;
}

export const objMap: <TObject extends object, TResult>(
	collection: TObject | null | undefined,
	iteratee: (
		value: TObject[keyof TObject],
		key: keyof TObject,
		collection: TObject
	) => TResult
) => TResult[] = map;

export const concatString = (...strings: (Primitive | Primitive[])[]) => {
	let result = "";
	let startIndex = 0;
	strings.forEach((value, i) => {
		if (checkNotNull(value)) {
			if (i > startIndex) {
				result += "-";
			}
			if (Array.isArray(value)) {
				result += value.join("_");
			} else {
				result += value + "";
			}
		} else {
			startIndex++;
		}
	});
	return result.replace(/ /g, "-");
};
