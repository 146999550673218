import { createSlice } from "@reduxjs/toolkit";

type CommonState = {
	permissionCodes: string[];
};

const initialState: CommonState = {
	permissionCodes: [],
};

const commonSlice = createSlice({
	name: "common",
	initialState,
	reducers: {
		setPermissionCodes: (state, { payload }) => {
			state.permissionCodes = payload;
		},
	},
});

export const { setPermissionCodes } = commonSlice.actions;

export default commonSlice.reducer;
