import { DefaultOptionType } from "antd/lib/select";
import {
	FormItem,
	SelectField,
	SelectFieldProps,
	SelectMode,
} from "components/FormFields";
import { IFormItemProps } from "components/FormFields/FormItem";
import { useFetch, UseFetchParams } from "hooks";
import { isFunction } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DropdownRes } from "types";

export type TApiSelectData = {
	key: string | number;
	name: string | number;
};

type ExtendIItemProps<T extends keyof IFormItemProps> = Pick<
	IFormItemProps,
	T
> & {
	fieldProps?: Omit<IFormItemProps, T | "children">;
};

type GetOptionValue<DataType> =
	| keyof DataType
	| ((item: DataType, index: number) => string | number);

export type GetOption<DataType> = {
	[key in keyof DefaultOptionType]: GetOptionValue<DataType>;
} & {
	disabled?: (item: DataType, index: number) => boolean;
};

export interface IApiSelectProps<
	DataType extends DropdownRes = TApiSelectData,
	M extends SelectMode = undefined,
	Res = DataType[]
> extends Omit<
			SelectFieldProps<
				M,
				DataType[keyof DataType],
				DefaultOptionType & DataType
			>,
			"options" | "children" | ""
		>,
		ExtendIItemProps<"name" | "label" | "rules" | "noStyle">,
		UseFetchParams {
	preHandleData?: (data: Res) => DataType[];
	optionKeys?: GetOption<DataType>;
}

function ApiSelect<
	DataType extends DropdownRes = TApiSelectData,
	M extends SelectMode = undefined,
	Res = DataType[]
>({
	mode,
	name,
	noStyle,
	rules,
	label,
	fieldProps,
	url,
	params,
	refetchDep,
	requiredParams,
	preHandleData = (data) => data as unknown as DataType[],
	optionKeys = {
		label: "name",
		value: "key",
	},
	...props
}: IApiSelectProps<DataType, M, Res>) {
	const { t } = useTranslation();

	const { data, loading } = useFetch<{}, Res>({
		url,
		params,
		refetchDep,
		requiredParams,
	});

	const handledData = useMemo(() => {
		if (data) {
			return preHandleData(data);
		} else {
			return [];
		}
		//missing preHandledData
		// eslint-disable-next-line
	}, [data]);
	const options = useMemo(() => {
		return handledData?.map((item, index) => {
			const option = { ...item } as any;
			for (const key in optionKeys) {
				if (Object.prototype.hasOwnProperty.call(optionKeys, key)) {
					const getOption = optionKeys[key];
					option[key] = isFunction(getOption)
						? getOption(item, index)
						: item[getOption];
				}
			}
			option.key ??= option.value;
			return option;
		});
	}, [handledData, optionKeys]);

	return (
		<FormItem
			name={name || ""}
			rules={rules}
			label={label}
			noStyle={noStyle}
			{...fieldProps}
		>
			<SelectField
				mode={mode}
				loading={loading}
				options={options}
				placeholder={t("function.all")}
				{...props}
			/>
		</FormItem>
	);
}

export { ApiSelect };
