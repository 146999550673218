import styled from "@emotion/styled";
import EyeMenu from "./EyeMenu";

const StyledEyeMenu = styled(EyeMenu)`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.backgroundColor};
	border-radius: 2px;
	min-width: 10rem;
	padding: 0;
	border: 1px solid #f1f1f1;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.07),
		0 0 20px rgba(0, 0, 0, 0.05);
	& .eye-menu__row {
		display: flex;
		align-items: center;
	}
	& .eye-menu__label {
		text-transform: capitalize;
		margin-left: 0.5rem;
		cursor: pointer;
		flex: 1;
	}
`;

export default StyledEyeMenu as typeof EyeMenu;
