import { SelectField } from "components";
import {
	CHART_ADMIN_ALL_MEMBERS,
	MY_CHART_SUMMARY,
	urlGetLogtimeUser,
} from "constants/api";
import { REQUIRED_PROJECT } from "constants/apiParams";
import { LOGTIME_UNIT } from "constants/setting";
import { useFetchWithUrlParams, useUrlParams } from "hooks";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TAllMemberLogtimeData } from "types";
import { addLogtimeUnit, formatNum, upperFirstLetter } from "utils";
import {
	ChartLayout,
	ChartWithQuery,
	IChartLayoutProps,
} from "../components/ChartLayout";
import MembersInfoDropdown from "../components/MembersInfoDropdown";
import { STATUS_COLOR } from "../constants";
import {
	combineProjectDetailDataset,
	findAndAdd,
	getProjectSummary,
} from "../utils";

function AllMember() {
	const { t } = useTranslation(["translation", "workLog"]);
	const { urlParams, setUrlParams } = useUrlParams();
	const { data, loading } = useFetchWithUrlParams<{}, TAllMemberLogtimeData>({
		url: CHART_ADMIN_ALL_MEMBERS,
		requiredParams: REQUIRED_PROJECT,
	});

	const handledData = useMemo(() => {
		const ticketList: IChartLayoutProps["ticketList"] = [
			{
				title: t("workLog:statistic.logtime", {
					unit: LOGTIME_UNIT,
				}),
				data: formatNum(data?.totalLogtime),
				info: t("workLog:statistic.description.approved", {
					unit: LOGTIME_UNIT,
				}),
				color: STATUS_COLOR.APPROVED,
			},
			{
				title: addLogtimeUnit(t("translation:plan")),
				data: formatNum(data?.totalPlanTime),
				info: t("workLog:statistic.description.plan", {
					unit: LOGTIME_UNIT,
				}),
				color: STATUS_COLOR.PLAN,
			},
		];

		const chartData = {
			labels: [] as string[],
			datasets: [
				{
					label: upperFirstLetter(t("translation:plan")),
					data: [],
					backgroundColor: STATUS_COLOR.PLAN,
					stack: "Stack0",
				},
				{
					label: upperFirstLetter(t("translation:logtime")),
					data: [],
					backgroundColor: STATUS_COLOR.APPROVED,
					stack: "Stack1",
				},
			],
		};

		if (data?.logTimeByUsers) {
			const { labels, datasets } = chartData;
			for (const key in data.logTimeByUsers) {
				if (Object.prototype.hasOwnProperty.call(data.logTimeByUsers, key)) {
					const userIndex = labels!.push(key) - 1;
					const { loggedTime, planedTime } = data.logTimeByUsers[key];
					findAndAdd(datasets[0].data, userIndex, planedTime);
					findAndAdd(datasets[1].data, userIndex, loggedTime);
				}
			}
		}

		return {
			ticketList,
			chartData,
		};
	}, [data, t]);

	const member = useMemo(() => {
		return handledData.chartData.labels.includes(urlParams.username as string)
			? urlParams.username
			: undefined;
	}, [handledData.chartData.labels, urlParams.username]);

	const handleMemberChartData = useCallback(
		(data: any) => {
			return combineProjectDetailDataset(data, urlParams.groupBy as any);
		},
		[urlParams.groupBy]
	);

	return (
		<>
			<ChartLayout
				{...handledData}
				loading={loading}
				title={<MembersInfoDropdown />}
				options={{
					scales: {
						x: {
							title: {
								text: t("translation:member"),
							},
						},
					},
				}}
			/>
			<ChartWithQuery
				urlGetTicket={MY_CHART_SUMMARY}
				requiredParams={{
					...REQUIRED_PROJECT,
					username: member,
				}}
				urlGetData={urlGetLogtimeUser(member as string)}
				exportTitle={member as string}
				title={
					<SelectField
						options={handledData.chartData.labels.map((value) => ({
							value,
							label: value,
							key: value,
						}))}
						value={member as string}
						onChange={(value) => {
							setUrlParams({
								username: value,
							});
						}}
						allowClear={false}
						defaultActiveFirstOption
					/>
				}
				handleTicketData={getProjectSummary}
				handleChartData={handleMemberChartData}
			/>
		</>
	);
}

export default AllMember;
