import { ProjectSelect, SearchInput, TableWithQuery } from "components";
import CommonFilterForm from "components/CommonFields/CommonFilterForm";
import {
	DROPDOWN_MY_PROJECT,
	MY_WORKLOG,
	MY_WORKLOG_EXPORT,
} from "constants/api";
import {
	DEFAULT_WORKLOG_PARAMS,
	REQUIRED_WORKLOG_PARAMS,
} from "constants/apiParams";
import Layout from "layout";
import { useTranslation } from "react-i18next";
import { ApprovedStatusFilter } from "../components";
import { MY_WORKLOG_COLUMNS } from "../constants";

function MyWorkLog() {
	const { t } = useTranslation("workLog");

	return (
		<Layout currentPageLabel={t("me")}>
			<div className="filter">
				<CommonFilterForm>
					<ProjectSelect projectUrl={DROPDOWN_MY_PROJECT} />
					{/* <LogValidFilter /> */}
					<ApprovedStatusFilter />
					<SearchInput placeholder="myWorklog" />
				</CommonFilterForm>
			</div>
			<TableWithQuery
				testId="My-Worklog"
				url={MY_WORKLOG}
				defaultParams={DEFAULT_WORKLOG_PARAMS}
				requiredParams={REQUIRED_WORKLOG_PARAMS}
				columns={MY_WORKLOG_COLUMNS}
				exportParams={{
					url: MY_WORKLOG_EXPORT,
					filename: t("me"),
				}}
			/>
		</Layout>
	);
}

export default MyWorkLog;
