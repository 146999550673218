import { FORMAT_TIME } from "constants/format";
import moment from "moment";
import { TPlanGeneral } from "types/plan/project";
import { combineDateNames, durationNames, names } from "../constants";

export const viewDate = (dates: any[]) => {
	const results = [];
	if (dates?.[0]) {
		results.push(dates[0].format(FORMAT_TIME.DATE));
	}
	if (dates?.[1]) {
		results.push(dates[1].format(FORMAT_TIME.DATE));
	}
	if (results.length === 0) return undefined;
	return results.join(" - ");
};

export const handleDateForObj = (
	obj: any,
	arrayKey: string,
	firstKey: string,
	secondKey: string
) => {
	const firstValue = obj[arrayKey]?.[0];
	const secondValue = obj[arrayKey]?.[1];
	if (firstValue) {
		obj[firstKey] = firstValue.format(FORMAT_TIME.DATE);
	}
	if (secondValue) {
		obj[secondKey] = secondValue.format(FORMAT_TIME.DATE);
	}
	delete obj[arrayKey];
};

export const stringToMoment = (dateString?: string | null) =>
	dateString ? moment(dateString, FORMAT_TIME.DATE) : undefined;

export const dateStringToFormRange = <Obj extends Record<string, any>>(
	obj: Obj,
	firstKey: keyof Obj,
	secondKey: keyof Obj
) => [stringToMoment(obj[firstKey]), stringToMoment(obj[secondKey])];

export const preHandleGeneralValues = (values: TPlanGeneral) => {
	const postData = { ...values };
	//remove planDate and actualDate
	delete (postData as any)[combineDateNames.actualDate];
	delete (postData as any)[combineDateNames.planDate];

	postData[names.children] = values[names.children].map((duration) => {
		handleDateForObj(
			duration,
			combineDateNames.planDate,
			durationNames.planStart,
			durationNames.planEnd
		);
		handleDateForObj(
			duration,
			combineDateNames.actualDate,
			durationNames.actualStart,
			durationNames.actualEnd
		);
		delete (duration as any)[combineDateNames.budgetDate];
		duration[durationNames.adjustBudgets] = (
			duration[durationNames.adjustBudgets] || []
		).filter((adjustBudget) => {
			if (adjustBudget?.[durationNames.id]) {
				return false;
			} else {
				return adjustBudget;
			}
		});
		const result = {
			...duration,
			[durationNames.id]: duration?.[durationNames.id] || null,
		};
		return result;
	});
	return postData;
};

export type SubmitValues = ReturnType<typeof preHandleGeneralValues>;
