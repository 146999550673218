import styled from "@emotion/styled";
import { Form, FormInstance, Select } from "antd";
import { DateRangeField, FilterForm, ProjectSelect } from "components";
import {
	changeTimeToQuery,
	searchParamsToFormValue,
} from "components/CommonFields/CommonFilterForm";
import { DROPDOWN_MY_PROJECT } from "constants/api";
import { DEFAULT_FILTER_FORM_VALUES } from "constants/apiParams";
import { FORMAT_DATE, FORMAT_TIME, TKeyFormat } from "constants/format";
import { useUrlParams } from "hooks";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FilterFormValue } from "types";
import { TAB_MENU } from "..";
import { DEFAULT_CHART_PARAMS, TIME_OPTIONS } from "../constants";

const { Item } = Form;
interface ProjectChartFilterFormValues extends FilterFormValue {
	groupBy: TKeyFormat;
	projectKey?: string;
}

const SelectTypeItem = styled(Item)`
	&& {
		min-width: 5rem;
	}
`;
const SelectDatePicker = styled(Select)`
	.ant-select-selector {
		padding: 0 !important;
	}
`;
function ProjectChartFilter() {
	const formRef = useRef<FormInstance<ProjectChartFilterFormValues>>(null);
	const { tab, urlParams, search } = useUrlParams();
	const { t } = useTranslation("translation", {
		keyPrefix: "function",
	});

	const initValueFunc = useCallback(() => {
		if (formRef.current) {
			if (!search) {
				if (tab === "me") {
					formRef.current.resetFields(["projectKey"]);
				}
				formRef.current.setFieldsValue(DEFAULT_CHART_PARAMS as any);
			} else {
				formRef.current.setFieldsValue(searchParamsToFormValue(search));
			}
		}
		// eslint-disable-next-line
	}, [tab, search]);

	/**
	 * @technicalDebt form need to wait for `projectKey` has value before submit
	 */
	useEffect(() => {
		setTimeout(() => {
			if (!urlParams.projectKey) {
				formRef.current?.submit();
			}
		}, 500);
		// eslint-disable-next-line
	}, [tab]);

	const projectProps = useMemo(
		() =>
			tab === TAB_MENU[0].key
				? { projectUrl: DROPDOWN_MY_PROJECT }
				: {
						mode: undefined,
						allowClear: false,
						defaultActiveFirstOption: true,
				  },
		[tab]
	);

	return (
		<FilterForm
			className="filter"
			ref={formRef}
			initValue={initValueFunc}
			resetPagination={false}
			preHandleParams={(values) => {
				return changeTimeToQuery(values, (handledValues) => {
					if (handledValues.fromDate) {
						handledValues.fromDate = moment(handledValues.fromDate, FORMAT_DATE)
							.startOf(handledValues.groupBy)
							.format(FORMAT_DATE);
					} else {
						handledValues.fromDate = DEFAULT_FILTER_FORM_VALUES.time[0]
							.startOf(handledValues.groupBy)
							.format(FORMAT_DATE);
					}
					if (handledValues.toDate) {
						handledValues.toDate = moment(handledValues.toDate, FORMAT_DATE)
							.endOf(handledValues.groupBy)
							.format(FORMAT_DATE);
					} else {
						handledValues.toDate = DEFAULT_FILTER_FORM_VALUES.time[1]
							.endOf(handledValues.groupBy)
							.format(FORMAT_DATE);
					}
					return handledValues;
				});
			}}
		>
			<SelectTypeItem label={t("time")} name="groupBy">
				<SelectDatePicker
					style={{ padding: 0 }}
					defaultActiveFirstOption
					options={TIME_OPTIONS.map((value) => ({
						value,
						label: value.toLocaleLowerCase(),
					}))}
					bordered={false}
					data-testid="group-by-select"
				/>
			</SelectTypeItem>
			<Item noStyle shouldUpdate>
				{({ getFieldValue }) => {
					const type = getFieldValue("groupBy") as keyof typeof FORMAT_TIME;
					return (
						<DateRangeField
							label=""
							picker={type?.toLocaleLowerCase() as any}
							format={FORMAT_TIME[type]}
							testId="filter-time"
						/>
					);
				}}
			</Item>

			<ProjectSelect {...projectProps} />
		</FilterForm>
	);
}

export default ProjectChartFilter;
