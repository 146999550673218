import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as LuigiClient from "@luigi-project/client";
import { BrowserRouter } from "react-router-dom";
import { store } from "./appStore";
import { Provider } from "react-redux";
import "./translations";
import AppThemeProvider from "layout/theme";
import moment from "moment";
window["LuigiClient"] = LuigiClient;

moment.locale("en", {
	week: {
		dow: 1,
	},
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AppThemeProvider>
				<Provider store={store}>
					<App />
				</Provider>
			</AppThemeProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
