import { css, Global } from "@emotion/react";
import { theme } from "./theme";

function GlobalStyles() {
	return (
		<Global
			styles={css`
				//ant typography
				p,
				.ant-typography,
				.ant-typography + h1.ant-typography,
				.ant-typography + h2.ant-typography,
				.ant-typography + h3.ant-typography,
				.ant-typography + h4.ant-typography,
				.ant-typography + h5.ant-typography,
				.ant-typography p {
					margin: 0;
				}
				.ant-card-head-title {
					text-transform: capitalize;
				}

				.ant-select-selection-item,
				.ant-select-item-option-content {
					&::first-letter {
						text-transform: uppercase;
					}
				}

				//ant form item
				.ant-form-item {
					margin-bottom: 15px;
					.ant-col.ant-form-item-label {
						padding-bottom: 2px;
					}
				}
				.ant-form-item.submit-btn {
					margin-bottom: 0;
				}
				.ant-form-item.ant-form-item-has-error {
					margin-bottom: 5px;
				}

				.ant-form-item-margin-offset {
					display: none;
				}

				.ant-form-item-explain-error::first-letter {
					text-transform: capitalize;
				}

				.ant-message-custom-content {
					text-transform: capitalize;
				}

				.ant-form-item-label > label {
					&::first-letter {
						text-transform: uppercase;
					}
					&.ant-form-item-required:not(
							.ant-form-item-required-mark-optional
						)::before {
						content: "";
						display: none;
					}
				}
				.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
					&&::after {
						content: "*";
						display: inline-block;
						margin-left: 4px;
						color: ${theme.color.danger};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
					}
				}

				textarea.ant-input {
					overflow-y: auto !important; //fix bug auto-size textarea
				}

				.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
					&&::after {
						content: "*";
						display: inline-block;
						margin-left: 4px;
						color: ${theme.color.danger};
						font-size: 14px;
						font-family: SimSun, sans-serif;
						line-height: 1;
					}
				}

				textarea.ant-input {
					overflow-y: auto !important; //fix bug auto-size textarea
				}

				//utils class
				.mb-0 {
					margin-bottom: 0;
				}
				.cursor-pointer {
					cursor: pointer;
				}
			`}
		/>
	);
}

export default GlobalStyles;
