import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Translation } from "translations";
import { ETableCol, ETableCols } from "types";
import { ChildrenPaths, TranslateKey } from "types/translation";

type TranslationParentsKey = TranslateKey<"onlyParents">;

export interface TColsTranslation<
	DataType extends object,
	DataIndexType extends string
> extends Omit<ETableCol<DataType>, "dataIndex"> {
	dataIndex?: DataIndexType | Omit<string, DataIndexType>;
	translationKey?: TranslateKey<"onlyLeaves">;
}

function useCreateCols<
	DataType extends object,
	PrePath extends TranslationParentsKey = never
>(
	prePath: PrePath,
	columns: Array<
		TColsTranslation<
			DataType,
			ChildrenPaths<Translation, PrePath, "onlyLeaves">
		>
	>
): ETableCols<DataType> {
	const { t } = useTranslation();
	return useMemo<ETableCols<DataType>>(() => {
		//@ts-ignore
		return columns.map(({ dataIndex, translationKey, title, ...col }) => {
			return {
				title: title ?? t(translationKey ?? `${prePath}.${dataIndex}`),
				dataIndex: dataIndex as any,
				...col,
			};
		});
	}, [t, columns, prePath]);
}

export { useCreateCols };
