import { ReactNode } from "react";
import { Translation } from "translations";
import { ETableCol } from "types";
import { translate } from "utils";

type ExtractObj<Obj extends object> = {
	[key in keyof Obj]: Obj[key] extends string ? never : key;
};

type PlanObjKey = ExtractObj<Translation["plan"]>[keyof ExtractObj<
	Translation["plan"]
>];

type PlanKey = PlanObjKey | "";

export const planTranslate = <TabKey extends PlanKey>(
	key: string,
	tab: TabKey = "" as any
) => translate(`plan:${tab ? tab + "." : tab}${key}`);

export const createPlanCol = <DataType>(
	name: string,
	{
		title,
		...other
	}:
		| ETableCol<DataType>
		| {
				title?: (title: string) => ReactNode;
		  } = {},
	tab: PlanKey = "allocation"
): ETableCol<DataType> => {
	const translatedTitle = planTranslate(name, tab);
	let newTitle: ReactNode = translatedTitle;

	if (title) {
		if (typeof title === "function") {
			newTitle = title(translatedTitle);
		} else {
			newTitle = title;
		}
	}

	return {
		title: newTitle,
		dataIndex: name,
		align: "center",
		...other,
	};
};
