import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip, TooltipProps } from "antd";

function InfoTooltip(props: TooltipProps) {
	return (
		<Tooltip {...props}>
			<InfoCircleOutlined />
		</Tooltip>
	);
}

export { InfoTooltip };
