import { Table } from "antd";
import {
	Accordion,
	AddBtn,
	ContentContainer,
	ETable,
	ProjectQuerySelect,
	StatusTag,
} from "components";
import { GET_MEMBER_METRICS } from "constants/api";
import { useAppSelector, useFetchWithUrlParams, useUrlParams } from "hooks";
import { useAddFunc, useEditFunc } from "hooks/useEditFunc";
import CustomLayout from "layout";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ETableCol } from "types";
import { addPercent } from "utils";
import { TABS_PROPS, TAB_KEYS } from "../constants";
import { SummaryCell, renderLongText } from "./components/Other";
import RoleFactorFormForm from "./components/RoleFactorForm";
import TeamMetricsForm from "./components/TeamMetricsForm";
import {
	RoleFactor,
	TMemberMetricsResponse,
	TeamMetrics,
	roleNames,
	teamNames,
} from "./types";

const statusRender = (status: boolean | number) => <StatusTag value={status} />;

function MemberMetrics() {
	const { t } = useTranslation("metrics");
	const teamT = useCallback((key: string) => t(`team.${key}`), [t]);
	const { t: roleT } = useTranslation("metrics", {
		keyPrefix: "role",
	});
	const { fetchTable } = useAppSelector((state) => state.common);
	const { tab } = useUrlParams();
	const isProjectTab = tab === TAB_KEYS[1];

	const { data, loading } = useFetchWithUrlParams<{}, TMemberMetricsResponse>({
		url: GET_MEMBER_METRICS,
		refetchDep: fetchTable,
	});

	const teamColumns = useMemo<ETableCol<TeamMetrics>[]>(
		() => [
			{
				dataIndex: teamNames.name,
				title: teamT("name"),
				width: 150,
			},
			{
				dataIndex: teamNames.weight,
				title: teamT("weight"),
				width: 100,
				render: addPercent,
			},
			{
				dataIndex: teamNames.description,
				title: teamT("description"),
				render: renderLongText,
			},
			{
				dataIndex: teamNames.range,
				title: teamT("valueRange"),
				width: 200,
			},
			{
				dataIndex: teamNames.default,
				title: teamT("default"),
				width: 100,
			},
			{
				dataIndex: teamNames.status,
				title: teamT("status"),
				width: 100,
				render: statusRender,
			},
		],
		[teamT]
	);
	const roleColumns: ETableCol<RoleFactor>[] = useMemo(
		() => [
			{
				dataIndex: roleNames.role,
				title: roleT("role"),
				render: renderLongText,
			},
			{
				dataIndex: roleNames.key,
				title: roleT("key"),
				render: renderLongText,
			},
			{
				dataIndex: roleNames.description,
				title: roleT("description"),
				render: renderLongText,
			},
			{
				dataIndex: roleNames.range,
				title: roleT("factorRange"),
				width: 200,
			},
			{
				dataIndex: roleNames.default,
				title: roleT("default"),
				width: 100,
			},
			{
				dataIndex: roleNames.status,
				title: roleT("status"),
				width: 100,
				render: statusRender,
			},
		],
		[roleT]
	);

	const { handleEdit } = useEditFunc();
	const { handleAdd } = useAddFunc();
	const [keyForm, setKeyForm] = useState<"teamMetrics" | "roleFactor" | null>(
		null
	);

	return (
		<CustomLayout currentPageLabel={t("memberMetrics")} tabProps={TABS_PROPS}>
			<ContentContainer>
				{keyForm === "teamMetrics" && <TeamMetricsForm />}
				{keyForm === "roleFactor" && (
					<RoleFactorFormForm isProjectTab={isProjectTab} />
				)}
				{isProjectTab && <ProjectQuerySelect />}

				<Accordion
					style={{
						marginTop: 10,
					}}
					noPaddingX
					items={[
						{
							key: 1,
							title: teamT("panelTitle"),
							children: (
								<ETable<TeamMetrics>
									testId={teamT("panelTitle")}
									loading={loading}
									dataSource={data?.teamEvaluationMetricsResponses}
									columns={teamColumns}
									rowKey={teamNames.id}
									pagination={false}
									actionColProps={{
										onEdit: (record) => {
											setKeyForm("teamMetrics");
											handleEdit(record);
										},
										fixed: "right",
									}}
									summary={(data) => {
										const totalWeight: number = data.reduce(
											(prev, curr) => prev + +curr.weight,
											0
										);
										return (
											<Table.Summary.Row>
												<SummaryCell index={0}>{t("sum")}</SummaryCell>
												<SummaryCell index={1} colSpan={7}>
													{addPercent(totalWeight)}
												</SummaryCell>
											</Table.Summary.Row>
										);
									}}
								/>
							),
						},
						{
							key: 2,
							title: roleT("panelTitle"),
							children: (
								<ETable<RoleFactor>
									testId={roleT("panelTitle")}
									loading={loading}
									dataSource={data?.roleFactorMetricsResponses}
									columns={roleColumns}
									rowKey={teamNames.id}
									pagination={false}
									headerBtns={
										<AddBtn
											type="primary"
											onClick={() => {
												setKeyForm("roleFactor");
												handleAdd();
											}}
										/>
									}
									actionColProps={{
										onEdit: (record) => {
											setKeyForm("roleFactor");
											handleEdit(record);
										},
										fixed: "right",
									}}
								/>
							),
						},
					]}
				/>
			</ContentContainer>
		</CustomLayout>
	);
}

export default MemberMetrics;
