import { Tooltip, Typography } from "antd";
import { LOGTIME_UNIT } from "constants/setting";
import { TASK_TYPE_COLOR } from "constants/workLog";
import { TOptions } from "i18next";
import { ComponentProps } from "react";
import {
	DataProjectWorkLog,
	ETableCols,
	MyWorkLogData,
	TTypeOfTicket,
	WorkLogData,
} from "types";
import { translate } from "utils";
import { renderLongComment } from "../components";
const { Link } = Typography;

export const workLogT = (
	key: string,
	options?: string | TOptions | undefined
) => translate(`workLog:${key}`, options);

interface LinkTicKet
	extends Omit<ComponentProps<typeof Link>, "color" | "type"> {
	type: TTypeOfTicket;
	hoverTitle?: string;
}
const LinkTicket = ({ href, type, hoverTitle, ...props }: LinkTicKet) => {
	return (
		<Tooltip title={hoverTitle}>
			<Link
				style={{
					color: TASK_TYPE_COLOR[type],
				}}
				href={href}
				target="_blank"
				rel="noopener, noreferrer"
				{...props}
			/>
		</Tooltip>
	);
};

const numColWidth = "6.25rem";
const dateColWidth = "9.375rem";
const componentRe = /((?:.+,){2})(.+)/m;

const createCols = <DataType extends WorkLogData>(): ETableCols<DataType> => [
	{
		title: workLogT("component"),
		dataIndex: "component",
		width: dateColWidth,
		align: "center",
		render: (value) => {
			if (!value) {
				return "--";
			}
			const newValue = value.replaceAll(",", ", ");
			return (
				<Tooltip title={newValue}>
					{newValue.replace(componentRe, "$1...")}
				</Tooltip>
			);
		},
	},
	{
		title: translate("description"),
		dataIndex: "description",
		width: "15.675rem",
		render: renderLongComment,
	},
	{
		title: workLogT("worked", {
			unit: LOGTIME_UNIT,
		}),
		width: numColWidth,
		align: "center",
		dataIndex: "worked",
	},
	{
		title: workLogT("typeOfWork"),
		align: "center",
		width: numColWidth,
		dataIndex: "typeOfWork",
	},
	// {
	// 	title: workLogT("logValid"),
	// 	align: "center",
	// 	width: numColWidth,
	// 	dataIndex: "logValid",
	// 	render: (valid) => {
	// 		return (
	// 			typeof valid === "boolean" &&
	// 			translate("function." + (valid ? "yes" : "no"))
	// 		);
	// 	},
	// },
	{
		title: workLogT("approvedStatus"),
		align: "center",
		width: numColWidth,
		dataIndex: "approvedStatus",
	},
	{
		title: workLogT("date"),
		align: "center",
		width: dateColWidth,
		dataIndex: "loggedDate",
		render: (_, { date }) => date,
		sorter: true,
	},
	{
		title: translate("comment"),
		dataIndex: "comment",
		render: renderLongComment,
	},
];

const MY_WORKLOG_COLUMNS: ETableCols<MyWorkLogData> = createCols();

MY_WORKLOG_COLUMNS.splice(
	0,
	0,
	{
		title: translate("project"),
		align: "center",
		dataIndex: "projectKey",
		width: numColWidth,
		render: (value, { projectName }) => (
			<Tooltip title={projectName}>{value}</Tooltip>
		),
	},
	{
		title: translate("key"),
		dataIndex: "ticketKey",
		width: numColWidth,
		align: "center",
		render: (ticketKey, { typeOfTicket, linkTicket, ticketName }) => (
			<LinkTicket type={typeOfTicket} href={linkTicket} hoverTitle={ticketName}>
				{ticketKey}
			</LinkTicket>
		),
	}
);
const createProjectCols = () => {
	const projectWorkLogCols: ETableCols<DataProjectWorkLog> = createCols();
	projectWorkLogCols[1].dataIndex = "comment";
	projectWorkLogCols.splice(
		projectWorkLogCols.length - 2,
		0,
		{
			title: workLogT("assignee"),
			align: "center",
			width: numColWidth,
			dataIndex: "assignee",
		},
		{
			title: workLogT("memberLog"),
			width: numColWidth,
			align: "center",
			dataIndex: "memberLogWork",
		}
	);
	projectWorkLogCols.splice(0, 0, {
		title: translate("key"),
		align: "center",
		dataIndex: "ticketKey",
		width: numColWidth,
		render: (value, { typeOfTicket, linkIssue, ticketName }) => (
			<LinkTicket type={typeOfTicket} href={linkIssue} hoverTitle={ticketName}>
				{value}
			</LinkTicket>
		),
	});
	projectWorkLogCols[projectWorkLogCols.length - 1].dataIndex = "description";

	return projectWorkLogCols;
};

export { createProjectCols, MY_WORKLOG_COLUMNS };
