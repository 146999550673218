import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { BaseSelectRef } from "rc-select";
import React, { ReactElement, RefAttributes, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { concatString } from "utils";
import { SelectFieldProps, SelectMode } from "./typeUtils";

export type { SelectMode } from "./typeUtils";
export type { SelectFieldProps };

const SelectField = <
	M extends SelectMode,
	Data,
	Option extends DefaultOptionType
>(
	{
		children,
		mode,
		options,
		defaultActiveFirstOption,
		value,
		onChange,
		testId,
		...props
	}: SelectFieldProps<M, Data, Option>,
	ref?: React.Ref<BaseSelectRef>
) => {
	const { t } = useTranslation();
	const selectTestId = concatString(testId ?? props.id, "select");

	useEffect(() => {
		if (defaultActiveFirstOption && onChange && options?.[0] && !value) {
			onChange(options[0].value as any, options[0] as any);
		}
		// missing data, onChange and value
		//eslint-disable-next-line
	}, [options, defaultActiveFirstOption]);

	const handledValue = useMemo(() => {
		if (mode === "multiple") {
			if (Array.isArray(value)) {
				return value;
			} else if (value) {
				return [value];
			}
			return [];
		}
		return value;
	}, [value, mode]);

	return (
		<Select
			dropdownClassName={`${testId}-dropdown`}
			ref={ref}
			allowClear
			showArrow
			showSearch
			mode={mode}
			options={options}
			defaultActiveFirstOption={defaultActiveFirstOption}
			filterOption={(input, option) => {
				if (option) {
					return Boolean(
						(option?.label &&
							(option.label + "")
								.toLowerCase()
								.indexOf(input.replace(/\s/g, "").toLowerCase()) >= 0) ||
							(option?.value &&
								(option.value + "")
									.toLowerCase()
									.indexOf(input.replace(/\s/g, "").toLowerCase()) >= 0)
					);
				} else {
					return false;
				}
			}}
			maxTagCount={mode === "multiple" ? "responsive" : undefined}
			value={handledValue as any}
			onChange={onChange as any}
			placeholder={t("function.all")}
			data-testid={selectTestId}
			{...props}
		>
			{children}
		</Select>
	);
};

const forwardedSelect = React.forwardRef(SelectField) as <
	M extends SelectMode,
	DataType = any,
	Option extends DefaultOptionType = DefaultOptionType
>(
	props: SelectFieldProps<M, DataType, Option> & RefAttributes<BaseSelectRef>
) => ReactElement | null;
export default React.memo(forwardedSelect) as typeof forwardedSelect;
