import { DefaultOptionType } from "antd/lib/select";
import en from "translations/locales/en";
import { TKeySyncStatus, TSyncOptionsKeys } from "../types";

const syncOptions = ["Sync at 00:00", " and 12:00"];
const FREQUENCY_OPTIONS: DefaultOptionType[] = [];

for (let i = 0; i < syncOptions.length; i++) {
	const element = syncOptions[i];
	FREQUENCY_OPTIONS.push({
		label: i > 0 ? syncOptions[i - 1].concat(element) : element,
		value: i + 1,
	});
}

const SYNC_OPTIONS_KEYS = Object.keys(
	en.projectJiraSync.syncOptions
) as TSyncOptionsKeys[];

const SYNC_STATUS: {
	[key in TKeySyncStatus]: string;
} = {
	synced: "green",
	resynced: "magenta",
	
};

export { FREQUENCY_OPTIONS, SYNC_STATUS, SYNC_OPTIONS_KEYS };
