import { DEFAULT_FILTER_FORM_VALUES } from "constants/apiParams";
import { FORMAT_TIME } from "constants/format";
import { APPROVED_STATUS } from "constants/workLog";
import { KeyApprovedStatus, TApprovedStatus } from "types";

const TIME_OPTIONS: Array<keyof typeof FORMAT_TIME> = Object.keys(
	FORMAT_TIME
) as Array<keyof typeof FORMAT_TIME>;

const DEFAULT_CHART_PARAMS = {
	...DEFAULT_FILTER_FORM_VALUES,
	groupBy: TIME_OPTIONS[0],
};

const WORKLOG_STATUS: Record<
	TApprovedStatus,
	{
		index: number;
		color: string;
	}
> = {} as any;

const STATUS_COLOR: Record<TApprovedStatus | "PLAN", string> = {
	PLAN: "#47B5FF",
	APPROVED: "#165DFF",
	PENDING: "#F7BE16",
	REJECT: "#F05454",
};

let index = 1;
for (const key in APPROVED_STATUS) {
	if (Object.prototype.hasOwnProperty.call(APPROVED_STATUS, key)) {
		const newkey = key as KeyApprovedStatus;
		const element = APPROVED_STATUS[newkey];
		WORKLOG_STATUS[element] = {
			index: index++,
			color: STATUS_COLOR[element],
		};
	}
}

export { WORKLOG_STATUS, TIME_OPTIONS, STATUS_COLOR, DEFAULT_CHART_PARAMS };
