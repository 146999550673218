import {
	CommonFilterForm,
	PositionSelect,
	RangePickerProps,
	RankSelect,
	SearchInput,
	TableWithQuery,
} from "components";
import { FREE_RESOURCE, FREE_RESOURCE_EXPORT } from "constants/api";
import { DEFAULT_FREE_RESOURCE_PARAMS } from "constants/apiParams";
import { FORMAT_DATE } from "constants/format";
import { useAppSelector, useGetPermission, useUrlParams } from "hooks";
import { useEditFunc } from "hooks/useEditFunc";
import Layout from "layout";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { freeResourceNames } from "translations/keys/plan";
import { addLogtimeUnit } from "utils";
import { PERMISSION } from "constants/permissionMap";
import { HeaderInfoList } from "../components/SmallComponents";
import EditForm from "./components/EditForm";
import { createColsWithDays } from "./constants";
import "./styles.css";

const params = DEFAULT_FREE_RESOURCE_PARAMS;
type TRange = "start" | "end";

const UNIT = "month";
const disableAfter = (date: Moment, time: Moment) =>
	date > moment(time).add(1, UNIT);
const disableBefore = (date: Moment, time: Moment) =>
	date < moment(time).subtract(1, UNIT);

function FreeResource() {
	const { urlParams } = useUrlParams();
	const { t } = useTranslation("freeResource");
	const { handleEdit } = useEditFunc();
	const { tableResponse } = useAppSelector((state) => state.common);
	const [times, setTimes] = useState<{
		values?: [Moment, Moment];
		info?: TRange;
	}>({});
	const { permissions } = useGetPermission();
	const editPermission = permissions?.includes(PERMISSION.EDIT);

	const { fromDate, toDate } = urlParams;

	useEffect(() => {
		setTimes({
			values: [
				moment((fromDate as any) ?? params.fromDate, FORMAT_DATE),
				moment((toDate as any) ?? params.toDate, FORMAT_DATE),
			],
			info: "start",
		});
		// eslint-disable-next-line
	}, []);

	const disabledDate = useCallback(
		(date: Moment) => {
			const start = times?.values?.[0];
			const end = times?.values?.[1];
			const info = times?.info;
			let condition = false;
			if (start && info === "start") {
				condition ||= disableAfter(date, start);
				if (end) {
					condition ||= disableBefore(date, end);
				}
			}

			if (end && info === "end") {
				condition ||= disableBefore(date, end);
				if (start) {
					condition ||= disableAfter(date, start);
				}
			}

			return condition;
		},
		[times]
	);

	const onChangeRangePicker = useCallback<
		NonNullable<RangePickerProps["onCalendarChange"]>
	>((values, _, info) => {
		setTimes({ values, info: info?.range } as any);
	}, []);

	const onFocusRangePicker = useCallback<
		NonNullable<RangePickerProps["onFocus"]>
	>((e) => {
		const isStart = e.target.id === "time";
		setTimes((prev) => {
			return { ...prev, info: isStart ? "end" : "start" };
		});
	}, []);

	return (
		<Layout currentPageLabel={t("mainTitle")}>
			<div className="filter">
				<EditForm/>
				<CommonFilterForm
					datePickerProps={{
						allowEmpty: [false, false],
						disabledDate,
						onCalendarChange: onChangeRangePicker,
						onFocus: onFocusRangePicker,
					}}
					paramsHandler={(values) => {
						if (!values.fromDate) {
							values.fromDate = params.fromDate;
						}
						if (!values.toDate) {
							values.toDate = params.toDate;
						}
						return values;
					}}
				>
					<PositionSelect name={freeResourceNames.position} mode="multiple"/>
					<RankSelect name={freeResourceNames.rank} mode="multiple"/>
					<SearchInput placeholder="freeResource"/>
				</CommonFilterForm>
			</div>

			<TableWithQuery
				testId="Free-Resource-Report"
				url={FREE_RESOURCE}
				preHandleData={(data: any) => data?.data}
				columns={createColsWithDays(fromDate as string, toDate as string)}
				exportParams={{
					url: FREE_RESOURCE_EXPORT,
					filename: t("mainTitle"),
				}}
				rowKey={freeResourceNames.username}
				defaultParams={params}
				headerBtns={
					<HeaderInfoList
						data={[
							{
								label: t("members"),
								value: tableResponse?.totalMember,
								testId: "free-resource",
							},
							{
								label: t("freeEffort"),
								value: addLogtimeUnit(tableResponse?.freeEffort),
							},
						]}
					/>
				}
				requiredParams={{
					keyWord: "",
				}}
				actionColProps={
					editPermission
						? {
							onEdit: handleEdit,
							fixed: "right",
						}
						: undefined
				}
			/>
		</Layout>
	);
}

export default FreeResource;
