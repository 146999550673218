import { REMOVE_TAB_REGEX, TAB_NAME } from "constants/format";
import { pageSearch } from "localStorage";
import { useCallback, useEffect, useMemo } from "react";
import {
	NavigateOptions,
	useLocation,
	useSearchParams,
} from "react-router-dom";
import { objToUrlParams, urlParamsToObj } from "utils";

export const useUrlParams = (
	defaultParams?: Record<string, any>,
	tabName = TAB_NAME
) => {
	const { search } = useLocation();
	// eslint-disable-next-line
	const [_, setParams] = useSearchParams();

	const searchParams = useMemo(() => {
		const urlSearch = window.location.search;
		const fullParams = urlParamsToObj(urlSearch);
		const urlParams = { ...fullParams };
		delete urlParams?.[tabName];

		return {
			urlParams,
			search: urlSearch.replace(REMOVE_TAB_REGEX, ""),
			tab: fullParams?.[tabName],
			searchFull: urlSearch,
		};
		// eslint-disable-next-line
	}, [search]);

	useEffect(() => {
		if (!searchParams.search) {
			const defaultPassedParams = searchParams.tab
				? {
						tab: searchParams.tab,
						...defaultParams,
				  }
				: defaultParams;
			const defaultSearchParams = pageSearch.getItem() ?? defaultPassedParams;
			if (defaultSearchParams) {
				setParams(defaultSearchParams, { replace: true });
			}
		}
		// eslint-disable-next-line
	}, [setParams]);

	/**
	 * @param values values to be replaced
	 * @param clearOldParams if `true` this function will query parameters with the new ones, if `false`, modify the properties that are in the new value
	 */
	const setUrlParams = useCallback(
		(
			values: object | string,
			clearOldParams?: boolean,
			options: NavigateOptions = {
				replace: true,
			}
		) => {
			let newSearchParams;
			if (typeof values === "object") {
				let newValues = {};
				if (clearOldParams) {
					newValues = values;
				} else {
					newValues = { ...urlParamsToObj(window.location.search), ...values };
				}
				newSearchParams = objToUrlParams(newValues);
			} else {
				newSearchParams = values;
			}

			pageSearch.setItem(newSearchParams);
			setParams(newSearchParams, options);
		},
		// eslint-disable-next-line
		[setParams, searchParams.searchFull]
	);

	return {
		...searchParams,
		setUrlParams,
	};
};
